import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer } from 'mdbreact'
import Paper from '@material-ui/core/Paper/Paper'
import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import { Redirect } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Select from '@material-ui/core/Select/Select'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import MUIDataTable from 'mui-datatables'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'

const InvoiceRatesPage = props => {
  const reduxState = useSelector(state => state)
  const [orders, setOrders] = useState([])
  const [objectKeys, setObjectKeys] = useState([])
  const [clientId, setClientId] = useState('none')
  const [client, setClient] = useState('none')
  const [clientList, setClientList] = useState([])
  const [store, setStore] = useState('none')
  const [storeId, setStoreId] = useState('none')
  const [storeList, setStoreList] = useState([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [authQueryReceived, setQueryReceived] = useState(false)

  useEffect(() => {
    if (
      reduxState.environmentVariables &&
      reduxState.environmentVariables.public &&
      reduxState.environmentVariables.public.analyticsTrackingId
    ) {
      ReactGA.initialize(
        reduxState.environmentVariables.public.analyticsTrackingId,
        { debug: false }
      )
      ReactGA.pageview(window.location.pathname + window.location.search)
    }

    window
      .fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        setLoggedIn(data.success)
        setQueryReceived(true)
      })
    window
      .fetch('/api/client/getClients')
      .then(res => res.json())
      .then(data => {
        setClientList(data.clients)
      })
  }, [])

  useEffect(() => {
    if (!(clientId === 'none' || storeId === 'none')) {
      getRows()
    }
  }, [clientId, startDate, endDate, storeId])

  if (!props.isLoggedIn && !props.isLoading) {
    return (
      <Redirect
        to={{ pathname: '/login', state: { referrer: '/invoicing' } }}
      />
    )
  }

  function getRows () {
    window
      .fetch(
        '/api/order/getFulfilled/' +
          startDate +
          '/' +
          endDate +
          '/' +
          clientId +
          '/' +
          storeId
      )
      // window.fetch('/api/order/getFulfilledByStore/5f1664a209f2ab2f828c3abb')
      .then(res => res.json())
      .then(data => {
        setObjectKeys(Object.keys({}))
        setTimeout(function () {
          setObjectKeys(data.keys)
          // setOrders(data.orderInvoiceArray)
          formatOrderInvoiceArray(data.orderInvoiceArray)
          console.log(data.orderInvoiceArray)
        }, 10)
      })
  }

  function formatOrderInvoiceArray (data) {
    const finalInvoiceArray = []
    Promise.all(
      data.map(invoice => {
        // If courier is AusPost or Fastway then push to finalInvoiceArray as normal
        if (
          invoice.fulfillment.courier === 'Australia Post' ||
          invoice.fulfillment.courier === 'Fastway'
        ) {
          finalInvoiceArray.push(invoice)
        }
        // else we need to hide the shipping cost
        else {
          invoice.shippingCost = ''
          invoice.shippingCharge = ''
          invoice.totalCost = ''
          finalInvoiceArray.push(invoice)
        }
      })
    ).then(() => {
      setOrders(finalInvoiceArray)
    })
  }

  function changeClient (value) {
    if (value !== 'none') {
      setClient(value)
      setClientId(value._id)
      setStoreList(value.stores)
      setStore('none')
      setStoreId('none')
    } else {
      setClientId('none')
      setClient('none')
    }
  }

  function handleDateChange (fromOrTo, e) {
    if (fromOrTo === 'from') {
      setStartDate(e)
    } else {
      setEndDate(e)
    }
  }

  function changeStore (value) {
    if (value !== 'none') {
      setStore(value)
      setStoreId(value._id)
    } else {
      setStoreId('none')
      setStore('none')
    }
  }

  const tableTitle =
    'Invoice - ' +
    (client.name ? client.name : '') +
    ' - ' +
    (store.name ? store.name : '') +
    ' | ' +
    startDate.toLocaleDateString('en-GB') +
    ' - ' +
    endDate.toLocaleDateString('en-GB') +
    '.csv'

  return (
    <div>
      {props.isLoggedIn ? (
        <MDBContainer fluid>
          <MDBBreadcrumb>
            <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>Invoicing</MDBBreadcrumbItem>
          </MDBBreadcrumb>
          <Paper style={{ padding: 25 }} elevation={5}>
            <Grid
              container
              justify='space-between'
              alignItems='center'
              alignContent='flex-end'
            >
              <Grid item>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    format='yyyy-MM-dd'
                    margin='normal'
                    id='from-date'
                    label='From:'
                    onChange={e => handleDateChange('from', e)}
                    value={startDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    variant='inline'
                    style={{ margin: 10 }}
                  />
                  <KeyboardDatePicker
                    format='yyyy-MM-dd'
                    margin='normal'
                    id='to-date'
                    label='To:'
                    onChange={e => handleDateChange('to', e)}
                    value={endDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    variant='inline'
                    style={{ margin: 10 }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item>
                <InputLabel id='client-label' shrink>
                  Client
                </InputLabel>
                <Select
                  labelId='client-label'
                  value={client}
                  onChange={e => changeClient(e.target.value)}
                >
                  <MenuItem value='none' disabled>
                    Select
                  </MenuItem>
                  {clientList
                    .sort((a, b) => a.name > b.name)
                    .map((row, i) => {
                      return (
                        <MenuItem key={i} value={row}>
                          {row.name}
                        </MenuItem>
                      )
                    })}
                </Select>
              </Grid>
              <Grid item>
                <InputLabel id='store-label' shrink>
                  Store
                </InputLabel>
                <Select
                  labelId='store-label'
                  value={store}
                  onChange={e => changeStore(e.target.value)}
                  disabled={clientId === 'none'}
                >
                  <MenuItem value='none' disabled>
                    Select
                  </MenuItem>
                  {storeList.map((row, i) => {
                    return (
                      <MenuItem key={i} value={row}>
                        {row.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              </Grid>
              {/* <Grid item> */}
              {/* <Button variant="contained" color="primary" disabled={storeId === 'none'}> */}
              {/* <CSVLink data={orders} */}
              {/* filename={tableTitle + ".csv"} */}
              {/* style={{color: 'white'}}>Download Invoice</CSVLink> */}
              {/* </Button> */}
              {/* </Grid> */}
            </Grid>
            {/* <InvoiceRatesTable order={orders} storeId={storeId}/> */}
            <MUIDataTable
              title={tableTitle}
              data={orders && orders}
              columns={objectKeys}
              options={{
                downloadOptions: { filename: tableTitle },
                isSelectable: false,
                elevation: 0,
                selectableRows: 'none',
                selectableRowsHeader: false,
                rowsPerPage: 25,
                rowsPerPageOptions: [25, 50, 100, 9999]
              }}
            />
          </Paper>
        </MDBContainer>
      ) : (
        <div />
      )}
    </div>
  )
}

export default InvoiceRatesPage
