import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import MUIDataTable from 'mui-datatables'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import MasterCaseRow from './MasterCaseRow'
import axios from 'axios'
import { Scrollbars } from 'react-custom-scrollbars'
import Snackbar from '@material-ui/core/Snackbar'

const MasterCase = (props) => {
  const [clientMap, setClientMap] = useState([])
  const [client, setClient] = useState({})
  const [invMap, setInvMap] = useState([])
  const [modal, setModal] = useState(false)
  const [inv, setInv] = useState('')
  const [masterCases, setMasterCases] = useState([])
  const [tags, setTags] = useState([])
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  useEffect(() => {
    window.fetch('/api/client/getClients')
      .then(res => res.json())
      .then(data => {
        setClientMap(data.clients)
        setClient(data.clients[0])
      })
  }, [])

  useEffect(() => {
    window.fetch('/api/item/getClientInv/' + client._id)
      .then(res => res.json())
      .then(data => {
        setInvMap(data.inventory)
      })
    window.fetch('/api/invoiceRates/getInvoiceRatesByClientId/' + client._id)
      .then(res => res.json())
      .then(data => {
        if (data !== null) {
          const storePickArr = []
          data.storePick.map(a => {
            if (storePickArr.indexOf(a.tag) === -1) {
              storePickArr.push(a.tag)
            }
          })
          setTags(storePickArr)
        }
      })
  }, [client])

  function updateCase () {
    axios.post('/api/item/updateCase', {
      body: JSON.stringify({
        itemId: inv._id,
        masterCases
      })
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      if (res.data.success) {
        setSnackbarMessage('Updated')
        setSnackbarOpen(true)
        setModal(false)
      } else {
        setSnackbarMessage('Failed')
        setSnackbarOpen(true)
      }
    })
      .catch(err => console.log(err))
  }

  function openModal (item) {
    setInv(item)
    setModal(true)
    if (item.masterCase === undefined) {
      if (masterCases.length === 0) {
        addRow()
      }
    } else {
      setMasterCases(item.masterCase)
    }
  }

  if (!props.isLoggedIn) {
    return <Redirect to={{ pathname: '/login', state: { referrer: '/masterCase' } }} />
  }

  const columns = [
    { name: 'sku', label: 'SKU' },
    { name: 'title', label: 'Title' },
    {
      name: '_id',
      label: ' ',
      options: {
        customBodyRenderLite: dataIndex => {
          return (<Button onClick={() => openModal(invMap[dataIndex])}>Update</Button>)
        }
      }
    }
  ]

  function addRow () {
    setMasterCases([...masterCases, {
      rowId: masterCases.length,
      quantity: 1,
      tag: 'standard'
    }])
  }

  function removeRow () {
    masterCases.splice(-1, 1)
    setMasterCases([...masterCases])
  }

  function updateMasterCaseRows (value, i, key) {
    const masterCase = masterCases.find(a => a.rowId === i)
    const myMasterCases = masterCases
    const index = myMasterCases.indexOf(masterCase)
    if (key === 'quantity') {
      masterCase[key] = parseInt(value)
    } else {
      masterCase[key] = value
    }
    myMasterCases.splice(index, 1)
    myMasterCases.push(masterCase)
    setMasterCases(myMasterCases)
  }

  return (
    <MDBContainer>
      <MDBBreadcrumb>
        <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>Master Case</MDBBreadcrumbItem>
      </MDBBreadcrumb>
      <Paper style={{ padding: 25 }}>
        <Grid container direction='column'>
          <Grid item>
            <InputLabel id='client-label' shrink>Client</InputLabel>
            <Select labelId='client-label' value={client} onChange={e => setClient(e.target.value)}>
              {clientMap.sort((a, b) => a.name > b.name ? 1 : -1).map((row, i) => {
                return (
                  <MenuItem key={i} value={row}>{row.name}</MenuItem>
                )
              })}
            </Select>
          </Grid>
          <Grid item>
            <MUIDataTable
              // title={"Shipment Report"}
              data={invMap.sort((a, b) => a.sku > b.sku ? 1 : -1)}
              columns={columns}
              options={{
                download: false,
                isSelectable: false,
                elevation: 0,
                selectableRows: 'none',
                selectableRowsHeader: false
              }}

            />
            {/* <Table> */}
            {/*    <TableHead> */}
            {/*        <TableRow> */}
            {/*            <TableCell>SKU</TableCell> */}
            {/*            <TableCell>Title</TableCell> */}
            {/*            <TableCell/> */}
            {/*        </TableRow> */}
            {/*    </TableHead> */}
            {/*    <TableBody> */}
            {/*        {invMap.map((inv, i) => { */}
            {/*            return ( */}
            {/*                <TableRow key={i}> */}
            {/*                    <TableCell>{inv.sku}</TableCell> */}
            {/*                    <TableCell>{inv.title}</TableCell> */}
            {/*                    <TableCell><Button>Update master case</Button></TableCell> */}
            {/*                </TableRow> */}
            {/*            ) */}
            {/*        })} */}
            {/*    </TableBody> */}
            {/* </Table> */}
          </Grid>
        </Grid>
        <Modal open={modal} onClose={() => setModal(false)}>
          <Paper style={{ margin: 'auto', marginTop: '25vh', height: '50vh', width: 600, padding: 25 }}>
            <Typography variant='h5'>{inv.sku}</Typography>
            <Typography variant='h5'>{inv.title}</Typography>
            <Divider />
            <Scrollbars style={{ height: '60%' }}>
              {masterCases.map((masterCase, i) => {
                return <MasterCaseRow updateMasterCaseRows={updateMasterCaseRows} i={i} masterCase={masterCase} tags={tags} key={i} />
              })}
            </Scrollbars>

            <Grid item>
              <Grid container justify='space-between' style={{ padding: 25 }}>
                <Grid item>
                  <Button onClick={() => addRow()}>
                    <AddIcon color='primary' />
                  </Button>
                  {masterCases.length > 1 &&
                    <Button onClick={() => removeRow()}>
                      <RemoveIcon color='primary' />
                    </Button>}
                </Grid>
                <Grid item>
                  <Button color='primary' onClick={() => updateCase()}>Update</Button>
                  <Button onClick={() => setModal(false)}>Cancel</Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Modal>
      </Paper>
      <Snackbar
        style={{ boxShadow: '0 0 10px red' }}
        message={snackbarMessage}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
    </MDBContainer>
  )
}

export default MasterCase
