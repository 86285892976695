import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'
import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBLink } from 'mdbreact'
import ReCAPTCHA from 'react-google-recaptcha'
import ReactGA from 'react-ga'
import {
  setEnvironmentVariables,
  setUserPreferences
} from '../../redux/actions'

class loginPage extends Component {
  recaptchaRef = React.createRef()
  constructor (props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      loginStatus: true,
      showRecaptchaV2Challenge: false,
      recaptchaToken: '',
      mounted: false
    }

    this.handleLoginClick = this.handleLoginClick.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleUsernameChange = this.handleUsernameChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleRecaptchaLoaded = this.handleRecaptchaLoaded.bind(this)
    this.handleRecaptchaV2Change = this.handleRecaptchaV2Change.bind(this)
  }

  componentDidMount () {
    this.setState({ mounted: true })
    window
      .fetch('/api/env/')
      .then(res => res.json())
      .then(data => {
        this.props.setEnvironmentVariables(data)

        // Add reCaptcha
        const script = document.createElement('script')
        script.src = `https://www.google.com/recaptcha/api.js?render=${data.public.recaptchaV3SiteKey}`
        script.addEventListener('load', this.handleRecaptchaLoaded)
        document.body.appendChild(script)

        // Add Google Analytics
        ReactGA.initialize(data.public.analyticsTrackingId, { debug: false })
        ReactGA.pageview(window.location.pathname + window.location.search)
      })
  }

  componentWillUnmount () {
    this.setState({ mounted: false })
  }

  handleRecaptchaLoaded () {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(this.props.environmentVariables.public.recaptchaV3SiteKey, {
          action: 'loginPage'
        })
        .then(token => {
          // Verify reCAPTCHA v3
          window
            .fetch('/api/auth/verify-grecaptcha-v3', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                recaptchaToken: token
              })
            })
            .then(res => res.json())
            .then(data => {
              window.fetch('/api/clientsideLogging/write', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                  loggingAuthKey: this.props.environmentVariables.public
                    .loggingAuthKey,
                  appName: this.props.appName,
                  appVersion: this.props.appVersion,
                  clientSessionUuid: this.props.clientSideSessionUuid,
                  datetime: DateTime.local().toString(),
                  component: 'loginPage',
                  message: 'reCAPTCHA v3 verification result.',
                  response: data
                })
              })
              if (this.state.mounted) {
                if (!data.success || !data.score || data.score < 0.6) {
                  // show reCAPTCHA v2 challenge
                  this.setState({ showRecaptchaV2Challenge: true })
                } else {
                  // hide reCAPTCHA v2 challenge
                  this.setState({ showRecaptchaV2Challenge: false })
                }
              }
            })
        })
    })
  }

  handleRecaptchaV2Change (value) {
    if (this.state.mounted) {
      this.setState({ recaptchaToken: value })
    }
  }

  handleLoginClick (e) {
    window.fetch('/api/clientsideLogging/write', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        loggingAuthKey: this.props.environmentVariables.public.loggingAuthKey,
        appName: this.props.appName,
        appVersion: this.props.appVersion,
        clientSessionUuid: this.props.clientSideSessionUuid,
        datetime: DateTime.local().toString(),
        component: 'loginPage',
        message: `Attempt to login as user '${this.state.username}'.`
      })
    })

    window
      .fetch('/api/auth', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username: this.state.username,
          password: this.state.password,
          verifyRecaptchaV2Challenge: this.state.showRecaptchaV2Challenge,
          recaptchaToken: this.state.recaptchaToken
        })
      })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          window.fetch('/api/clientsideLogging/write', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              loggingAuthKey: this.props.environmentVariables.public
                .loggingAuthKey,
              appName: this.props.appName,
              appVersion: this.props.appVersion,
              clientSessionUuid: this.props.clientSideSessionUuid,
              datetime: DateTime.local().toString(),
              component: 'loginPage',
              message: `User '${this.state.username}' successfully logged in.`
            })
          })

          window
            .fetch('/api/userPreferences/', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                username: this.state.username
              })
            })
            .then(res => res.json())
            .then(data => {
              this.props.setUserPreferences(data)
            })

          this.props.setIsLoggedIn(true)
          this.setState({ loginStatus: true })

          // Reset expired time for idle timer
          localStorage.removeItem('_expiredTime')

          if (this.props.location.state !== undefined) {
            this.props.history.push(this.props.location.state.referrer)
          } else {
            this.props.history.push('/create-items')
          }
        } else {
          this.setState({ loginStatus: false })
          window.fetch('/api/clientsideLogging/write', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              loggingAuthKey: this.props.environmentVariables.public
                .loggingAuthKey,
              appName: this.props.appName,
              appVersion: this.props.appVersion,
              clientSessionUuid: this.props.clientSideSessionUuid,
              severity: 'ERROR',
              datetime: DateTime.local().toString(),
              component: 'loginPage',
              message: `User '${this.state.username}' failed login attempt.`
            })
          })
        }
      })
      .catch(e => console.log(e))
  }

  handlePasswordChange (e) {
    this.setState({ password: e.target.value })
  }

  handleUsernameChange (e) {
    this.setState({ username: e.target.value })
  }

  handleKeyDown (event) {
    if (event.key === 'Enter') {
      this.handleLoginClick()
    }
  }

  componentDidUpdate () {
    if (this.props.isLoggedIn) {
      if (this.props.location.state !== undefined) {
        this.props.history.push(this.props.location.state.referrer)
      }
      //  else {
      //   this.props.history.push('/orders')
      // }
    }
  }

  render () {
    // if (this.props.isLoggedIn) {
    //   if (this.props.location.state !== undefined) {
    //     this.props.history.push(this.props.location.state.referrer)
    //   } else {
    //     this.props.history.push('/orders')
    //   }
    // }
    return (
      <MDBContainer>
        {!this.props.isLoading ? (
          <MDBRow center>
            <MDBCol md='4'>
              <form>
                <p className='h4 text-center mb-4'>Sign in</p>
                <label htmlFor='defaultFormLoginEmailEx' className='grey-text'>
                  Your email
                </label>
                <input
                  type='email'
                  id='defaultFormLoginEmailEx'
                  autoFocus
                  className='form-control'
                  onChange={this.handleUsernameChange}
                  onKeyDown={this.handleKeyDown}
                />
                <br />
                <label
                  htmlFor='defaultFormLoginPasswordEx'
                  className='grey-text'
                >
                  Your password
                </label>
                <input
                  type='password'
                  id='defaultFormLoginPasswordEx'
                  className='form-control'
                  onChange={this.handlePasswordChange}
                  onKeyDown={this.handleKeyDown}
                />
                {this.state.showRecaptchaV2Challenge &&
                this.props.environmentVariables &&
                this.props.environmentVariables.public &&
                this.props.environmentVariables.public.recaptchaV2SiteKey ? (
                  <div className='text-center mt-4'>
                    <ReCAPTCHA
                      ref={this.recaptchaRef}
                      sitekey={
                        this.props.environmentVariables.public
                          .recaptchaV2SiteKey
                      }
                      onChange={this.handleRecaptchaV2Change}
                    />
                  </div>
                ) : (
                  ''
                )}
                <div className='text-center mt-4'>
                  <MDBBtn
                    rounded
                    color='indigo'
                    onClick={this.handleLoginClick}
                  >
                    Login
                  </MDBBtn>
                </div>
                <div className='text-center mt-4'>
                  <MDBLink className='blue-text' link to='/forgot-password'>
                    Forgot your password?
                  </MDBLink>
                </div>
                <div className='text-center'>
                  {this.state.loginStatus ? (
                    <div />
                  ) : (
                    <div className='red-text'>
                      <br />
                      Login failed
                    </div>
                  )}
                </div>
              </form>
            </MDBCol>
          </MDBRow>
        ) : (
          <></>
        )}
        {this.props.environmentVariables &&
        this.props.environmentVariables.public &&
        this.props.environmentVariables.public.recaptchaV3SiteKey ? (
          <div
            className='g-recaptcha'
            data-sitekey={
              this.props.environmentVariables.public.recaptchaV3SiteKey
            }
            data-size='invisible'
          />
        ) : (
          ''
        )}
      </MDBContainer>
    )
  }
}

// Access to Redux store props
const mapStateToProps = (state, ownProps) => {
  // redux state fields are mapped to the props fields here.
  // This class component can access the redux state field via this.props.[fieldname]
  return {
    appName: state.appName,
    appVersion: state.appVersion,
    clientSideSessionUuid: state.clientSideSessionUuid,
    environmentVariables: state.environmentVariables,
    userPreferences: state.userPreferences
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUserPreferences: preferences => {
      dispatch(setUserPreferences(preferences))
    },
    setEnvironmentVariables: data => {
      dispatch(setEnvironmentVariables(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(loginPage)
