import Grid from '@material-ui/core/Grid/Grid'
import TextField from '@material-ui/core/TextField/TextField'
import React from 'react'
import Divider from '@material-ui/core/Divider/Divider'
import Typography from '@material-ui/core/Typography/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'

const CreateOrderLineItemRow = (props) => {
  const { updateLineItemRows, i, lineItems, setSnackbarOpen, setSnackbarMessage } = props

  function openSnackbar () {
    setSnackbarOpen(true)
    setSnackbarMessage('Please use a valid number')
  }

  return (
    <>
      <Grid
        container spacing={1} alignItems='center' alignContent='center' justify='flex-start'
        style={{ paddingRight: 10 }}
      >
        <Grid item xs={1}>
          <Typography variant='caption'> </Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid container direction='column'>
            <Grid item xs={10}>
              <TextField
                type='number'
                margin='normal'
                label='Quantity'
                defaultValue={1}
                onChange={e => parseInt(e.target.value) ? updateLineItemRows(e.target.value, i, 'qty') : openSnackbar()}
              />
            </Grid>
            <Grid item xs={10}>
              <Autocomplete
                fullWidth
                options={lineItems}
                getOptionLabel={(option) => (option.sku + ' - ' + option.title)}
                renderInput={(params) => <TextField {...params} label='Item' variant='outlined' fullWidth />}
                onChange={(e, value) => updateLineItemRows(value || null, i, 'item')}
              />
            </Grid>
            <Grid item xs={10}>
              <Divider style={{ marginTop: 15 }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default CreateOrderLineItemRow
