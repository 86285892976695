import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid/Grid'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper/Paper'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import { Alert } from '@material-ui/lab'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress'
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer
} from 'mdbreact'
import { DateTime } from 'luxon'
import socketIOClient from 'socket.io-client'
import _ from 'lodash'

const EparcelShipmentsPage = (props) => {
  const reduxState = useSelector(state => state)
  const { history } = props
  const [shipmentList, setShipmentList] = useState([])
  const [internationalShipmentList, setInternationalShipmentList] = useState([])
  const [domesticShipmentList, setDomesticShipmentList] = useState([])
  const [rowSelected, setRowSelected] = useState({})
  const [selectAll, setSelectAll] = useState(false)
  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])
  const [showInternational, setShowInternational] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: '',
    severity: 'warning'
  })
  const [isLoading, setIsLoading] = useState(true)
  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    window
      .fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        props.setAuthQueryReceived(true)
        props.setIsLoggedIn(data.success)
        if (data.success) {
          if (
            reduxState.environmentVariables &&
            reduxState.environmentVariables.public &&
            reduxState.environmentVariables.public.analyticsTrackingId
          ) {
            ReactGA.initialize(
              reduxState.environmentVariables.public.analyticsTrackingId,
              { debug: false }
            )
            ReactGA.pageview(window.location.pathname + window.location.search)
          }
        }
      })
      .then(() => {
        window
          .fetch('/api/eparcel/getAllShipments')
          .then(res => res.json())
          .then(data => {
            assignShipmentsToList(data.shipments)
          })
      })
  }, [])

  useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_SOCKET_IO_ENDPOINT)
    socket.on('connect', () => {
      // emit message to backend to watch change on eparcel-manifest
      socket.emit('EparcelShipmentsPage-watch-auspost-api-shipments')
    })
    if (socket) {
      socket.on('EparcelShipmentsPage-auspost-api-shipments-event', data => {
        // Received message, process it.
        handleShipmentChange(data)
      })
    }
    // CLEAN UP THE EFFECT
    return () => {
      if (socket !== undefined) {
        socket.disconnect()
      }
    }
  }, [isLoading])

  useEffect(() => {
  }, [shipmentList, internationalShipmentList, domesticShipmentList])

  // Must include this code block to redirect user to the login page if user is not logged in.
  // Otherwise if the session has timed out and the user access this page it will appear as loading indefinitely.
  // This code block must be placed immediately after the userEffect() blocks.
  if (!props.isLoggedIn && !props.isLoading) {
    return (
      <Redirect
        to={{ pathname: '/login', state: { referrer: '/eparcel-shipments' } }}
      />
    )
  }

  function handleShipmentChange (data) {
    switch (data.operationType) {
      case 'insert':
        // A document has been inserted
        if (!data.data.auspostOrderSummaryGenerated) {
          shipmentList.unshift(data.data)
          setShipmentList([...shipmentList])

          if (data.data.isInternational) {
            internationalShipmentList.unshift(data.data)
            setInternationalShipmentList([...internationalShipmentList])
          } else {
            domesticShipmentList.unshift(data.data)
            setDomesticShipmentList([...domesticShipmentList])
          }
        }
        break
      case 'update':
      case 'replace': {
        // A document has been changed
        let index = _.findIndex(shipmentList, { shipmentDocId: data.data.shipmentDocId })
        if (index > -1) {
          if (!data.data.auspostOrderSummaryGenerated) {
            // Manifest for the shipment has not been generated, replace the element found at the index in the existing array
            shipmentList.splice(index, 1, data.data)
          } else {
            // Manifest for the shipment has been generated, delete the item found at the index in the existing array
            shipmentList.splice(index, 1)
          }
          setShipmentList([...shipmentList])

          if (data.data.isInternational) {
            index = _.findIndex(internationalShipmentList, { shipmentDocId: data.data.shipmentDocId })
            if (index > -1) {
              if (!data.data.auspostOrderSummaryGenerated) {
                // Manifest for the shipment has not been generated, replace the element found at the index in the existing array
                internationalShipmentList.splice(index, 1, data.data)
              } else {
                // Manifest for the shipment has been generated, delete the item found at the index in the existing array
                internationalShipmentList.splice(index, 1)
              }
              setInternationalShipmentList([...internationalShipmentList])
            }
          } else {
            index = _.findIndex(domesticShipmentList, { shipmentDocId: data.data.shipmentDocId })
            if (index > -1) {
              if (!data.data.auspostOrderSummaryGenerated) {
                // Manifest for the shipment has not been generated, replace the element found at the index in the existing array
                domesticShipmentList.splice(index, 1, data.data)
              } else {
                // Manifest for the shipment has been generated, delete the item found at the index in the existing array
                domesticShipmentList.splice(index, 1)
              }
              setDomesticShipmentList([...domesticShipmentList])
            }
          }
        } else {
          // A document has been changed but it does not exist in the existing array
          if (!data.data.auspostOrderSummaryGenerated) {
            // Manifest for the shipment has not been generated, add the document in the existing array
            shipmentList.unshift(data.data)
            setShipmentList([...shipmentList])

            if (data.data.isInternational) {
              internationalShipmentList.unshift(data.data)
              setInternationalShipmentList([...internationalShipmentList])
            } else {
              domesticShipmentList.unshift(data.data)
              setDomesticShipmentList([...domesticShipmentList])
            }
          }
        }
      }
        break
      case 'delete': {
        // Find item index using _.findIndex (thanks @AJ Richardson for comment)
        let index = _.findIndex(shipmentList, { shipmentDocId: data.data.shipmentDocId })
        if (index > -1) {
          // Replace item at index using native splice
          shipmentList.splice(index, 1)
          setShipmentList([...shipmentList])

          if (data.data.isInternational) {
            index = _.findIndex(internationalShipmentList, { shipmentDocId: data.data.shipmentDocId })
            if (index > -1) {
              internationalShipmentList.splice(index, 1)
              setInternationalShipmentList([...internationalShipmentList])
            }
          } else {
            index = _.findIndex(domesticShipmentList, { shipmentDocId: data.data.shipmentDocId })
            if (index > -1) {
              domesticShipmentList.splice(index, 1)
              setDomesticShipmentList([...domesticShipmentList])
            }
          }
        }
      }
        break
      default:
    }
  }

  function assignShipmentsToList (shipments) {
    const internationalList = []
    const domesticList = []
    Promise.all(
      shipments.map(shipment => {
        if (shipment.isInternational) {
          internationalList.push(shipment)
        } else {
          domesticList.push(shipment)
        }
      })
    ).then(() => {
      setShipmentList([...sortListByDate(domesticList)])
      setInternationalShipmentList([...internationalList])
      setDomesticShipmentList([...domesticList])
      setIsLoading(false)
    })
  }

  function handleCreateLabelClick () {
    const selectedShipmentList = []
    Object.keys(rowSelected).forEach(key => {
      if (rowSelected[key]) {
        selectedShipmentList.push({ shipment_doc_id: key })
      }
    })

    // Send post request to create labels
    // only send request if selectedShipmentList has at least one shipment
    if (selectedShipmentList.length > 0) {
      window
        .fetch('/api/eparcel/createLabels', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            shipments: selectedShipmentList
          })
        })
        .then(res => res.json())
        .then(data => {
          if (data.success) {
            window.open(data.labelUrl)
            setSnackbarOpen(true)
            setSnackbarMessage({
              message: 'Labels successfully generated',
              severity: 'warning'
            })
          } else {
            setSnackbarOpen(true)
            setSnackbarMessage({ message: data.error, severity: 'error' })
          }
        })
    }
  }

  function handleCheckboxClick (key) {
    const newRowSelected = rowSelected
    if (key in newRowSelected) {
      newRowSelected[key] = !newRowSelected[key]
    } else {
      newRowSelected[key] = true
    }

    setRowSelected(newRowSelected)
    forceUpdate()
  }

  function handleSelectAll () {
    const newRowSelected = {}
    if (!selectAll) {
      shipmentList.forEach(shipment => {
        newRowSelected[shipment.shipmentDocId] = true
      })
    } else {
      shipmentList.forEach(shipment => {
        newRowSelected[shipment.shipmentDocId] = false
      })
    }
    setSelectAll(!selectAll)
    setRowSelected(newRowSelected)
    forceUpdate()
  }

  function handleInternationalToggle () {
    const newShipmentList = []
    // If we are toggling international orders off, then remove all international orders from shipment list
    if (showInternational) {
      // newShipmentList = domesticShipmentList
      domesticShipmentList.forEach(shipment => {
        newShipmentList.push(shipment)
      })
      // If we are toggling international orders on, then add all orders to shipment list
    } else if (!showInternational) {
      internationalShipmentList.forEach(shipment => {
        newShipmentList.push(shipment)
      })
    }
    setShowInternational(!showInternational)
    setShipmentList(sortListByDate([...newShipmentList]))
    setRowSelected([])
    setSelectAll(false)
    forceUpdate()
  }

  function sortListByDate (list, ascendingOrder = false) {
    list.sort(function compare (a, b) {
      const dateA = new Date(a.shipmentCreatedAt)
      const dateB = new Date(b.shipmentCreatedAt)
      if (ascendingOrder) {
        return dateA - dateB
      } else {
        return dateB - dateA
      }
    })
    return list
  }

  function handleClose () {
    setDialogOpen(false)
  }

  function handleDispatch () {
    window
      .fetch('/api/eparcel/dispatchOrders', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(res => res.json())
      .then(data => {
        setDialogOpen(false)
        setSnackbarOpen(true)
        setSnackbarMessage({
          message: 'Orders Dispatched',
          priority: 'warning'
        })
      })
  }

  return (
    <div>
      <MDBContainer>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
          <MDBBreadcrumbItem active>eParcel Shipments</MDBBreadcrumbItem>
        </MDBBreadcrumb>
      </MDBContainer>
      <MDBContainer>
        <Paper style={{ padding: 25 }} elevation={5}>
          <Grid
            container
            justify='space-between'
            alignItems='center'
            alignContent='flex-end'
          >
            <FormControl>
              <InputLabel id='demo-simple-select-label'>Location</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={showInternational}
                onChange={handleInternationalToggle}
              >
                <MenuItem value={false}>Domestic</MenuItem>
                <MenuItem value>International</MenuItem>
              </Select>
            </FormControl>
            <Grid item> </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                disableElevation
                onClick={() => handleCreateLabelClick()}
              >
                Create Labels
              </Button>
              <br />
              <br />
              <Button
                variant='contained'
                style={{ backgroundColor: 'green', color: 'white' }}
                disableElevation
                onClick={() => setDialogOpen(true)}
              >
                Dispatch
              </Button>
            </Grid>
          </Grid>
          <br />
          {isLoading ? (
            <LinearProgress style={{ width: '100%', margin: '25px 5px' }} />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      checked={selectAll}
                      onClick={() => handleSelectAll()}
                    />
                  </TableCell>
                  <TableCell>Order Name</TableCell>
                  <TableCell>Store Name</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shipmentList.length > 0 &&
                  shipmentList.map(shipment => {
                    return (
                      <TableRow
                        key={shipment.shipmentDocId}
                        hover
                        style={{
                          backgroundColor: (shipment.auspostLabelGenerated && 'lightblue')
                        }}
                      >
                        <TableCell padding='checkbox'>
                          <Checkbox
                            checked={
                              rowSelected[shipment.shipmentDocId] || false
                            }
                            onClick={() =>
                              handleCheckboxClick(shipment.shipmentDocId)}
                          />
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            history.push('/order/' + shipment.orderDocId)
                          }}
                        >
                          {shipment.orderName}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            history.push('/order/' + shipment.orderDocId)
                          }}
                        >
                          {shipment.storeName}
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            history.push('/order/' + shipment.orderDocId)
                          }}
                        >
                          {DateTime.fromJSDate(new Date(shipment.shipmentCreatedAt)).setLocale('en-AU').toFormat('d-LLL-yyyy h:mm:ss a')}
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          )}
        </Paper>
      </MDBContainer>
      <Snackbar
        // message={snackbarMessage}
        // message={dbOrder.fulfillment || order.displayFulfillmentStatus === "FULFILLED" ? "Order locked" : snackbarMessage}
        // open={snackbarOpen}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          elevation={6}
          variant='filled'
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarMessage.severity}
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>

      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Are you sure you want to create the manifest?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Only 1 manifest can be created per day. The shipments cannot be changed after the manifest is created.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary' autoFocus>
            No
          </Button>
          <Button onClick={handleDispatch} color='primary'>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EparcelShipmentsPage
