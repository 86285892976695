import React, { useState } from 'react'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import Button from '@material-ui/core/Button/Button'
import TextField from '@material-ui/core/TextField/TextField'
import axios from 'axios'

const InternationalRatesRow = (props) => {
  const { rate, setSnackbarOpen, setSnackbarMessage } = props
  const [basic, setBasic] = useState(rate.basic)
  const [perKg, setPerKg] = useState(rate.perKg)
  const [under500, set500g] = useState(rate.under500)
  const [oneKg, setOneKg] = useState(rate.oneKg)
  const [twoKg, setTwoKg] = useState(rate.twoKg)
  const [savedBasic, setSavedBasic] = useState(rate.basic)
  const [savedPerKg, setSavedPerKg] = useState(rate.perKg)
  const [saved500, setSaved500] = useState(rate['500g'])
  const [savedOneKg, setSavedOneKg] = useState(rate.oneKg)
  const [savedTwoKg, setSavedTwoKg] = useState(rate.twoKg)

  function updateRow () {
    if (oneKg !== '' && perKg !== '' && under500 !== '') {
      axios.post('/api/internationalRates/updateRate', {
        body: JSON.stringify({
          _id: rate._id,
          basic: basic === null ? rate.basic : basic,
          perKg: perKg === null ? rate.perKg : perKg,
          '500g': under500 === null ? rate['500g'] : under500,
          oneKg: oneKg === null ? rate.oneKg : oneKg,
          twoKg: twoKg === null ? rate.twoKg : twoKg,
          zone: rate.zone,
          oldBasic: rate.oldBasic,
          oldPerKg: rate.perKg,
          old500g: rate['500g'],
          oldOneKg: rate.oldOneKg,
          oldTwoKg: rate.oldTwoKg
        })
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (res.data.success) {
            setSnackbarMessage('Updated')
            if (basic === null) {
              setBasic(rate.basic)
            } else {
              setSavedBasic(basic)
            }
            if (oneKg === null) {
              setOneKg(rate.oneKg)
            } else {
              setSavedOneKg(oneKg)
            }
            if (twoKg === null) {
              setTwoKg(rate.twoKg)
            } else {
              setSavedTwoKg(twoKg)
            }
            if (perKg === null) {
              setSavedPerKg(rate.perKg)
            } else {
              setSavedPerKg(perKg)
            }
            if (under500 === null) {
              setSaved500(rate['500g'])
            } else {
              setSaved500(under500)
            }
          } else {
            setSnackbarMessage('Failed')
          }
          setSnackbarOpen(true)
        })
    } else {
      setSnackbarMessage('There is an invalid field')
      setSnackbarOpen(true)
    }
  }

  return (
    <TableRow>
      <TableCell>{rate.zone}</TableCell>
      <TableCell>
        <TextField
          type='number' value={basic && basic} onChange={e => setBasic(e.target.value)}
          defaultValue={rate.basic} helperText={'Saved as: ' + savedBasic}
        />
      </TableCell>
      <TableCell>
        <TextField
          type='number' value={under500 && under500} onChange={e => set500g(e.target.value)}
          defaultValue={rate['500g']} helperText={'Saved as: ' + saved500}
        />
      </TableCell>
      <TableCell>
        <TextField
          type='number' value={oneKg && oneKg} onChange={e => setOneKg(e.target.value)}
          defaultValue={rate.oneKg} helperText={'Saved as: ' + savedOneKg}
        />
      </TableCell>
      <TableCell>
        <TextField
          type='number' value={twoKg && twoKg} onChange={e => setTwoKg(e.target.value)}
          defaultValue={rate.twoKg} helperText={'Saved as: ' + savedTwoKg}
        />
      </TableCell>
      <TableCell>
        <TextField
          type='number' value={perKg && perKg} onChange={e => setPerKg(e.target.value)}
          defaultValue={rate.perKg} helperText={'Saved as: ' + savedPerKg}
        />
      </TableCell>
      <TableCell>
        <Button
          variant='contained' color='primary'
          onClick={() => updateRow()}
        >Update
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default InternationalRatesRow
