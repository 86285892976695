import { MDBCardBody, MDBRow } from 'mdbreact'
import TextField from '@material-ui/core/TextField/TextField'
import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'
import axios from 'axios'
import BundleCreateRows from './BundleCreateRows'
import Button from '@material-ui/core/Button/Button'
import Divider from '@material-ui/core/Divider/Divider'
import Paper from '@material-ui/core/Paper/Paper'

const BundleCreate = props => {
  const { setSnackbarOpen, setSnackbarMessage } = props
  const [clientList, setClientList] = useState([])
  const [client, setClient] = useState({})
  const [aliasSku, setAliasSku] = useState('')
  const [title, setTitle] = useState('')
  const [clientInv, setClientInv] = useState([])
  const [selectedInv, setSelectedInv] = useState([{ qty: 0, item: null }])

  useEffect(() => {
    window
      .fetch('/api/client/getClients')
      .then(res => res.json())
      .then(data => {
        setClientList(data.clients)
        // setClient(data.clients[0])
      })
  }, [])

  function selectClient (value) {
    if (value !== null) {
      setClient(value)
      axios.get('/api/item/getClientInv/' + value._id).then(res => {
        setClientInv(res.data.inventory)
      })
    }
    setSelectedInv([{ qty: 0, item: null }])
  }

  function updateSelectedInvQty (i, value) {
    const myInv = selectedInv
    myInv[i].qty = value
    setSelectedInv(myInv)
  }

  function updateSelectedInvItem (i, value) {
    const myInv = selectedInv
    myInv[i].item = value
    setSelectedInv(myInv)
  }

  function addRow () {
    setSelectedInv(selectedInv.concat({ qty: 0, item: null }))
  }

  function removeRow () {
    selectedInv.splice(-1, 1)
    setSelectedInv([...selectedInv])
  }

  useEffect(() => {}, [selectedInv])

  function saveBundle () {
    let missingRows = false
    selectedInv.map((a, i) => {
      if (a.qty < 1 || a.item === null) {
        setSnackbarMessage('Quantity or item missing for row: ' + (i + 1))
        setSnackbarOpen(true)
        missingRows = true
      }
    })
    if (aliasSku === '') {
      setSnackbarMessage('Alias SKU missing')
      setSnackbarOpen(true)
    } else if (title === '') {
      setSnackbarMessage('title missing')
      setSnackbarOpen(true)
    } else if (!missingRows) {
      const skuArray = []
      selectedInv.map((item, i) => {
        skuArray.push({ itemId: item.item._id, qty: item.qty })
      })
      const stores = []

      client.stores.map(store => {
        stores.push({ storeId: store._id })
      })

      const bundle = {
        aliasSku,
        skuArray,
        stores,
        title,
        clientId: client._id
      }

      axios
        .post('/api/inventoryBundle/create', bundle, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          setSnackbarOpen(true)
          setSnackbarMessage(
            'Bundle sent, please double check logs to see if it went through'
          )
        })
    }
  }

  return (
    <MDBCardBody style={{ padding: 25 }}>
      <Paper style={{ padding: 25 }} elevation={5}>
        <MDBRow hidden={clientList.length === 0}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Autocomplete
                options={clientList}
                getOptionLabel={option =>
                  option === undefined ? 'loading...' : option.name
                }
                style={{ width: 300 }}
                onChange={(e, newValue) => selectClient(newValue)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Client Select'
                    variant='outlined'
                  />
                )}
                value={client}
                fullWidth
                autoComplete
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Title'
                onChange={e => setTitle(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label='Alias SKU'
                onChange={e => setAliasSku(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ margin: 25 }} />
            </Grid>
            {clientInv.length > 0 &&
              selectedInv.map((inv, i) => {
                return (
                  <BundleCreateRows
                    key={i}
                    i={i}
                    clientInv={clientInv}
                    updateSelectedInvItem={updateSelectedInvItem}
                    updateSelectedInvQty={updateSelectedInvQty}
                    selectedInv={selectedInv[i]}
                  />
                )
              })}
            <Grid
              item
              hidden={clientInv.length === 0}
              xs={6}
              style={{ padding: 25 }}
            >
              <Button
                variant='contained'
                color='primary'
                onClick={() => addRow()}
                style={{ width: '100%' }}
              >
                Add row
              </Button>
            </Grid>
            <Grid
              item
              hidden={clientInv.length === 0}
              xs={6}
              style={{ padding: 25 }}
            >
              <Button
                variant='contained'
                color='secondary'
                onClick={() => removeRow()}
                style={{ width: '100%' }}
                disabled={selectedInv.length < 2}
              >
                Remove row
              </Button>
            </Grid>
            <Grid container justify='flex-end'>
              <Grid item style={{ padding: 25 }}>
                <Button onClick={() => saveBundle()}>Save Bundle</Button>
              </Grid>
            </Grid>
          </Grid>
        </MDBRow>
      </Paper>
    </MDBCardBody>
  )
}

export default BundleCreate
