import React from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import Typography from '@material-ui/core/Typography/Typography'
import TextField from '@material-ui/core/TextField/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Divider from '@material-ui/core/Divider/Divider'

const CreateOrdersSingle = (props) => {
  const {
    countries, setCountries,
    firstName, setFirstName,
    lastName, setLastName,
    company, setCompany,
    address1, setAddress1,
    address2, setAddress2,
    suburb, setSuburb,
    state, setState,
    postcode, setPostcode,
    setCountry,
    setCountryCode,
    phone, setPhone,
    email, setEmail
  } = props

  function updateCountry (value) {
    setCountryCode(value.code)
    setCountry(value.name)
  }

  return (
    <>
      <Typography variant='h5'>Shipping</Typography>
      <Grid container spacing={5}>
        <Grid item>
          <TextField
            value={firstName} onChange={e => setFirstName(e.target.value)}
            label='First Name'
          />
        </Grid>
        <Grid item>
          <TextField
            value={lastName} onChange={e => setLastName(e.target.value)}
            label='Last Name'
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={company} onChange={e => setCompany(e.target.value)}
            label='Company'
          />
        </Grid>
        <Grid item>
          <TextField
            value={address1} onChange={e => setAddress1(e.target.value)}
            label='Address 1'
          />
        </Grid>
        <Grid item>
          <TextField
            value={address2} onChange={e => setAddress2(e.target.value)}
            label='Address 2'
          />
        </Grid>
        <Grid item>
          <TextField
            value={suburb} onChange={e => setSuburb(e.target.value)}
            label='Suburb / City'
          />
        </Grid>
        <Grid item>
          <TextField
            value={state} onChange={e => setState(e.target.value)}
            label='State / Province'
          />
        </Grid>
        <Grid item>
          <TextField
            value={postcode} onChange={e => setPostcode(e.target.value)}
            label='Postcode / ZIP'
          />
        </Grid>
        <Grid item xs={10}>
          <Autocomplete
            fullWidth
            options={countries}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label='Country' variant='outlined' fullWidth />}
            onChange={(e, value) => updateCountry(value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item>
          <TextField value={phone} onChange={e => setPhone(e.target.value)} label='Phone' />
        </Grid>
        <Grid item>
          <TextField
            value={email} onChange={e => setEmail(e.target.value)} label='Email'
            type='email'
          />
        </Grid>
      </Grid>
    </>
  )
}

export default CreateOrdersSingle
