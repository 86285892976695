import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBCollapse,
  MDBCollapseHeader,
  MDBContainer,
  MDBIcon,
  MDBDataTable,
  MDBBtn
} from 'mdbreact'
import axios from 'axios'
import Paper from '@material-ui/core/Paper/Paper'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import TextField from '@material-ui/core/TextField/TextField'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid/Grid'
import Divider from '@material-ui/core/Divider/Divider'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
var _ = require('lodash')

const BatchPage = props => {
  const [collapseId, setCollapseId] = useState('collapse0')
  const reduxState = useSelector(state => state)
  const history = useHistory()
  const [selectedSKU, setSelectedSKU] = useState()
  const [clientInv, setClientInv] = useState([])
  const [clientList, setClientList] = useState([])
  const [client, setClient] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [openBatchDetails, setOpenBatchDetails] = useState(false)
  const [batchDetails, setBatchDetails] = useState()
  const [batchList, setBatchList] = useState([
    {
      batchId: '',
      qty: 0,
      expirationDate: new Date().toISOString().split('T')[0]
    }
  ])
  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])
  const [open, setOpen] = React.useState(false)
  const [tableData, setTableData] = React.useState({
    columns: [
      {
        label: 'SKU',
        field: 'sku',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Title',
        field: 'title',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Client',
        field: 'client',
        sort: 'asc',
        width: 270
      },
      {
        label: '',
        field: 'update',
        width: 100
      }
    ],
    rows: []
  })
  const [batchTableData, setBatchTableData] = React.useState({
    columns: [
      {
        label: 'Batch Id',
        field: 'id',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Qty',
        field: 'qty',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Expiration Date',
        field: 'expiration',
        sort: 'asc',
        width: 270
      },
      {
        label: '',
        field: 'update',
        width: 100
      }
    ],
    rows: []
  })
  const [openEditDialog, setOpenEditDialog] = React.useState(false)
  const [batchRow, setBatchRow] = React.useState()
  const [newBatchRowQty, setNewBatchRowQty] = React.useState()
  const [newBatchRowExpiration, setNewBatchRowExpiration] = React.useState()
  const [snackbarMessage, setSnackbarMessage] = React.useState('')
  const [snackbarOpen, setSnackbarOpen] = React.useState(false)
  const [openAddBatchDialog, setOpenAddBatchDialog] = React.useState(false)
  const [batchDialogRows, setBatchDialogRows] = React.useState([
    {
      batchId: '',
      qty: 0,
      expirationDate: new Date().toISOString().split('T')[0]
    }
  ])
  useEffect(() => {
    setIsLoading(true)

    window
      .fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        props.setAuthQueryReceived(true)
        props.setIsLoggedIn(data.success)
        if (data.success) {
          if (
            reduxState.environmentVariables &&
            reduxState.environmentVariables.public &&
            reduxState.environmentVariables.public.analyticsTrackingId
          ) {
            ReactGA.initialize(
              reduxState.environmentVariables.public.analyticsTrackingId,
              { debug: false }
            )
            ReactGA.pageview(window.location.pathname + window.location.search)
          }

          window
            .fetch('/api/client/getClients')
            .then(res => res.json())
            .then(data => {
              console.log(data.clients)
              setClientList(data.clients)
              setIsLoading(false)
              // setClient(data.clients[0])
            })
          getBatches()
        } else {
          history.push('/login')
        }
      })
  }, [])

  function toggleCollapse (id) {
    if (collapseId !== id) {
      setCollapseId(id)
    } else {
      setCollapseId('')
    }
  }

  function selectClient (value) {
    if (value !== null) {
      setClient(value)
      axios.get('/api/item/getClientInv/' + value._id).then(res => {
        setClientInv(res.data.inventory)
      })
    }
  }

  function handleBatchNumberChange (value, i) {
    console.log(value, i)
    const newBatchList = batchList
    newBatchList[i].batchId = value
    setBatchList(newBatchList)
    forceUpdate()
  }

  function handleAddBatchNumberChange (value, i) {
    const newBatchList = batchDialogRows
    newBatchList[i].batchId = value
    setBatchDialogRows(newBatchList)
  }

  function handleBatchQtyChange (qty, i) {
    console.log(qty, i)
    const newBatchList = batchList
    newBatchList[i].qty = parseInt(qty)
    setBatchList(newBatchList)
    forceUpdate()
  }

  function handleAddBatchQtyChange (qty, i) {
    const newBatchList = batchDialogRows
    newBatchList[i].qty = qty
    setBatchDialogRows(newBatchList)
  }

  function handleExpirationDateChange (e, i) {
    const newBatchList = batchList
    newBatchList[i].expirationDate = e
    setBatchList(newBatchList)
    forceUpdate()
  }

  function handleAddBatchExpirationDateChange (e, i) {
    const newBatchList = batchDialogRows
    newBatchList[i].expirationDate = e
    setBatchDialogRows(newBatchList)
    forceUpdate()
  }

  function handleAddRow () {
    console.log(batchList)
    const newBatchList = batchList
    newBatchList.push({
      batchId: '',
      qty: 0,
      expirationDate: new Date().toISOString().split('T')[0]
    })
    setBatchList(newBatchList)
    forceUpdate()
  }

  function handleAddBatchDialogRow () {
    const newBatchList = batchDialogRows
    newBatchList.push({
      batchId: '',
      qty: 0,
      expirationDate: new Date().toISOString().split('T')[0]
    })
    setBatchDialogRows(newBatchList)
    forceUpdate()
  }

  function handleRemoveRow () {
    const newBatchList = batchList
    newBatchList.pop()
    setBatchList(newBatchList)
    forceUpdate()
  }

  function handleRemoveBatchDialogRow () {
    const newBatchList = batchDialogRows
    newBatchList.pop()
    setBatchDialogRows(newBatchList)
    forceUpdate()
  }

  function handleAddNewBatchItem () {
    // Check that all batch ids are unique
    const uniqueBatchIdList = [
      ...new Map(batchList.map(item => [item.batchId, item])).values()
    ]
    const emptyID = _.get(batchDialogRows, '')

    if (
      batchList.length > 0 &&
      batchList[0].batchId !== '' &&
      typeof emptyID === 'undefined'
    ) {
      console.log(uniqueBatchIdList.length)
      console.log(batchList.length)
      if (uniqueBatchIdList.length === batchList.length) {
        setOpen(true)
      } else {
        setSnackbarOpen(true)
        setSnackbarMessage('Duplicate Batch IDs detected')
      }
    } else {
      setSnackbarOpen(true)
      setSnackbarMessage('Please make sure each Batch has an ID')
    }
  }

  function confirmAddNewBatchItem () {
    const payload = {
      selectedSKU,
      batchList,
      client
    }

    axios
      .post('/api/batches/add-product-batch', payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        console.log(res.data)
        getBatches()
      })
    setOpen(false)
    setBatchList([
      {
        batchId: '',
        qty: 0,
        expirationDate: new Date().toISOString().split('T')[0]
      }
    ])
    forceUpdate()
  }

  function getBatches () {
    window
      .fetch('/api/batches/get-batches')
      .then(res => res.json())
      .then(data => {
        console.log(data)
        const newRows = []
        Promise.all(
          data.batches.map((batch, i) => {
            newRows.push({
              ...batch,
              client: batch.clientName,
              update: (
                <>
                  <MDBBtn
                    color='primary'
                    size='sm'
                    id={i}
                    onClick={() => {
                      setBatchDetails(batch)
                      setOpenAddBatchDialog(true)
                    }}
                  >
                    {' '}
                    Add Batch
                  </MDBBtn>
                  <MDBBtn
                    color='primary'
                    size='sm'
                    id={i}
                    onClick={() => {
                      setBatchDetails(batch)
                      renderBatchDetailsTable(batch)
                      setOpenBatchDetails(true)
                      console.log(batch)
                    }}
                  >
                    View
                  </MDBBtn>
                </>
              )
            })
          })
        ).then(() => {
          const newData = {
            columns: [
              {
                label: 'SKU',
                field: 'sku',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Title',
                field: 'title',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Client',
                field: 'client',
                sort: 'asc',
                width: 270
              },
              {
                label: '',
                field: 'update',
                width: 100
              }
            ],
            rows: newRows
          }
          setTableData(newData)
          forceUpdate()
        })
      })
  }

  function renderBatchDetailsTable (batch) {
    const newRows = []

    Promise.all(
      batch.batchList.map((batch, i) => {
        newRows.push({
          id: batch.batchId,
          qty: batch.qty,
          expiration: batch.expirationDate,
          update: (
            <MDBBtn
              color='primary'
              size='sm'
              id={i}
              onClick={() => {
                console.log(batch)
                setBatchRow(batch)
                setNewBatchRowExpiration(batch.expirationDate)
                setNewBatchRowQty(batch.qty)
                setOpenEditDialog(true)
              }}
            >
              {' '}
              Edit
            </MDBBtn>
          )
        })
      })
    ).then(() => {
      setBatchTableData({
        columns: [
          {
            label: 'Batch Id',
            field: 'id',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Qty',
            field: 'qty',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Expiration Date',
            field: 'expiration',
            sort: 'asc',
            width: 270
          },
          {
            label: '',
            field: 'update',
            width: 100
          }
        ],
        rows: newRows
      })
      forceUpdate()
    })
  }

  function handleUpdateBatchRowSave () {
    window
      .fetch('/api/batches/update-batch', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          batchDetails: batchDetails,
          newBatchQty: newBatchRowQty,
          newBatchExpiration: newBatchRowExpiration,
          batchRow: batchRow
        })
      })
      .then(res => res.json())
      .then(data => {
        setOpenEditDialog(false)
        setOpenBatchDetails(false)
        getBatches()

        if (data.success) {
          setSnackbarOpen(true)
          setSnackbarMessage('Update Successful')
        } else {
          setSnackbarOpen(true)
          setSnackbarMessage('Update Failed')
        }
      })
  }

  function handleSaveAddBatch () {
    // Check that all batch ids are unique
    const uniqueBatchIdList = [
      ...new Map(batchDialogRows.map(item => [item.batchId, item])).values()
    ]
    const emptyID = _.get(batchDialogRows, '')
    if (
      batchDialogRows.length > 0 &&
      batchDialogRows[0].batchId !== '' &&
      typeof emptyID === 'undefined'
    ) {
      if (uniqueBatchIdList.length === batchDialogRows.length) {
        window
          .fetch('/api/batches/add-batch', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              batchRows: batchDialogRows,
              batchDetails: batchDetails
            })
          })
          .then(res => res.json())
          .then(data => {
            console.log(data)
            if (data.success) {
              setSnackbarOpen(true)
              setSnackbarMessage(data.message)
              getBatches()
              setOpenAddBatchDialog(false)
              setBatchDialogRows([
                {
                  batchId: '',
                  qty: 0,
                  expirationDate: new Date().toISOString().split('T')[0]
                }
              ])
            } else {
              setSnackbarOpen(true)
              setSnackbarMessage(data.message)
            }
          })
      } else {
        console.log('Duplicate IDs detected')
        setSnackbarOpen(true)
        setSnackbarMessage('Duplicate Batch IDs Detected')
      }
    } else {
      setSnackbarOpen(true)
      setSnackbarMessage('Please make sure each batch has an ID')
    }
  }
  return (
    <>
      <MDBContainer>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
          <MDBBreadcrumbItem>Items</MDBBreadcrumbItem>
          <MDBBreadcrumbItem active>Item Batches</MDBBreadcrumbItem>
        </MDBBreadcrumb>
        <MDBCard
          className='mt-3'
          style={{ backgroundColor: 'rgba(0,0,0,.03)' }}
        >
          <MDBCollapseHeader
            tagClassName='d-flex justify-content-between'
            onClick={() => toggleCollapse('collapse0')}
          >
            Create Item Batch
            <MDBIcon
              icon={collapseId === 'collapse0' ? 'angle-up' : 'angle-down'}
            />
          </MDBCollapseHeader>
          {collapseId === 'collapse0' && (
            <MDBCollapse id='collapse0' isOpen={collapseId}>
              <MDBCardBody>
                <Paper elevation={3} style={{ padding: 25 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Autocomplete
                        options={clientList}
                        getOptionSelected={(option, value) => {
                          return option.name === value
                        }}
                        getOptionLabel={option =>
                          option === undefined ? 'loading...' : option.name
                        }
                        style={{ width: 300 }}
                        onChange={(e, newValue) => selectClient(newValue)}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label='Client Select'
                            variant='outlined'
                          />
                        )}
                        value={client}
                        fullWidth
                        autoComplete
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        options={clientInv}
                        getOptionLabel={option =>
                          option === undefined
                            ? 'loading...'
                            : option.sku + ' - ' + option.title
                        }
                        onChange={(e, value) => setSelectedSKU(value)}
                        value={selectedSKU}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label='SKU Select'
                            variant='outlined'
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  {selectedSKU && (
                    <>
                      <Grid item xs={12}>
                        <Divider style={{ margin: 25 }} />
                      </Grid>
                      <Grid container>
                        {batchList.map((batch, i) => {
                          return (
                            <Grid key={i} container style={{ margin: 25 }}>
                              <Grid item xs={3} style={{ marginBottom: 25 }}>
                                {' '}
                                <TextField
                                  fullWidth
                                  label='Batch #'
                                  value={batchList[i].batchId}
                                  onChange={e =>
                                    handleBatchNumberChange(e.target.value, i)
                                  }
                                />
                              </Grid>
                              <Grid item xs={1} />
                              <Grid item xs={3} style={{ marginBottom: 25 }}>
                                {' '}
                                <TextField
                                  fullWidth
                                  type='number'
                                  label='Quantity'
                                  value={batchList[i].qty}
                                  onChange={e =>
                                    handleBatchQtyChange(e.target.value, i)
                                  }
                                />
                              </Grid>
                              <Grid item xs={1} />
                              <Grid item xs={3} style={{ marginBottom: 25 }}>
                                {' '}
                                <TextField
                                  fullWidth
                                  label='Expiration Date'
                                  type='date'
                                  value={batchList[i].expirationDate}
                                  onChange={e =>
                                    handleExpirationDateChange(
                                      e.target.value,
                                      i
                                    )
                                  }
                                />
                              </Grid>
                            </Grid>
                          )
                        })}
                        <Grid container>
                          <Grid item xs={6}>
                            <Button
                              variant='contained'
                              color='primary'
                              style={{ width: '50%' }}
                              onClick={() => handleAddRow()}
                            >
                              Add row
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                              variant='contained'
                              color='secondary'
                              disabled={batchList.length === 1}
                              style={{ width: '50%' }}
                              onClick={() => handleRemoveRow()}
                            >
                              Remove row
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <br />
                  <Grid item xs={12}>
                    <Divider style={{ margin: 25 }} />
                  </Grid>
                  <Grid container style={{ padding: 25 }}>
                    <Grid item xs={12}>
                      <Button
                        variant='contained'
                        color='primary'
                        style={{ width: '50%' }}
                        onClick={() => handleAddNewBatchItem()}
                        disabled={!selectedSKU}
                      >
                        Add New Batch Item
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </MDBCardBody>
            </MDBCollapse>
          )}
        </MDBCard>
        <MDBCard
          className='mt-3'
          style={{ backgroundColor: 'rgba(0,0,0,.03)' }}
        >
          <MDBCollapseHeader
            tagClassName='d-flex justify-content-between'
            onClick={() => toggleCollapse('collapse1')}
          >
            Edit Batches
            <MDBIcon
              icon={collapseId === 'collapse1' ? 'angle-up' : 'angle-down'}
            />
          </MDBCollapseHeader>
          {collapseId === 'collapse1' && (
            <MDBCollapse id='collapse1' isOpen={collapseId}>
              <MDBCardBody>
                <Paper elevation={3} style={{ padding: 25 }}>
                  <MDBDataTable
                    searchLabel='Search'
                    striped
                    bordered
                    small
                    data={tableData}
                  />
                  {/* <Typography variant='body1'>aaa</Typography> */}
                </Paper>
              </MDBCardBody>
            </MDBCollapse>
          )}
        </MDBCard>
      </MDBContainer>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Confirm adding batch item?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to add this item to the batch list?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color='secondary' autoFocus>
            No
          </Button>
          <Button onClick={() => confirmAddNewBatchItem()} color='primary'>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth='md'
        fullWidth
        open={openBatchDetails}
        onClose={() => setOpenBatchDetails(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Batch Details</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            SKU: {batchDetails && batchDetails.sku}
            <br />
            Title: {batchDetails && batchDetails.title}
          </DialogContentText>
          <MDBDataTable
            searchLabel='Search'
            striped
            bordered
            small
            data={batchTableData}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenBatchDetails(false)} color='default'>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth='md'
        fullWidth
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Batch Details</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description' />
          <Grid container spacing={1}>
            <Grid item xs={4}>
              {' '}
              <TextField
                id='outlined-read-only-input'
                fullWidth
                label='Batch Id'
                defaultValue={batchRow && batchRow.batchId}
                InputProps={{
                  readOnly: true
                }}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={4}>
              {' '}
              <TextField
                id='outlined-number'
                fullWidth
                label='Quantity'
                type='number'
                onChange={e => setNewBatchRowQty(e.target.value)}
                defaultValue={batchRow && batchRow.qty}
                InputLabelProps={{
                  shrink: true
                }}
                variant='outlined'
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id='outlined-search'
                fullWidth
                label='Expiration Date'
                type='date'
                onChange={e => setNewBatchRowExpiration(e.target.value)}
                defaultValue={batchRow && batchRow.expirationDate}
                variant='outlined'
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleUpdateBatchRowSave()} color='primary'>
            Save
          </Button>
          <Button onClick={() => setOpenEditDialog(false)} color='default'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        message={snackbarMessage}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      />
      <Dialog
        maxWidth='md'
        fullWidth
        open={openAddBatchDialog}
        onClose={() => {
          setBatchDialogRows([
            {
              batchId: '',
              qty: 0,
              expirationDate: new Date().toISOString().split('T')[0]
            }
          ])
          setOpenAddBatchDialog(false)
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Add Batch</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            SKU: {batchDetails && batchDetails.sku}
            <br />
            Title: {batchDetails && batchDetails.title}
          </DialogContentText>
          <Paper elevation={3} style={{ padding: 10 }}>
            {batchDialogRows.map((row, i) => {
              return (
                <Grid key={i} container style={{ margin: 15 }}>
                  <Grid item xs={3} style={{ marginBottom: 25 }}>
                    {' '}
                    <TextField
                      fullWidth
                      label='Batch #'
                      // value={batchList[i].batchId}
                      onChange={e =>
                        handleAddBatchNumberChange(e.target.value, i)
                      }
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} style={{ marginBottom: 25 }}>
                    {' '}
                    <TextField
                      fullWidth
                      type='number'
                      label='Quantity'
                      // value={batchList[i].qty}
                      onChange={e => handleAddBatchQtyChange(e.target.value, i)}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} style={{ marginBottom: 25 }}>
                    {' '}
                    <TextField
                      fullWidth
                      label='Expiration Date'
                      defaultValue={new Date().toISOString().split('T')[0]}
                      type='date'
                      // value={batchList[i].expirationDate}
                      onChange={e =>
                        handleAddBatchExpirationDateChange(e.target.value, i)
                      }
                    />
                  </Grid>
                </Grid>
              )
            })}

            <Grid container>
              <Grid item xs={6} style={{ paddingLeft: '15%' }}>
                <Button
                  variant='contained'
                  color='primary'
                  style={{ width: '50%' }}
                  onClick={() => handleAddBatchDialogRow()}
                >
                  Add row
                </Button>
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: '15%' }}>
                <Button
                  variant='contained'
                  color='secondary'
                  disabled={batchDialogRows.length === 1}
                  style={{ width: '50%' }}
                  onClick={() => handleRemoveBatchDialogRow()}
                >
                  Remove row
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSaveAddBatch()} color='primary'>
            Save
          </Button>
          <Button
            onClick={() => {
              setBatchDialogRows([
                {
                  batchId: '',
                  qty: 0,
                  expirationDate: new Date().toISOString().split('T')[0]
                }
              ])
              setOpenAddBatchDialog(false)
            }}
            color='default'
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BatchPage
