import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer } from 'mdbreact'
import Paper from '@material-ui/core/Paper/Paper'
import React, { useEffect, useState } from 'react'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import Table from '@material-ui/core/Table/Table'
import Typography from '@material-ui/core/Typography/Typography'
import Button from '@material-ui/core/Button/Button'
import Grid from '@material-ui/core/Grid/Grid'
import { Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'

const EventsPage = (props) => {
  const reduxState = useSelector(state => state)
  const [events, setEvents] = useState([])
  const [showData, setShowData] = useState(false)

  useEffect(() => {
    // Add Google Analytics
    if (reduxState.environmentVariables && reduxState.environmentVariables.public && reduxState.environmentVariables.public.analyticsTrackingId) {
      ReactGA.initialize(reduxState.environmentVariables.public.analyticsTrackingId, { debug: false })
      ReactGA.pageview(window.location.pathname + window.location.search)
    }

    window.fetch('/api/events')
      .then(res => res.json())
      .then(data => {
        setEvents(data.events)
      })
  }, [])

  if (!props.isLoggedIn) {
    return <Redirect to={{ pathname: '/login', state: { referrer: '/events' } }} />
  }

  return (<div>
    {props.isLoggedIn
      ? <MDBContainer>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
          <MDBBreadcrumbItem active>Events</MDBBreadcrumbItem>
        </MDBBreadcrumb>
        <Paper elevation={10} style={{ padding: 25 }}>
          <Grid container justify='space-between'>
            <Grid item>
              <Typography variant='caption'>Don't trust the log if the message is empty... and tell
                            Trev
              </Typography>
            </Grid>
            <Grid item>
              <Button onClick={() => setShowData(!showData)}>Show full data</Button>
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                                    Message
                </TableCell>
                <TableCell>
                                    Success
                </TableCell>
                <TableCell>
                                    Function Name
                </TableCell>
                <TableCell>
                                    User
                </TableCell>
                <TableCell>
                                    Created At
                </TableCell>
                {showData &&
                  <TableCell>
                                        Data
                  </TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {events !== undefined && events.map((event, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      {event.eventMessage}
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant='body1'
                        style={{ color: event.eventSuccess ? 'limegreen' : 'lightcoral' }}
                      > {event.eventSuccess ? 'Success' : 'Failed'}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {event.action}
                    </TableCell>
                    <TableCell>
                      {event.username}
                    </TableCell>
                    <TableCell>
                      {new Date(event.createdAt).toLocaleString('en-GB')}
                    </TableCell>
                    {showData &&
                      <TableCell>
                        {event.eventData !== undefined && JSON.stringify(event.eventData)}
                      </TableCell>}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Paper>
      </MDBContainer> : <div />}
  </div>
  )
}

export default EventsPage
