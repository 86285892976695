import {
  SET_CLIENT_SESSION_UUID,
  SET_MENU_INVOICING_AUTHORIZATION,
  SET_MENU_REPORTS_AUTHORIZATION,
  SET_MENU_EVENTS_AUTHORIZATION,
  SET_MENU_NOTIFICATIONS_AUTHORIZATION,
  SET_MENU_RETURNS_AUTHORIZATION,
  SET_ENVIRONMENT_VARIABLES,
  SET_USER_PREFERENCES,
  SET_MENU_EPARCEL_AUTHORIZATION
} from '../constants/actionTypes'

export const setClientSideUuid = payload => {
  return { type: SET_CLIENT_SESSION_UUID, payload: payload }
}

export const setMenuInvoicingAuthorization = payload => {
  return { type: SET_MENU_INVOICING_AUTHORIZATION, payload: payload }
}

export const setMenuReportsAuthorization = payload => {
  return { type: SET_MENU_REPORTS_AUTHORIZATION, payload: payload }
}

export const setMenuReturnsAuthorization = payload => {
  return { type: SET_MENU_RETURNS_AUTHORIZATION, payload: payload }
}

export const setMenuEventsAuthorization = payload => {
  return { type: SET_MENU_EVENTS_AUTHORIZATION, payload: payload }
}

export const setMenuNotificationsAuthorization = payload => {
  return { type: SET_MENU_NOTIFICATIONS_AUTHORIZATION, payload: payload }
}

export const setMenuEparcelAuthorization = payload => {
  return { type: SET_MENU_EPARCEL_AUTHORIZATION, payload: payload }
}

export const setEnvironmentVariables = payload => {
  return { type: SET_ENVIRONMENT_VARIABLES, payload: payload }
}

export const setUserPreferences = payload => {
  return { type: SET_USER_PREFERENCES, payload: payload }
}
