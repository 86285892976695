import Typography from '@material-ui/core/Typography/Typography'
import TableCell from '@material-ui/core/TableCell/TableCell'
import React from 'react'

const EbaySpecificStat = (props) => {
  const { order } = props

  if (order.ShippingServiceSelected !== undefined) {
    return (
      <TableCell colSpan={2}>
        <Typography
          variant='caption'
          style={{ color: ((order.OrderStatus.includes('Cancelled')) && 'tomato') }}
        >
                    Order Status: {order.OrderStatus}
        </Typography>
        <br />
        <Typography
          variant='caption'
          style={{
            color: (order.ShippingServiceSelected.ShippingService.includes('xpress') ||
                                    order.ShippingServiceSelected.ShippingService.includes('XPRESS')) && 'tomato'
          }}
        >
                    Shipping Type: {order.ShippingServiceSelected.ShippingService}
        </Typography>
        <br />
        <Typography variant='caption'>
                    Shipping Cost: ${order.ShippingServiceSelected.ShippingServiceCost.content + ' ' + order.ShippingServiceSelected.ShippingServiceCost.currencyID}
        </Typography>
        <br />
        <Typography variant='caption'>
                    Total Cost: ${order.AmountPaid.content + ' ' + order.AmountPaid.currencyID}
        </Typography>
      </TableCell>
    )
  } else {
    return <TableCell />
  }
}

export default EbaySpecificStat
