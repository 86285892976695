import React, { useEffect, useState } from 'react'
import Modal from '@material-ui/core/Modal/Modal'
import Paper from '@material-ui/core/Paper/Paper'
import { Scrollbars } from 'react-custom-scrollbars'
import MUIDataTable from 'mui-datatables'

const DownloadChangelogModal = props => {
  const { open, itemId, closeModal } = props
  const [csvData, setCsvData] = useState([])
  useEffect(() => {
    window
      .fetch('/api/item/getChangelogs/' + itemId)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setCsvData(data)
      })
  }, [])

  const columns = [
    { name: 'reason', label: 'Reason' },
    { name: 'adjustedQty', label: 'Adjustment' },
    { name: 'oldQuantity', label: 'Old Quantity' },
    { name: 'newQuantity', label: 'New Quantity' },
    { name: 'oldDisplayQuantity', label: 'Old Display Quantity' },
    { name: 'newDisplayQuantity', label: 'New Display Quantity' },
    { name: 'username', label: 'User' },
    { name: 'updatedAt', label: 'Updated At' }
  ]

  return (
    <Modal open={open} onClose={() => closeModal()}>
      <Paper
        style={{
          width: '70%',
          margin: '10vh auto',
          height: '80vh',
          padding: 25
        }}
      >
        <Scrollbars>
          <MUIDataTable
            // title={"Shipment Report"}
            data={csvData.logs}
            columns={columns}
            options={{
              download: false,
              isSelectable: false,
              elevation: 0,
              selectableRows: 'none',
              selectableRowsHeader: false,
              sortOrder: { name: 'updatedAt', direction: 'desc' }
            }}
          />
        </Scrollbars>
      </Paper>
    </Modal>
  )
}

export default DownloadChangelogModal
