import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField/TextField'
import Grid from '@material-ui/core/Grid/Grid'
import Button from '@material-ui/core/Button/Button'
import axios from 'axios'

const ClientRatesPackagingRow = (props) => {
  const { row, setSnackbarOpen, setSnackbarMessage } = props

  const [cost, setCost] = useState(row.cost)
  const [origCost, setOrigCost] = useState(row.cost)

  function updateRow () {
    axios.post('/api/invoiceRates/updateRatePackaging', {
      body: JSON.stringify({
        _id: row._id,
        cost: cost
      })
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.data.success) {
          setOrigCost(cost)
          setSnackbarMessage('Updated packaging')
        } else {
          setSnackbarMessage('Failed')
        }
        setSnackbarOpen(true)
      })
  }

  return (
    <Grid item>
      <Grid container alignContent='center' alignItems='center' spacing={5}>
        <Grid item>
          <TextField
            type='number' label={row.name} helperText={'Saved as: ' + origCost}
            value={cost} onChange={e => setCost(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Button variant='contained' onClick={() => updateRow()}>Update</Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ClientRatesPackagingRow
