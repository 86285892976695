import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer } from 'mdbreact'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper/Paper'
import Typography from '@material-ui/core/Typography/Typography'
import Grid from '@material-ui/core/Grid/Grid'
import TextField from '@material-ui/core/TextField/TextField'
import Button from '@material-ui/core/Button/Button'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import { ObjectID } from 'bson'
import { ChevronLeft, Close } from '@material-ui/icons'
import { isEmpty } from '../../Utils/Utils'
import OrderContentsTable from './OrderContentsTable'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import DialogActions from '@material-ui/core/DialogActions/DialogActions'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import PackSlip from './PackSlip'
import Modal from '@material-ui/core/Modal/Modal'
import FulfillmentRows from './FulfillmentRows'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import MUIDataTable from 'mui-datatables'
import './styles.css'
import { Alert } from '@material-ui/lab'
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'
import DialogContentText from '@material-ui/core/DialogContentText'

function getModalStyle () {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}))

const OrderContentsPage = props => {
  const reduxState = useSelector(state => state)
  const classes = useStyles()
  const [modalStyle] = React.useState(getModalStyle)
  const [order, setOrder] = useState({})
  const [dbOrder, setDbOrder] = useState({})
  const [client, setClient] = useState({})
  const [store, setStore] = useState({})
  const [lineItems, setLineItems] = useState([])
  const [scanSku, setScanSku] = useState('')
  const [pickedArray, setPickedArray] = useState([])
  const [orderLoaded, setOrderLoaded] = useState(false)
  const [pickedArrayPopulated, setPickedArrayPopulated] = useState(false)
  const [fulfillmentRows, setFulfillmentRows] = useState([
    {
      rowId: 0,
      boxType: 'None',
      polybag: false,
      trackingNumber: '',
      weight: 0,
      isEparcel: false
    }
  ])
  const [allPicked, setAllPicked] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState({
    message: 'Order Locked',
    severity: 'warning'
  })
  const [courier, setCourier] = useState('None')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [boxTypes, setBoxTypes] = useState([])
  const [refunded, setRefunded] = useState(false)
  const [packingSlipModal, setPackingSlipModal] = useState(false)
  const [nextOrderId, setNextOrderId] = useState(null)
  const [platform, setPlatform] = useState('')
  const [qtyConfirmDialogue, setQtyConfirmDialogue] = useState(false)
  const [qtyConfirmErrorMessage, setQtyConfirmErrorMessage] = useState('')
  const [qtyConfirmDialogueFocus, setQtyConfirmDialogueFocus] = useState({})
  const [qtyConfirmNumber, setQtyConfirmNumber] = useState(0)
  const [qtyToFulfil, setQtyToFulfil] = useState(0)
  const [doubleCheckLineItems, setDoubleCheckLineItems] = useState(false)
  const [isEparcel, setEparcel] = React.useState(false)
  const [fulfilledByEparcel, setFulfilledByEparcel] = React.useState(false)
  const [
    isDomesticShippingAddress,
    setDomesticShippingAddress
  ] = React.useState(true)
  const [leaveAtDoor, setleaveAtDoor] = React.useState(false)
  const [eparcelNotes, setEparcelNotes] = React.useState('')
  const [
    internationalParcelDescription,
    setInternationalParcelDescription
  ] = React.useState()
  const [
    internationalParcelUnitValue,
    setInternationalParcelUnitValue
  ] = React.useState()
  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])
  const [isLoading, setIsLoading] = React.useState(true)
  const [batches, setBatches] = React.useState()
  const [batchDialogOpen, setBatchDialogOpen] = React.useState(false)
  const [batchList, setBatchList] = React.useState([])
  const [selectedBatchId, setSelectedBatchId] = React.useState()
  const [batchRowList, setBatchRowList] = React.useState([
    { selectedId: '', currentQuantity: 0, quantity: 0 }
  ])
  const [blockOrderDialog, setBlockOrderDialog] = React.useState(false)

  const [batch, setBatch] = React.useState()

  useEffect(() => {
    window
      .fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        props.setAuthQueryReceived(true)
        props.setIsLoggedIn(data.success)
        if (data.success) {
          if (
            reduxState.environmentVariables &&
            reduxState.environmentVariables.public &&
            reduxState.environmentVariables.public.analyticsTrackingId
          ) {
            ReactGA.initialize(
              reduxState.environmentVariables.public.analyticsTrackingId,
              { debug: false }
            )
            ReactGA.pageview(window.location.pathname + window.location.search)
          }

          window
            .fetch('/api/order/getOrderById/' + props.match.params.orderId)
            .then(res => res.json())
            .then(data => {
              setDbOrder(data)
              if (data.fulfillment) {
                if (data.fulfillment.isEparcel) {
                  setFulfilledByEparcel(true)
                  setEparcel(true)
                  setCourier('Australia Post')
                }
              }
              setOrderLoaded(true)
            })

          window
            .fetch('/api/batches/get-batches')
            .then(res => res.json())
            .then(data => {
              console.log(data)
              setBatches(data.batches)
            })
        }
      })
  }, [])

  useEffect(() => {
    if (!isEmpty(dbOrder)) {
      console.log(dbOrder.storeId)
      window
        .fetch('/api/client/getClientByStoreId/' + dbOrder.storeId)
        .then(res => res.json())
        .then(clientData => {
          if (clientData) {
            const storeObj = clientData.stores.find(
              a => a._id.toString() === dbOrder.storeId
            )
            setClient(clientData)
            setStore(storeObj)
            setPlatform(storeObj.platform)
            // forceUpdate()

            window
              .fetch(
                '/api/invoiceRates/getInvoiceRatesByStoreId/' + storeObj._id
              )
              .then(res => res.json())
              .then(data => {
                console.log(data)
                if (data) {
                  setBoxTypes(data.packaging)
                }
              })
          }
        })
    }
  }, [dbOrder])

  useEffect(() => {
    if (platform === 'Shopify') {
      window
        .fetch('/api/order/shopify/' + props.match.params.orderId)
        .then(res => res.json())
        .then(data => {
          setOrder(data)
          if (
            (data.displayFinancialStatus === 'REFUNDED' ||
              data.displayFulfillmentStatus === 'FULFILLED') &&
            !fulfilledByEparcel &&
            !dbOrder.blocked &&
            !dbOrder.allowChangingBlockedOrder
          ) {
            axios
              .post(
                '/api/order/blockOrder',
                {
                  body: JSON.stringify({
                    dbOrderId: dbOrder._id
                  })
                },
                {
                  headers: {
                    'Content-Type': 'application/json'
                  }
                }
              )
              .then(res => {})
              .catch(res => console.log(res))
          }
          if (data.displayFinancialStatus === 'REFUNDED') {
            setRefunded(true)
          }
        })

      if (dbOrder.odeoShippingAddress) {
        setDomesticShippingAddress(
          dbOrder.odeoShippingAddress.country
            .toString()
            .trim()
            .toUpperCase() === 'AUSTRALIA' ||
            dbOrder.odeoShippingAddress.country
              .toString()
              .trim()
              .toUpperCase() === 'AU'
        )
      } else {
        setDomesticShippingAddress(
          dbOrder.shippingAddress.country
            .toString()
            .trim()
            .toUpperCase() === 'AUSTRALIA' ||
            dbOrder.shippingAddress.country
              .toString()
              .trim()
              .toUpperCase() === 'AU'
        )
      }
    } else if (platform === 'eBay') {
      if (dbOrder.odeoShippingAddress) {
        setDomesticShippingAddress(
          dbOrder.odeoShippingAddress.CountryName.toString()
            .trim()
            .toUpperCase() === 'AUSTRALIA' ||
            dbOrder.odeoShippingAddress.CountryName.toString()
              .trim()
              .toUpperCase() === 'AU'
        )
      } else {
        setDomesticShippingAddress(
          dbOrder.ShippingAddress.CountryName.toString()
            .trim()
            .toUpperCase() === 'AUSTRALIA' ||
            dbOrder.ShippingAddress.CountryName.toString()
              .trim()
              .toUpperCase() === 'AU'
        )
      }
    } else if (platform === 'Inhouse') {
      if (dbOrder.odeoShippingAddress) {
        setDomesticShippingAddress(
          dbOrder.odeoShippingAddress.country
            .toString()
            .trim()
            .toUpperCase() === 'AUSTRALIA' ||
            dbOrder.odeoShippingAddress.country_code
              .toString()
              .trim()
              .toUpperCase() === 'AU'
        )
      } else {
        setDomesticShippingAddress(
          dbOrder.shippingAddress.country
            .toString()
            .trim()
            .toUpperCase() === 'AUSTRALIA' ||
            dbOrder.shippingAddress.country_code
              .toString()
              .trim()
              .toUpperCase() === 'AU'
        )
      }
    }
  }, [platform])

  useEffect(() => {
    setIsLoading(true)
    const myLineItems = []

    if (!isEmpty(client) && dbOrder.itemsPicked === undefined) {
      updatePickedArray(myLineItems)
    } else {
      if (!isEmpty(client)) {
        if (dbOrder.itemsPicked !== undefined) {
          if (platform !== 'eBay') {
            try {
              if (dbOrder.itemsPicked.length < dbOrder.lineItems.length) {
                updatePickedArray(myLineItems)
              }
            } catch (e) {
              setPlatform('eBay')
            }
          } else {
            console.log(myLineItems)
            updatePickedArray(myLineItems)
            setIsLoading(false)
          }
          let picked = true
          dbOrder.itemsPicked.map(a => {
            if (!a.picked) {
              picked = false
            }
          })
          setPickedArray(
            dbOrder.itemsPicked.sort((a, b) =>
              a.scannedAt > b.scannedAt ? -1 : 1
            )
          )
          setAllPicked(picked)

          setIsLoading(false)
        }
      }
    }
  }, [client, platform, doubleCheckLineItems])

  useEffect(async () => {
    console.log(pickedArray)
    if (pickedArray.length !== 0) {
      await Promise.all(
        pickedArray.map(async (pick, i) => {
          // Need to include inhouse for in stock quantity to appear
          if (
            pick.title === undefined ||
            pick.barcode === undefined ||
            platform === 'Inhouse'
          ) {
            return await window
              .fetch(
                '/api/item/getItemBySkuClient/' + pick.sku + '/' + client._id
              )
              .then(res => {
                return res.json()
              })
              .then(inv => {
                if (inv) {
                  pick.title = inv.title
                  pick.barcode = inv.barcode
                  pick.inStock = inv.quantity
                }
                return pick
              })
          }
        })
      ).then(result => {
        setPickedArrayPopulated(true)
      })
    }
  }, [pickedArray])

  function updatePickedArray (myLineItems) {
    let pushLineItems
    let len = 0
    var populateLineItemsPromise = new Promise((resolve, reject) => {
      if (platform === 'Shopify') {
        pushLineItems = Promise.all(
          dbOrder.lineItems.map((item, i) => {
            item.qtyToFulfil = item.fulfillableQty
            item.qtyOrdered = item.qty
            myLineItems.push(item)
            len++
          })
        ).then(() => {
          resolve(myLineItems)
        })
      } else if (platform === 'eBay') {
        if (Array.isArray(dbOrder.TransactionArray.Transaction)) {
          pushLineItems = Promise.all(
            dbOrder.TransactionArray.Transaction.map((item, i) => {
              item.sku = item.Item.SKU
              item.qtyToFulfil = item.QuantityPurchased
              item.title = item.Item.Title
              myLineItems.push(item)
              len++
            })
          ).then(() => {
            resolve(myLineItems)
          })
        } else {
          pushLineItems = Promise.all(
            [dbOrder.TransactionArray.Transaction].map((item, i) => {
              item.sku = item.Item.SKU
              item.qtyToFulfil = item.QuantityPurchased
              item.title = item.Item.Title
              myLineItems.push(item)
              len++
            })
          ).then(() => {
            resolve(myLineItems)
          })
        }
      } else if (platform === 'Inhouse') {
        setOrder(dbOrder.lineItems)
        pushLineItems = Promise.all(
          dbOrder.lineItems.map(async (item, i) => {
            // console.log(item)
            await window
              .fetch(
                '/api/item/getItemBySkuClient/' + item.sku + '/' + client._id
              )
              .then(res => res.json())
              .then(inv => {
                if (item.fulfillableQty !== undefined) {
                  inv.qtyToFulfil = item.fulfillableQty
                } else {
                  inv.qtyToFulfil = item.qty
                }
                inv.qtyOrdered = item.qty
                myLineItems.push(inv)
                len++
              })
          })
        ).then(() => {
          resolve(myLineItems)
        })
      }
    })
    Promise.all([populateLineItemsPromise]).then(res => {
      if (platform !== '' && pushLineItems !== undefined) {
        const myFulfillmentArray = []
        Promise.all(
          myLineItems.map(i => {
            if (pickedArray.find(a => a.sku === i.sku) === undefined) {
              // console.log(i)
              // console.log(i._id)
              myFulfillmentArray.push({
                _id: new ObjectID(),
                itemId: i._id,
                sku: i.sku,
                picked: false,
                barcode: i.barcode,
                qtyToFulfil: i.qtyToFulfil,
                title: i.title,
                scannedAt: new Date(0)
              })
            }
          })
        ).then(() => {
          // console.log("Setting picked array")
          const newPickedArray = [...pickedArray, ...myFulfillmentArray]
          newPickedArray.sort((a, b) => (a.scannedAt > b.scannedAt ? -1 : 1))
          setPickedArray([...pickedArray, ...myFulfillmentArray])
          setIsLoading(false)
          // forceUpdate()
          if (len > myLineItems.length) {
            setDoubleCheckLineItems(!doubleCheckLineItems)
            // forceUpdate()
          }
          setLineItems(myLineItems)
          // forceUpdate()
        })
      }
    })
  }

  function saveItemsPicked (findRow, qtyConfirmed) {
    console.log(findRow)
    const saveItems = new Promise((resolve, reject) => {
      const newFulfillmentArray = pickedArray
      const i = pickedArray.indexOf(findRow)
      if (qtyConfirmed <= findRow.qtyToFulfil) {
        newFulfillmentArray.splice(i, 1)
        // only set picked to true if scanned the correct amount
        if (parseInt(qtyConfirmed) === findRow.qtyToFulfil) {
          findRow.picked = true
        }
        findRow.scannedAt = new Date()
        findRow.qtyPicked = parseInt(qtyConfirmed)
        newFulfillmentArray.push(findRow)
        setDoubleCheckLineItems(!doubleCheckLineItems)
        setPickedArray(
          newFulfillmentArray.sort((a, b) => {
            return a.scannedAt > b.scannedAt ? 1 : -1
          })
        )
        // Check if all items have been picked
        let myBool = true
        newFulfillmentArray.map(a => {
          if (!a.picked) {
            myBool = false
          }
        })
        setAllPicked(myBool)
        console.log(batch)
        console.log(batchRowList)
        console.log(newFulfillmentArray)
        axios
          .post(
            '/api/order/skuScanned',
            {
              body: JSON.stringify({
                orderId: dbOrder._id,
                // batchDetails: batch,
                // batchRowList: batchRowList,
                itemsPicked: newFulfillmentArray.sort((a, b) => {
                  return a.scannedAt > b.scannedAt ? -1 : 1
                })
              })
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
          .then(() => {
            setBatch({})
          })
        setQtyConfirmDialogue(false)
        setScanSku('')
      } else {
        setQtyConfirmErrorMessage('You can not pick more than the ordered qty')
      }
      resolve()
    })
    // Promise.all([saveItems]).then(() => {
    //   console.log(pickedArray)
    //   console.log("Test Promise Fulfilled")
    // })
  }

  async function skuScanned () {
    let findRow = pickedArray.find(
      a => a.sku === scanSku.trim() && a.picked === false
    )
    if (!findRow) {
      findRow = pickedArray.find(
        a => a.barcode === scanSku.trim() && a.picked === false
      )
    }
    if (findRow) {
      setQtyToFulfil(findRow.qtyToFulfil)
      const batch = await checkIfBatch()
      // if batch exists then open up batch dialog
      if (batch) {
        console.log(batchRowList)
        setBatchDialogOpen(true)
        console.log(batch)
        console.log(batch.batchList)
        setBatchList(batch.batchList)
        setBatch(batch)
        forceUpdate()

        // if batch doesn't exist then check for multiple items
      } else {
        if (findRow.qtyToFulfil > 1) {
          setQtyConfirmDialogue(true)
          setQtyConfirmDialogueFocus(findRow)
          setQtyToFulfil(findRow.qtyToFulfil)
        } else {
          saveItemsPicked(findRow, 1)
        }
      }
    } else {
      if (scanSku.trim() !== '') {
        setSnackbarOpen(true)
        setSnackbarMessage({
          message: "SKU/Barcode doesn't match",
          severity: 'error'
        })
      }
    }
  }

  async function checkIfBatch () {
    console.log(scanSku)
    let returnBatch
    return Promise.all(
      batches.map(batch => {
        if (scanSku.trim() === batch.sku) {
          returnBatch = batch
        }
      })
    ).then(() => {
      return returnBatch
    })
  }

  function handleBatchDialogSave () {
    let quantitySum = 0
    let error = false
    // Check that all batch ids are unique
    const uniqueBatchIdList = [
      ...new Map(batchRowList.map(item => [item.selectedId, item])).values()
    ]

    if (uniqueBatchIdList.length === batchRowList.length) {
      Promise.all(
        batchRowList.map(row => {
          // Can't enter an amount that is higher than what we currently have in stock
          console.log(row.quantity)
          quantitySum = quantitySum + parseInt(row.quantity)
          if (row.quantity > row.currentQuantity) {
            console.log('Quantity entered above current quantity')
            setSnackbarMessage({
              message:
                'Cannot enter quantity that is higher than current quantity',
              severity: 'warning'
            })
            setSnackbarOpen(true)
            error = true
          }
        })
      )

      if (!error) {
        if (qtyToFulfil === quantitySum) {
          // Send POST request to backend to show that quantity has been scanned
          // window
          //   .fetch('/api/batches/scan-batch', {
          //     method: 'POST',
          //     headers: { 'Content-Type': 'application/json' },
          //     body: JSON.stringify({
          //       batchDetails: batch,
          //       batchRowList: batchRowList
          //     })
          //   })
          //   .then(res => res.json())
          //   .then(data => {
          //     console.log(data)
          //   })

          setBatchRowList([{ selectedId: '', currentQuantity: 0, quantity: 0 }])
          setBatchDialogOpen(false)
        } else {
          setSnackbarMessage({
            message: 'Sum of all quantities not equal to expected quantity',
            severity: 'warning'
          })
          setSnackbarOpen(true)
          return
        }

        // Check if multiple items
        let findRow = pickedArray.find(
          a => a.sku === scanSku.trim() && a.picked === false
        )
        if (!findRow) {
          findRow = pickedArray.find(
            a => a.barcode === scanSku.trim() && a.picked === false
          )
        }
        console.log(batchRowList)
        findRow.batchDetails = batch
        findRow.batchRowDetails = batchRowList
        if (findRow.qtyToFulfil > 1) {
          setQtyConfirmDialogue(true)
          setQtyConfirmDialogueFocus(findRow)
          setQtyToFulfil(findRow.qtyToFulfil)
        } else {
          saveItemsPicked(findRow, 1)
        }
      }
    } else {
      setSnackbarMessage({
        message: 'Duplicate Batch IDs found',
        severity: 'warning'
      })
      setSnackbarOpen(true)
    }
  }

  function handleBatchRowQuantityChange (qty, index) {
    console.log(qty, index)
    const newBatchRowList = batchRowList
    newBatchRowList[index].quantity = parseInt(qty)
    setBatchRowList(newBatchRowList)
  }

  function handleBatchRowIdChange (data, index) {
    if (data) {
      console.log(data)
      console.log(data.batchId, index)
      const newBatchRowList = batchRowList
      newBatchRowList[index].selectedId = data.batchId
      newBatchRowList[index].expirationDate = data.expirationDate
      console.log(newBatchRowList)
      // get current quantity of item
      Promise.all(
        batchList.map(batch => {
          if (batch.batchId === data.batchId) {
            newBatchRowList[index].currentQuantity = parseInt(batch.qty)
          }
        })
      ).then(() => {
        console.log(newBatchRowList)
        setBatchRowList(newBatchRowList)
        forceUpdate()
      })
    } else {
      console.log(index)
      // This means that id has been cleared, set current quantity of row to 0 again
      const newBatchRowList = batchRowList
      newBatchRowList[index].currentQuantity = 0
      newBatchRowList[index].expirationDate = 0
      setBatchRowList(newBatchRowList)
      forceUpdate()
    }
  }

  function handleAddBatchRow () {
    const newBatchRowList = batchRowList
    newBatchRowList.push({ selectedId: '', currentQuantity: 0, quantity: 0 })
    setBatchRowList(newBatchRowList)
    forceUpdate()
  }

  function handleRemoveBatchRow () {
    const newBatchRowList = batchRowList
    newBatchRowList.pop()
    setBatchRowList(newBatchRowList)
    forceUpdate()
  }
  function updateFulfillmentRows (value, i, key) {
    const fulfillmentRow = fulfillmentRows.find(a => a.rowId === i)
    const myFulfillmentRows = fulfillmentRows
    const index = myFulfillmentRows.indexOf(fulfillmentRow)
    if (key === 'weight') {
      fulfillmentRow[key] = parseInt(value)
    } else {
      fulfillmentRow[key] = value
    }
    myFulfillmentRows.splice(index, 1)
    myFulfillmentRows.push(fulfillmentRow)
    setFulfillmentRows(myFulfillmentRows)
  }

  function addRow () {
    setFulfillmentRows([
      ...fulfillmentRows,
      {
        _id: new ObjectID(),
        rowId: fulfillmentRows.length,
        trackingNumber: '',
        boxType: 'None',
        polybag: false,
        weight: 0,
        isEparcel: isEparcel
      }
    ])
  }

  function removeRow () {
    fulfillmentRows.splice(-1, 1)
    setFulfillmentRows([...fulfillmentRows])
  }

  function fulfillOrder (redirect) {
    let missingFields = false
    let rowError = 0
    fulfillmentRows.map((row, i) => {
      if (isEparcel && (row.weight === 0 || row.boxType === 'None')) {
        missingFields = true
        rowError = row.rowId + 1
      } else if (
        !isEparcel &&
        (row.weight === 0 ||
          row.trackingNumber === '' ||
          row.boxType === 'None')
      ) {
        missingFields = true
        rowError = row.rowId + 1
      }
    })

    if (missingFields) {
      if (courier.includes('None')) {
        setSnackbarMessage({ message: 'Choose a courier', severity: 'warning' })
      } else {
        setSnackbarMessage({
          message: 'Fill out all entries before fulfilling, Row: ' + rowError,
          severity: 'warning'
        })
      }
      setSnackbarOpen(true)
    } else {
      window
        .fetch('/api/order/fulfillOrder', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            fulfillmentRows,
            dbOrderId: dbOrder._id,
            courier,
            isEparcel,
            leaveAtDoor,
            eparcelNotes,
            isDomesticShippingAddress,
            internationalParcelDescription,
            internationalParcelUnitValue
          })
        })
        .then(res => res.json())
        .then(data => {
          setSnackbarMessage({
            message: data.success ? 'Fulfilled' : data.message,
            severity: data.success ? 'success' : 'error'
          })
          setSnackbarOpen(true)
          setDisableSubmit(true)
          if (redirect) {
            if (nextOrderId === null) {
              props.history.push('/orders')
            } else {
              props.history.push('/order/' + nextOrderId)
              window.location.reload(false)
            }
          }
        })
    }
  }

  if (!props.isLoggedIn && !props.isLoading) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { referrer: '/order/' + props.match.params.orderId }
        }}
      />
    )
  }

  const columns = [
    { name: 'trackingNumber', label: 'Tracking' },
    { name: 'boxType', label: 'Box Type' },
    {
      name: 'polybag',
      label: 'Polybag used',
      options: {
        customBodyRenderLite: dataIndex => {
          return dbOrder.fulfillment.fulfillmentRows[dataIndex].polybag
            ? 'TRUE'
            : 'FALSE'
        }
      }
    },
    { name: 'weight', label: 'Weight (g)' }
  ]

  function openPackSlip () {
    setPackingSlipModal(true)
    axios.post(
      '/api/order/packslipOpened',
      {
        body: JSON.stringify({
          orderId: dbOrder._id
        })
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
  }

  function blockOrder () {
    setDisableSubmit(true)
    axios
      .post(
        '/api/order/blockOrder',
        {
          body: JSON.stringify({
            dbOrderId: dbOrder._id
          })
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(res => {})
      .catch(err => console.log(err))
  }

  function blockOrderAndReturnToStock () {
    setDisableSubmit(true)
    axios
      .post(
        '/api/order/blockOrderAndReturnToStock',
        {
          body: JSON.stringify({
            clientId: client._id,
            dbOrderId: dbOrder._id,
            store: dbOrder.storeId,
            platform: platform
          })
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(res => {})
      .catch(err => console.log(err))
  }
  const handleEparcelChange = () => {
    const newValue = !isEparcel
    setEparcel(newValue)

    const newFulfillmentRows = []

    fulfillmentRows.map(row => {
      row.isEparcel = newValue
      newFulfillmentRows.push(row)
    })
  }

  const handleLeaveAtDoor = () => {
    setleaveAtDoor(!leaveAtDoor)
  }

  const handleEparcelNotesChange = e => {
    setEparcelNotes(e.target.value.trim())
  }

  const handleInternationalParcelDescriptionChange = e => {
    setInternationalParcelDescription(e.target.value)
  }

  const handleInternationalParcelUnitValueChange = e => {
    setInternationalParcelUnitValue(parseFloat(e.target.value.replace('$', '')))
  }

  return (
    <MDBContainer>
      <MDBBreadcrumb style={{ paddingTop: 10 }}>
        <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
          Order -{' '}
          {dbOrder && dbOrder.orderName ? dbOrder.orderName : dbOrder.OrderID}
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>
      <Paper elevation={10} style={{ padding: 25, marginBottom: 25 }}>
        <Grid container justify='space-between'>
          <Grid item>
            <Button onClick={() => props.history.push('/orders')}>
              <ChevronLeft /> Back
            </Button>
          </Grid>
          <Grid item>
            <Typography variant='h5'>
              Order {dbOrder.orderName} - {client && client.name}{' '}
              {store && store.platform} {store ? store.name : 'Test'} -{' '}
              {dbOrder.createdAt
                ? new Date(dbOrder.createdAt).toLocaleString('en-GB')
                : 'Loading...'}
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              value={scanSku}
              label='SKU'
              autoFocus
              onChange={e => setScanSku(e.target.value)}
              onKeyPress={e => e.key === 'Enter' && skuScanned(e)}
            />
          </Grid>
        </Grid>
        {!isLoading && pickedArrayPopulated ? (
          <OrderContentsTable
            order={order}
            dbOrder={dbOrder}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            refunded={refunded}
            pickedArray={pickedArray}
            platform={platform}
          />
        ) : (
          <LinearProgress style={{ width: '100%', margin: '25px 5px' }} />
        )}

        {fulfillmentRows.map((row, i) => {
          return (
            <FulfillmentRows
              key={i}
              updateFulfillmentRows={updateFulfillmentRows}
              i={i}
              row={row}
              fulfillmentRows={fulfillmentRows}
              boxTypes={boxTypes}
            />
          )
        })}
        <Grid
          container
          justify='space-between'
          alignItems='flex-end'
          alignContent='flex-end'
          style={{ marginTop: 25 }}
        >
          <Grid item>
            <Button onClick={() => addRow()}>
              <AddIcon color='primary' />
            </Button>
            {fulfillmentRows.length > 1 && (
              <Button onClick={() => removeRow()}>
                <RemoveIcon color='primary' />
              </Button>
            )}
          </Grid>
          <Grid item>
            <Button
              color='secondary'
              variant='contained'
              disabled={allPicked || disableSubmit}
              onClick={() => setBlockOrderDialog(true)}
            >
              Hide order
            </Button>
          </Grid>
          <Grid item>
            <InputLabel shrink htmlFor='material-label-placeholder'>
              Courier
            </InputLabel>
            <Select
              inputProps={{
                name: 'courier',
                id: 'courier-label-placeholder'
              }}
              name='Courier'
              variant='standard'
              onChange={e => setCourier(e.target.value)}
              value={courier}
            >
              <MenuItem value='None' disabled>
                Select One
              </MenuItem>
              <MenuItem value='Australia Post'>Australia Post</MenuItem>
              <MenuItem value='DHL'>DHL</MenuItem>
              <MenuItem value='Fastway'>Fastway</MenuItem>
              <MenuItem value='Direct Couriers'>Direct Couriers</MenuItem>
              <MenuItem value='TNT'>TNT</MenuItem>
              <MenuItem value='Free Postage'>Free Postage</MenuItem>
            </Select>
          </Grid>
          {courier === 'Australia Post' && (
            <Grid item>
              <FormControlLabel
                id='eparcel-label'
                control={
                  <Switch
                    checked={isEparcel}
                    onChange={handleEparcelChange}
                    color='primary'
                  />
                }
                label='eParcel'
              />
            </Grid>
          )}
          {/* {dbOrder.clientId === '5ed08bd1f98747472cc18354' && */}
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              disabled={!order && !lineItems}
              onClick={() => openPackSlip()}
            >
              Packing slip
            </Button>
          </Grid>
          {/* } */}

          <Grid item>
            <Button
              variant='contained'
              disabled={
                orderLoaded &&
                !fulfilledByEparcel &&
                !dbOrder.allowChangingBlockedOrder &&
                (!allPicked ||
                  disableSubmit ||
                  dbOrder.fulfillment ||
                  order.OrderStatus === 'Cancelled' ||
                  order.ShippedTime !== undefined ||
                  order.displayFulfillmentStatus === 'FULFILLED' ||
                  order.displayFinancialStatus === 'REFUNDED')
              }
              onClick={() => fulfillOrder(false)}
            >
              Fulfil
            </Button>
            <Button
              variant='contained'
              color='primary'
              disabled={
                orderLoaded &&
                !fulfilledByEparcel &&
                !dbOrder.allowChangingBlockedOrder &&
                (!allPicked ||
                  disableSubmit ||
                  dbOrder.fulfillment ||
                  order.OrderStatus === 'Cancelled' ||
                  order.ShippedTime !== undefined ||
                  order.displayFulfillmentStatus === 'FULFILLED' ||
                  order.displayFinancialStatus === 'REFUNDED')
              }
              onClick={() => fulfillOrder(true)}
            >
              Fulfil & Next
            </Button>
          </Grid>
        </Grid>
        {isEparcel && (
          <Grid container style={{ marginTop: 25, marginLeft: 25 }}>
            <Grid item lg={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={leaveAtDoor}
                    onChange={handleLeaveAtDoor}
                    color='secondary'
                  />
                }
                label='Leave at door'
              />

              {leaveAtDoor && (
                <Grid item>
                  <TextField
                    id='outlined-basic'
                    label='eParcel Notes'
                    variant='outlined'
                    multiline
                    onChange={handleEparcelNotesChange}
                    rows={4}
                  />
                </Grid>
              )}
            </Grid>
            {!isDomesticShippingAddress && (
              <>
                <Grid item lg={4}>
                  <TextField
                    fullWidth
                    helperText='International parcel description'
                    onChange={handleInternationalParcelDescriptionChange}
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    placeholder='$'
                    helperText='International parcel unit value'
                    onChange={handleInternationalParcelUnitValueChange}
                  />
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Paper>
      {dbOrder.fulfillment && (
        <MUIDataTable
          title={'Fulfillment Data - Courier: ' + dbOrder.fulfillment.courier}
          data={dbOrder.fulfillment.fulfillmentRows}
          columns={columns}
          options={{
            download: false,
            isSelectable: false,
            elevation: 0,
            selectableRows: 'none',
            selectableRowsHeader: false
          }}
        />
      )}
      {packingSlipModal && (
        <Modal
          open={packingSlipModal}
          onClose={() => setPackingSlipModal(false)}
        >
          {order && lineItems && (
            <>
              <Grid
                container
                alignContent='flex-end'
                alignItems='flex-end'
                justify='flex-end'
              >
                <Grid item>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => setPackingSlipModal(false)}
                    style={{ textAlign: 'right' }}
                  >
                    <Close style={{ color: 'white' }} />
                  </Button>
                </Grid>
              </Grid>
              <PackSlip
                shippingAddress={
                  order.shippingAddress
                    ? order.shippingAddress
                    : order.ShippingAddress
                }
                pickedArray={pickedArray}
                orderNo={dbOrder.orderName ? dbOrder.orderName : order.OrderID}
                client={client}
              />
            </>
          )}
        </Modal>
      )}

      {/* <Modal open={eParcelModal} onClose={() => seteParcelModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <div style={modalStyle} className={classes.paper}>
            <Container>

            </Container>

        </div>
      </Modal> */}
      <Snackbar
        // message={snackbarMessage}
        // message={dbOrder.fulfillment || order.displayFulfillmentStatus === "FULFILLED" ? "Order locked" : snackbarMessage}
        // open={snackbarOpen}
        open={
          snackbarOpen ||
          disableSubmit ||
          (!fulfilledByEparcel &&
            !dbOrder.allowChangingBlockedOrder &&
            (dbOrder.fulfillment ||
              order.displayFulfillmentStatus === 'FULFILLED' ||
              order.OrderStatus === 'Cancelled'))
        }
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          elevation={6}
          variant='filled'
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarMessage.severity}
        >
          {snackbarMessage.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={qtyConfirmDialogue}
        onClose={() => setQtyConfirmDialogue(false)}
      >
        <DialogTitle>Qty Confirm</DialogTitle>
        <DialogContent>
          <TextField
            type='number'
            onKeyDown={e =>
              e.key === 'Enter' &&
              saveItemsPicked(qtyConfirmDialogueFocus, qtyConfirmNumber)
            }
            onChange={e => setQtyConfirmNumber(e.target.value)}
            helperText={'Should be: ' + qtyToFulfil}
            autoFocus
            error={qtyConfirmErrorMessage !== ''}
          />
          <br />
          {qtyConfirmErrorMessage !== '' && (
            <Typography variant='caption' style={{ color: 'red' }}>
              {qtyConfirmErrorMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setQtyConfirmDialogue(false)}>Cancel</Button>
          <Button
            onClick={() =>
              saveItemsPicked(qtyConfirmDialogueFocus, qtyConfirmNumber)
            }
            color='primary'
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={batchDialogOpen}
        onClose={() => {
          setBatchRowList([{ selectedId: '', quantity: 0 }])
          setBatchDialogOpen(false)
        }}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle>Confirm Batch</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Expected total quantity: {qtyToFulfil}
          </DialogContentText>
          {batchRowList.map((row, i) => {
            return (
              <Grid style={{ paddingTop: 15 }} key={i} container>
                <Grid item xs={4}>
                  <Autocomplete
                    options={batchList}
                    getOptionLabel={option =>
                      option === undefined ? 'loading...' : option.batchId
                    }
                    onChange={(e, value) => handleBatchRowIdChange(value, i)}
                    value={row.batchId}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Batch ID Select'
                        variant='outlined'
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={2}>
                  <TextField
                    id='outlined-number'
                    fullWidth
                    label='Current Quantity'
                    disabled
                    type='number'
                    value={batchRowList[i].currentQuantity}
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                  <TextField
                    id='outlined-number'
                    fullWidth
                    label='Quantity Picked'
                    type='number'
                    onChange={e =>
                      handleBatchRowQuantityChange(e.target.value, i)
                    }
                    defaultValue={0}
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant='outlined'
                  />
                </Grid>
              </Grid>
            )
          })}

          <Grid container style={{ paddingTop: 25, paddingLeft: 10 }}>
            <Grid item xs={5}>
              <Button
                variant='contained'
                color='primary'
                style={{ width: '50%' }}
                onClick={() => handleAddBatchRow()}
              >
                Add row
              </Button>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <Button
                variant='contained'
                color='secondary'
                disabled={batchRowList.length === 1}
                style={{ width: '50%' }}
                onClick={() => handleRemoveBatchRow()}
              >
                Remove row
              </Button>
            </Grid>
          </Grid>
          <br />
          {qtyConfirmErrorMessage !== '' && (
            <Typography variant='caption' style={{ color: 'red' }}>
              {qtyConfirmErrorMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setBatchRowList([{ selectedId: '', quantity: 0 }])
              setBatchDialogOpen(false)
            }}
          >
            Cancel
          </Button>
          <Button onClick={() => handleBatchDialogSave()} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={blockOrderDialog}
        onClose={() => setBlockOrderDialog(false)}
      >
        <DialogTitle>Return Items to Stock</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Do you want to return items back to stock?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              blockOrder()
              setBlockOrderDialog(false)
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              blockOrderAndReturnToStock()
              setBlockOrderDialog(false)
            }}
            color='primary'
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </MDBContainer>
  )
}

export default OrderContentsPage
