import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper/Paper'
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress'
import { DateTime } from 'luxon'
import socketIOClient from 'socket.io-client'
import _ from 'lodash'

import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBContainer,
  MDBSpinner
} from 'mdbreact'

const EparcelManifestPage = (props) => {
  const reduxState = useSelector(state => state)
  const [manifests, setManifests] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)
  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  useEffect(() => {
    window
      .fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        props.setAuthQueryReceived(true)
        props.setIsLoggedIn(data.success)
        if (data.success) {
          if (
            reduxState.environmentVariables &&
            reduxState.environmentVariables.public &&
            reduxState.environmentVariables.public.analyticsTrackingId
          ) {
            ReactGA.initialize(
              reduxState.environmentVariables.public.analyticsTrackingId,
              { debug: false }
            )
            ReactGA.pageview(window.location.pathname + window.location.search)
          }
        }
      })
      .then(() => {
        window
          .fetch('/api/eparcel/getManifests')
          .then(res => res.json())
          .then(data => {
            setManifests(data.manifests)
          })
      })
  }, [])

  useEffect(() => {
    if (manifests.length > 0) {
      setIsLoaded(true)
      forceUpdate()
    }
  }, [manifests])

  useEffect(() => {
    const socket = socketIOClient(process.env.REACT_APP_SOCKET_IO_ENDPOINT)
    socket.on('connect', () => {
      // emit message to backend to watch change on eparcel-manifest
      socket.emit('EparcelManifestPage-watch-auspost-api-order-summary')
    })

    socket.on('EparcelManifestPage-auspost-api-order-summary-event', data => {
      // Received message, process it.
      if (data.operationType === 'insert') {
        manifests.unshift(data.fullDocument)
        setManifests([...manifests])
      } else if ((data.operationType === 'update') || (data.operationType === 'replace')) {
        // Find item index using _.findIndex (thanks @AJ Richardson for comment)
        const index = _.findIndex(manifests, { _id: data.fullDocument._id })
        if (index > -1) {
          // Replace item at index using native splice
          manifests.splice(index, 1, data.fullDocument)
          setManifests([...manifests])
        }
      } else if (data.operationType === 'delete') {
        // Find item index using _.findIndex (thanks @AJ Richardson for comment)
        const index = _.findIndex(manifests, { _id: data.documentKey._id })
        if (index > -1) {
          // delete item at index using native splice
          manifests.splice(index, 1)
          setManifests([...manifests])
        }
      }
    })

    // CLEAN UP THE EFFECT
    return () => {
      if (socket !== undefined) {
        socket.disconnect()
      }
    }
  }, [manifests])

  // Must include this code block to redirect user to the login page if user is not logged in.
  // Otherwise if the session has timed out and the user access this page it will appear as loading indefinitely.
  // This code block must be placed immediately after the userEffect() blocks.
  if (!props.isLoggedIn && !props.isLoading) {
    return (
      <Redirect to={{ pathname: '/login', state: { referrer: '/eparcel-manifests' } }} />
    )
  }

  const handleDownloadClick = id => {
    // Search for document with id
    manifests.map(manifest => {
      if (manifest._id === id) {
        // check if manifest has manifest url
        if (manifest.s3 && manifest.s3.presignedS3Url) {
          window.open(manifest.s3.presignedS3Url)
        }
      }
    })
  }

  return (
    <>
      <MDBContainer>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
          <MDBBreadcrumbItem active>eParcel Manifests</MDBBreadcrumbItem>
        </MDBBreadcrumb>
      </MDBContainer>
      <MDBContainer>
        <Paper style={{ padding: 25 }} elevation={5}>
          {isLoaded ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Manifest Name</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoaded &&
                  manifests.map(manifest => {
                    return (
                      <TableRow key={manifest._id}>
                        <TableCell>{manifest.auspostOrderId}</TableCell>
                        <TableCell>
                          {DateTime.fromJSDate(new Date(manifest.createdAt)).setLocale('en-AU').toFormat('d-LLL-yyyy h:mm:ss a')}
                        </TableCell>
                        {manifest.s3
                          ? <TableCell>
                            {' '}
                            <Button
                              onClick={() => handleDownloadClick(manifest._id)}
                              color='primary'
                            >
                            Download
                            </Button>
                            </TableCell> : <TableCell><MDBSpinner small /></TableCell>}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          ) : (
            <LinearProgress style={{ width: '100%', margin: '25px 5px' }} />
          )}
        </Paper>
      </MDBContainer>
    </>
  )
}

export default EparcelManifestPage
