import React, { useEffect, useState } from 'react'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TextField from '@material-ui/core/TextField/TextField'
import { MDBBtn } from 'mdbreact'
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'

const ManageShipmentsContentsRowPage = props => {
  const {
    content,
    i,
    setShipmentContents,
    shipmentContents,
    receivedDate,
    cancelled,
    shipmentId
  } = props
  const [sku, setSku] = useState('')
  const [title, setTitle] = useState('')
  const [receivedQty, setReceivedQty] = useState(
    content.receivedQty ? content.receivedQty : 0
  )
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [saved, setSaved] = useState(!!content.receivedQty)

  useEffect(() => {
    document.addEventListener("wheel", (event) => {  
      if (document.activeElement.type === "number") {  
        document.activeElement.blur();  
      }  
    });
    returnInventorySku(content.itemId, setSku)
    returnInventoryTitle(content.itemId, setTitle)
  }, [])

  function returnInventorySku (id, setSku) {
    window
      .fetch('/api/item/getItemById/' + id)
      .then(res => res.json())
      .then(data => {
        setSku(data.inventory.sku)
      })
  }

  function returnInventoryTitle (id, setTitle) {
    window
      .fetch('/api/item/getItemById/' + id)
      .then(res => res.json())
      .then(data => {
        setTitle(data.inventory.title)
      })
  }

  function updateReceivedQty () {
    if (receivedQty === undefined || receivedQty === '') {
      setSnackbarOpen(true)
      setSnackbarMessage('Please enter an appropriate value')
    } else {
      axios
        .post(
          '/api/shipment/updateReceivedQty',
          {
            _id: content._id,
            shipmentId,
            receivedQty
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        .then(res => {
          setSnackbarOpen(true)
          setSnackbarMessage('Saved')
          setSaved(true)
        })
    }
  }

  function changeReceivedQty (qty) {
    // console.log(qty)
    setReceivedQty(qty)
    const newShipmentContents = shipmentContents
    const myObj = shipmentContents[i]
    myObj.receivedQty = parseInt(qty)
    newShipmentContents[i] = myObj
    setShipmentContents(newShipmentContents, console.log(shipmentContents))
  }

  return (
    <TableRow style={{ backgroundColor: saved && 'lightgreen' }}>
      <TableCell>{i}</TableCell>
      <TableCell>
        {sku} <br /> {title}
      </TableCell>
      <TableCell>{content.incomingQty}</TableCell>
      <TableCell>
        <TextField
          type='number'
          defaultValue={content.receivedQty && receivedQty}
          onChange={e => changeReceivedQty(e.target.value)}
        />
      </TableCell>
      <TableCell>
        <MDBBtn
          rounded
          disabled={receivedDate || cancelled}
          onClick={() => updateReceivedQty()}
        >
          <b>Save</b>
        </MDBBtn>
      </TableCell>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        message={snackbarMessage}
        onClose={() => setSnackbarOpen(false)}
      />
    </TableRow>
  )
}

export default ManageShipmentsContentsRowPage
