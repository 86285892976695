import Grid from '@material-ui/core/Grid/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'
import TextField from '@material-ui/core/TextField/TextField'
import React from 'react'
import Typography from '@material-ui/core/Typography/Typography'

const BundleCreateRows = (props) => {
  const { clientInv, updateSelectedInvItem, updateSelectedInvQty, i } = props

  return (
    <Grid item xs={12} style={{ marginTop: 5 }}>
      <Grid container justify='space-evenly' alignItems='center' alignContent='center'>
        <Grid item xs={1}>
          <Typography variant='caption' align='center'>{i + 1}.</Typography>
        </Grid>
        <Grid item xs={5}>
          <Autocomplete
            options={clientInv}
            getOptionLabel={(option) => option === undefined ? '' : option.sku + ' - ' + option.title}
            onChange={(e, value) => updateSelectedInvItem(i, value)}
            renderInput={(params) => <TextField {...params} label='SKU Select' variant='outlined' />}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            fullWidth type='number' label='Quantity in bundle'
            onChange={(e) => updateSelectedInvQty(i, e.target.value)}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BundleCreateRows
