import { v4 as uuidv4 } from 'uuid'
import { name, version, environment } from '../../../package.json'
import {
  SET_CLIENT_SESSION_UUID,
  SET_MENU_INVOICING_AUTHORIZATION,
  SET_MENU_REPORTS_AUTHORIZATION,
  SET_MENU_RETURNS_AUTHORIZATION,
  SET_MENU_EVENTS_AUTHORIZATION,
  SET_MENU_NOTIFICATIONS_AUTHORIZATION,
  SET_ENVIRONMENT_VARIABLES,
  SET_USER_PREFERENCES,
  SET_MENU_EPARCEL_AUTHORIZATION
} from '../constants/actionTypes'

const initialState = {
  // loggingAuthKey: '4a6ba11e-a5e8-4fbd-8c6a-841ae8c214bb',
  appName: name,
  appVersion: version,
  environment,
  clientSideSessionUuid: uuidv4(),
  showMenuInvoicing: false,
  showMenuReports: false,
  showMenuReturns: false,
  showMenuEvents: false,
  showMenuNotifications: false,
  showMenuEparcel: false,
  environmentVariables: {},
  userPreferences: {}
}

const rootReducer = (state = initialState, action) => {
  if (action.type === SET_CLIENT_SESSION_UUID) {
    return {
      ...state,
      clientSideSessionUuid: action.payload
    }
  } else if (action.type === SET_MENU_INVOICING_AUTHORIZATION) {
    return {
      ...state,
      showMenuInvoicing: action.payload
    }
  } else if (action.type === SET_MENU_REPORTS_AUTHORIZATION) {
    return {
      ...state,
      showMenuReports: action.payload
    }
  } else if (action.type === SET_MENU_RETURNS_AUTHORIZATION) {
    return {
      ...state,
      showMenuReturns: action.payload
    }
  } else if (action.type === SET_MENU_EVENTS_AUTHORIZATION) {
    return {
      ...state,
      showMenuEvents: action.payload
    }
  } else if (action.type === SET_MENU_NOTIFICATIONS_AUTHORIZATION) {
    return {
      ...state,
      showMenuNotifications: action.payload
    }
  } else if (action.type === SET_ENVIRONMENT_VARIABLES) {
    return {
      ...state,
      environmentVariables: action.payload
    }
  } else if (action.type === SET_MENU_EPARCEL_AUTHORIZATION) {
    return {
      ...state,
      showMenuEparcel: action.payload
    }
  } else if (action.type === SET_USER_PREFERENCES) {
    return {
      ...state,
      userPreferences: action.payload
    }
  }
  return state
}

export default rootReducer
