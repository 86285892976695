import React, { useState } from 'react'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TextField from '@material-ui/core/TextField/TextField'
import Button from '@material-ui/core/Button/Button'
import axios from 'axios'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'

const OrderContentsRow = (props) => {
  const {
    dbOrder,
    item,
    pickedArray,
    refunded,
    setSnackbarMessage,
    setSnackbarOpen,
    shopify,
    showQtyOrdered
  } = props
  const [restockQty, setRestockQty] = useState(
    shopify ? item.qtyToFulfil : null
  )
  const [checkbox, setCheckbox] = useState(false)

  let lineItem
  if (shopify) {
    lineItem = dbOrder.lineItems.find(a => a.itemId === item._id)
  }
  const [restocked, setRestocked] = useState(
    shopify && lineItem ? lineItem.restocked : false
  )

  function restockItem () {
    if (!restocked) {
      axios
        .post(
          '/api/order/restockItem',
          {
            body: JSON.stringify({
              item,
              dbOrder
            })
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        .then(res => {
          if (res.data.success) {
            setSnackbarMessage('Restocked item - ' + item.sku)
            setRestocked(true)
          } else {
            setSnackbarMessage("Couldn't restock item - " + item.sku)
          }
          setSnackbarOpen(true)
        })
        .catch(res => {
          setSnackbarMessage("Couldn't restock item - " + item.sku)
          setSnackbarOpen(true)
        })
    }
  }

  return (
    <TableRow
      hover
      style={{
        backgroundColor:
          pickedArray.find(
            a => a.sku === item.sku && a.picked === true && a._id === item._id
          ) && 'palegreen'
      }}
    >
      <TableCell>
        <Checkbox
          color='primary'
          checked={checkbox}
          onClick={() => setCheckbox(!checkbox)}
        />
      </TableCell>
      <TableCell>{item.inStock}</TableCell>
      <TableCell>{item.sku}</TableCell>
      <TableCell>{item.title}</TableCell>
      {showQtyOrdered && <TableCell>{item.qtyOrdered}</TableCell>}
      <TableCell>{item.qtyToFulfil}</TableCell>
      <TableCell
        style={{ color: item.qtyPicked !== item.qtyToFulfil && 'red' }}
      >
        {item.qtyPicked !== undefined || item.qtyPicked !== 0
          ? item.qtyPicked
          : 0}
      </TableCell>
      {refunded && shopify && (
        <TableCell>
          <TextField
            value={restockQty}
            onChange={e => setRestockQty(e.target.value)}
            label='Restock qty'
          />
          <Button disabled={restocked} onClick={() => restockItem()}>
            Restock
          </Button>
        </TableCell>
      )}
    </TableRow>
  )
}

export default OrderContentsRow
