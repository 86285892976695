import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import { withSwalInstance } from 'sweetalert2-react'
import swal from 'sweetalert2'
import { DateTime } from 'luxon'
import { v4 as uuidv4 } from 'uuid'
import ReCAPTCHA from 'react-google-recaptcha'
import ReactGA from 'react-ga'
import { SET_ENVIRONMENT_VARIABLES } from '../../redux/constants/actionTypes'

const ForgotPasswordPage = (props) => {
  const dispatch = useDispatch()
  const reduxState = useSelector(state => state)
  const SweetAlert = withSwalInstance(swal)
  const [operationUuid, setOperationUuid] = useState(uuidv4())
  const [email, setEmail] = useState('')
  const [emailOk, setEmailOk] = useState(true)
  const [recaptchaOk, setRecaptchaOk] = useState(true)
  const [recaptchaToken, setRecaptchaToken] = useState()
  const [result, setResult] = useState({})
  const [showResult, setShowResult] = useState(false)
  const [environmentVariables, setEnvironmentVariables] = useState({})
  const recaptchaRef = React.createRef()

  useEffect(() => {
    window.fetch('/api/env/')
      .then(res => res.json())
      .then(data => {
        setEnvironmentVariables(data)
        dispatch({ type: SET_ENVIRONMENT_VARIABLES, payload: data })

        // Add Google Analytics
        ReactGA.initialize(data.public.analyticsTrackingId, { debug: false })
        ReactGA.pageview(window.location.pathname + window.location.search)
      })
  }, [])

  function handleRecaptchaChange (value) {
    setRecaptchaToken(value)
    setRecaptchaOk(true)
  }

  function handleKeyDown (e) {
    if (e.key === 'Enter') {
      handleSubmitClick(e)
    }
  }

  function handleEmailChange (e) {
    setEmail(e.target.value)
  }

  function clientsideValidateEmail (email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  function handleSubmitClick (e) {
    const isEmailValid = clientsideValidateEmail(email)
    setEmailOk(isEmailValid)
    if (!isEmailValid) {
      return
    }

    if (!recaptchaToken) {
      setRecaptchaOk(false)
      return
    }

    recaptchaRef.current.reset()
    setRecaptchaToken('')
    setOperationUuid(uuidv4())

    window.fetch('/api/clientsideLogging/write', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        loggingAuthKey: reduxState.environmentVariables.public.loggingAuthKey,
        appName: reduxState.appName,
        appVersion: reduxState.appVersion,
        clientSessionUuid: reduxState.clientSideSessionUuid,
        operationUuid: operationUuid,
        datetime: DateTime.local().toString(),
        component: 'ForgotPasswordPage',
        message: `Requesting password reset for ${email}.`
      })
    })

    window.fetch('/api/auth/reset-password-request', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        clientSessionUuid: reduxState.clientSideSessionUuid,
        operationUuid: operationUuid,
        username: email,
        recaptchaToken
      })
    }).then(res => res.json())
      .then(function (data) {
        setShowResult(true)
        setResult({ ...data, sweetalertType: data.success ? 'success' : 'error' })

        window.fetch('/api/clientsideLogging/write', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            loggingAuthKey: reduxState.environmentVariables.public.loggingAuthKey,
            appName: reduxState.appName,
            appVersion: reduxState.appVersion,
            clientSessionUuid: reduxState.clientSideSessionUuid,
            operationUuid: operationUuid,
            severity: data.success ? 'INFO' : 'ERROR',
            datetime: DateTime.local().toString(),
            component: 'ForgotPasswordPage',
            message: data.message
          })
        })
      }).catch(e => console.log(e))
  }
  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol md='4'>
          <form>
            <p className='h3 text-center mb-4'>Enter Your Email</p>
            <p className='mb-4'>You will receive an email with details on how to reset your password.</p>
            <label htmlFor='defaultFormLoginEmailEx' className='grey-text'>
                            Email
            </label>
            <input
              type='email' id='defaultFormLoginEmailEx' autoFocus className='form-control'
              onChange={handleEmailChange} value={email}
            />
            <div style={divStyle.center}>
              {environmentVariables.public ? <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={environmentVariables.public.recaptchaV2SiteKey}
                onChange={handleRecaptchaChange}
                                             /> : ''}
            </div>
            <div className='text-center mt-4'>
              <MDBBtn rounded color='indigo' onClick={handleSubmitClick}>Submit</MDBBtn>
            </div>
            {emailOk ? <div />
              : <div className='red-text'><br />Please enter a valid email address.</div>}
            {recaptchaOk ? <div />
              : <div className='red-text'><br />Please verify you are not a robot.</div>}

          </form>
        </MDBCol>
      </MDBRow>
      <MDBRow center />
      <MDBRow center>
        <SweetAlert
          show={showResult}
          title='Reset Password Request'
          text={result.message}
          type={result.sweetalertType}
          onConfirm={() => {
            setShowResult(false)
            setEmail('')
          }}
        />
      </MDBRow>
    </MDBContainer>
  )
}

const divStyle = {
  center: {
    paddingTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}

export default ForgotPasswordPage
