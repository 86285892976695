import TextField from '@material-ui/core/TextField/TextField'
import Paper from '@material-ui/core/Paper/Paper'
import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import Button from '@material-ui/core/Button/Button'
import axios from 'axios'

const ClientRatesPackModal = (props) => {
  const { client, setSnackbarOpen, setSnackbarMessage, setCreatePackModal, forceUpdate, setForceUpdate } = props
  const [tag, setTag] = useState('')
  const [cost, setCost] = useState(0)

  function saveRow () {
    axios.post('/api/invoiceRates/createRatePackaging', {
      body: JSON.stringify({
        clientId: client._id,
        cost,
        tag
      })
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.data.success) {
          setSnackbarMessage('Created packaging')
          setCreatePackModal(false)
          setForceUpdate(!forceUpdate)
        } else {
          setSnackbarMessage('Failed')
        }
        setSnackbarOpen(true)
      })
  }

  return (
    <Paper style={{ padding: 25, maxHeight: '80vh', maxWidth: '20vw', marginLeft: '40vw', marginTop: '10vh' }}>
      <Grid container direction='column' spacing={3}>
        <Grid item>
          <TextField label='Box Type' fullwidth onChange={e => setTag(e.target.value)} />
        </Grid>
        <Grid item>
          <TextField label='Cost' fullwidth onChange={e => setCost(e.target.value)} type='number' />
        </Grid>
        <Grid item>
          <Grid container justify='flex-end' spacing={3}>
            <Grid item><Button variant='contained' color='primary' onClick={() => saveRow()}>Save</Button></Grid>
            <Grid item><Button variant='contained' onClick={() => setCreatePackModal(false)}>Cancel</Button></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ClientRatesPackModal
