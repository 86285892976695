import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBCollapse,
  MDBCollapseHeader,
  MDBContainer,
  MDBIcon
} from 'mdbreact'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import ItemLogsPage from '../Items/ItemLogsPage'
import ShipmentReportPage from '../Shipments/ShipmentReportPage'
import OrderReportPage from '../Orders/OrderReportPage'
import CurrentInventory from './InventorySnapshot'
import BatchReportPage from '../Batches/BatchReportPage'
const ReportsPage = props => {
  const reduxState = useSelector(state => state)
  const [collapseId, setCollapseId] = useState('collapse0')
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [authQueryReceived, setQueryReceived] = useState(false)

  useEffect(() => {
    if (
      reduxState.environmentVariables &&
      reduxState.environmentVariables.public &&
      reduxState.environmentVariables.public.analyticsTrackingId
    ) {
      ReactGA.initialize(
        reduxState.environmentVariables.public.analyticsTrackingId,
        { debug: false }
      )
      ReactGA.pageview(window.location.pathname + window.location.search)
    }

    window
      .fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        setLoggedIn(data.success)
        setQueryReceived(true)
      })
  }, [])
  if (!props.isLoggedIn && !props.isLoading) {
    return (
      <Redirect to={{ pathname: '/login', state: { referrer: '/reports' } }} />
    )
  }

  function toggleCollapse (id) {
    if (collapseId !== id) {
      setCollapseId(id)
    } else {
      setCollapseId('')
    }
  }

  return (
    <div>
      {props.isLoggedIn ? (
        <MDBContainer>
          <MDBBreadcrumb>
            <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
            <MDBBreadcrumbItem>Items</MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>Reports</MDBBreadcrumbItem>
          </MDBBreadcrumb>
          <MDBContainer className='md-accordion mt-5'>
            <MDBCard
              className='mt-3'
              style={{ backgroundColor: 'rgba(0,0,0,.03)' }}
            >
              <MDBCollapseHeader
                tagClassName='d-flex justify-content-between'
                onClick={() => toggleCollapse('collapse0')}
              >
                Inventory Snapshots
                <MDBIcon
                  icon={collapseId === 'collapse0' ? 'angle-up' : 'angle-down'}
                />
              </MDBCollapseHeader>
              {collapseId === 'collapse0' && (
                <MDBCollapse id='collapse0' isOpen={collapseId}>
                  <MDBCardBody>
                    <CurrentInventory />
                  </MDBCardBody>
                </MDBCollapse>
              )}
            </MDBCard>
            <MDBCard
              className='mt-3'
              style={{ backgroundColor: 'rgba(0,0,0,.03)' }}
            >
              <MDBCollapseHeader
                tagClassName='d-flex justify-content-between'
                onClick={() => toggleCollapse('collapse1')}
              >
                Items report
                <MDBIcon
                  icon={collapseId === 'collapse1' ? 'angle-up' : 'angle-down'}
                />
              </MDBCollapseHeader>
              {collapseId === 'collapse1' && (
                <MDBCollapse id='collapse1' isOpen={collapseId}>
                  <MDBCardBody>
                    {/* <Typography variant='body1'>aaa</Typography> */}
                    <ItemLogsPage />
                  </MDBCardBody>
                </MDBCollapse>
              )}
            </MDBCard>
            <MDBCard
              className='mt-3'
              style={{ backgroundColor: 'rgba(0,0,0,.03)' }}
            >
              <MDBCollapseHeader
                tagClassName='d-flex justify-content-between'
                onClick={() => toggleCollapse('collapse2')}
              >
                Shipment Reports
                <MDBIcon
                  icon={collapseId === 'collapse2' ? 'angle-up' : 'angle-down'}
                />
              </MDBCollapseHeader>
              {collapseId === 'collapse2' && (
                <MDBCollapse id='collapse2' isOpen={collapseId}>
                  <MDBCardBody>
                    <MDBContainer>
                      <MDBCardBody>
                        {/* <Typography variant='h6'>asd</Typography> */}
                        <ShipmentReportPage />
                      </MDBCardBody>
                    </MDBContainer>
                  </MDBCardBody>
                </MDBCollapse>
              )}
            </MDBCard>
            <MDBCard
              className='mt-3'
              style={{ backgroundColor: 'rgba(0,0,0,.03)' }}
            >
              <MDBCollapseHeader
                tagClassName='d-flex justify-content-between'
                onClick={() => toggleCollapse('collapse3')}
              >
                Orders Reports
                <MDBIcon
                  icon={collapseId === 'collapse3' ? 'angle-up' : 'angle-down'}
                />
              </MDBCollapseHeader>
              {collapseId === 'collapse3' && (
                <MDBCollapse id='collapse3' isOpen={collapseId}>
                  <MDBCardBody>
                    <MDBContainer>
                      <MDBCardBody>
                        <OrderReportPage />
                      </MDBCardBody>
                    </MDBContainer>
                  </MDBCardBody>
                </MDBCollapse>
              )}
            </MDBCard>
            <MDBCard
              className='mt-3'
              style={{ backgroundColor: 'rgba(0,0,0,.03)' }}
            >
              <MDBCollapseHeader
                tagClassName='d-flex justify-content-between'
                onClick={() => {
                  console.log('clickied')
                  toggleCollapse('collapse4')
                }}
              >
                Batch Reports
                <MDBIcon
                  icon={collapseId === 'collapse4' ? 'angle-up' : 'angle-down'}
                />
              </MDBCollapseHeader>
              {collapseId === 'collapse4' && (
                <MDBCollapse id='collapse4' isOpen={collapseId}>
                  <MDBCardBody>
                    <MDBContainer>
                      <MDBCardBody>
                        <BatchReportPage />
                      </MDBCardBody>
                    </MDBContainer>
                  </MDBCardBody>
                </MDBCollapse>
              )}
            </MDBCard>
          </MDBContainer>
        </MDBContainer>
      ) : (
        <div />
      )}
    </div>
  )
}

export default ReportsPage
