// Base code taken from https://medium.com/tinyso/how-to-detect-inactive-user-to-auto-logout-by-using-idle-timeout-in-javascript-react-angular-and-b6279663acf2

class IdleTimer {
  constructor ({ timeout, onTimeout, onExpired }) {
    this.timeout = timeout
    this.onTimeout = onTimeout

    const expiredTime = parseInt(window.localStorage.getItem('_expiredTime'), 10)
    if (expiredTime > 0 && expiredTime < Date.now()) {
      this.cleanUp()
      onExpired()
      return
    }

    this.eventHandler = this.updateExpiredTime.bind(this)
    this.tracker()
    this.startInterval()
  }

  startInterval () {
    this.updateExpiredTime()
    this.interval = setInterval(() => {
      const expiredTime = parseInt(window.localStorage.getItem('_expiredTime'), 10)
      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout()
          this.cleanUp()
        }
      }
    }, 1000)
  }

  updateExpiredTime () {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker)
    }
    this.timeoutTracker = setTimeout(() => {
      window.localStorage.setItem('_expiredTime', Date.now() + this.timeout * 1000)
    }, 10)
  }

  tracker () {
    window.addEventListener('mousemove', this.eventHandler)
    window.addEventListener('scroll', this.eventHandler)
    window.addEventListener('keydown', this.eventHandler)
    window.addEventListener('click', this.eventHandler)
  }

  cleanUp () {
    window.localStorage.removeItem('_expiredTime')
    clearInterval(this.interval)
    window.removeEventListener('mousemove', this.eventHandler)
    window.removeEventListener('scroll', this.eventHandler)
    window.removeEventListener('keydown', this.eventHandler)
    window.removeEventListener('click', this.eventHandler)
  }
}

export default IdleTimer
