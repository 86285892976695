import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBCollapse,
  MDBCollapseHeader,
  MDBContainer,
  MDBIcon
} from 'mdbreact'
import React, { useEffect, useState } from 'react'
import 'date-fns'
import BundleCreate from './BundleCreate'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import BundleView from './BundleView'
import { Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'
import Paper from '@material-ui/core/Paper/Paper'
import { useSelector } from 'react-redux'

const BundleItemPage = props => {
  const reduxState = useSelector(state => state)
  const [collapseId, setCollapseId] = useState(1)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  useEffect(() => {
    // Add Google Analytics
    if (
      reduxState.environmentVariables &&
      reduxState.environmentVariables.public &&
      reduxState.environmentVariables.public.analyticsTrackingId
    ) {
      ReactGA.initialize(
        reduxState.environmentVariables.public.analyticsTrackingId,
        { debug: false }
      )
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  })

  // Must include this code block to redirect user to the login page if user is not logged in.
  // Otherwise if the session has timed out and the user access this page it will appear as loading indefinitely.
  // This code block must be placed immediately after the userEffect() blocks.
  if (!props.isLoggedIn && !props.isLoading) {
    return (
      <Redirect
        to={{ pathname: '/login', state: { referrer: '/bundle-items' } }}
      />
    )
  }

  return (
    <MDBContainer>
      <MDBBreadcrumb>
        <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
        <MDBBreadcrumbItem>Items</MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>Item Bundles</MDBBreadcrumbItem>
      </MDBBreadcrumb>
      <MDBContainer className='md-accordion mt-5'>
        <MDBCard
          className='mt-3'
          style={{ backgroundColor: 'rgba(0,0,0,.03)' }}
        >
          <MDBCollapseHeader
            tagClassName='d-flex justify-content-between'
            onClick={() =>
              collapseId === 0 ? setCollapseId(-1) : setCollapseId(0)
            }
          >
            Create Bundle
            <MDBIcon icon={collapseId === 0 ? 'angle-up' : 'angle-down'} />
          </MDBCollapseHeader>
          <MDBCollapse isOpen={collapseId === 0}>
            <BundleCreate
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarOpen={setSnackbarOpen}
            />
          </MDBCollapse>
        </MDBCard>
        <MDBCard
          className='mt-3'
          style={{ backgroundColor: 'rgba(0,0,0,.03)' }}
        >
          <MDBCollapseHeader
            tagClassName='d-flex justify-content-between'
            onClick={() =>
              collapseId === 1 ? setCollapseId(-1) : setCollapseId(1)
            }
          >
            View Bundles
            <MDBIcon icon={collapseId === 1 ? 'angle-up' : 'angle-down'} />
          </MDBCollapseHeader>
          <MDBCollapse isOpen={collapseId === 1}>
            <MDBCardBody>
              <MDBContainer>
                <BundleView />
              </MDBContainer>
            </MDBCardBody>
          </MDBCollapse>
        </MDBCard>
      </MDBContainer>
      <Snackbar
        message={snackbarMessage}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
      />
    </MDBContainer>
  )
}

export default BundleItemPage
