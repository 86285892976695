import Autocomplete from '@material-ui/lab/Autocomplete'
import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer } from 'mdbreact'
import Paper from '@material-ui/core/Paper/Paper'
import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import { Redirect } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Select from '@material-ui/core/Select/Select'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import TextField from '@material-ui/core/TextField/TextField'
import Divider from '@material-ui/core/Divider/Divider'
import Typography from '@material-ui/core/Typography/Typography'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import FormGroup from '@material-ui/core/FormGroup/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import Button from '@material-ui/core/Button/Button'
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'

const CreateReturnsSection = props => {
  const { getRows } = props
  const reduxState = useSelector(state => state)
  const [orderList, setOrderList] = useState([])
  const [orderNameList, setOrderNameList] = useState([])
  const [order, setOrder] = useState(undefined)
  const [reason, setReason] = useState('')
  const [actionTaken, setActionTaken] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [resolved, setResolved] = useState(false)
  const [rts, setRts] = useState(false)
  const [clientId, setClientId] = useState('none')
  const [client, setClient] = useState('none')
  const [clientList, setClientList] = useState([])
  const [store, setStore] = useState('none')
  const [storeId, setStoreId] = useState('none')
  const [storeList, setStoreList] = useState([])
  const [receivedDate, setReceivedDate] = useState(new Date())
  const [customOrder, setCustomOrder] = useState()
  const [isCustom, setIsCustom] = useState(false)

  useEffect(() => {
    // Add Google Analytics
    if (
      reduxState.environmentVariables &&
      reduxState.environmentVariables.public &&
      reduxState.environmentVariables.public.analyticsTrackingId
    ) {
      ReactGA.initialize(
        reduxState.environmentVariables.public.analyticsTrackingId,
        { debug: false }
      )
      ReactGA.pageview(window.location.pathname + window.location.search)
    }

    window
      .fetch('/api/client/getClients')
      .then(res => res.json())
      .then(data => {
        setClientList(data.clients)
      })
  }, [])

  useEffect(() => {
    window
      .fetch('/api/order/returnEligible/' + storeId)
      .then(res => res.json())
      .then(data => {
        setOrderList(data)
        const orderNames = []
        data.map((a, i) => {
          if (a.orderName !== undefined) {
            orderNames.push(a.orderName)
          }
        })
        setOrderNameList(orderNames)
      })
  }, [store])

  function changeClient (value) {
    if (value !== 'none') {
      setClient(value)
      setClientId(value._id)
      setStoreList(value.stores)
      setStore('none')
      setStoreId('none')
      setOrder(undefined)
    } else {
      setClientId('none')
      setClient('none')
    }
  }

  function changeStore (value) {
    if (value !== 'none') {
      setStore(value)
      setStoreId(value._id)
      setOrder(undefined)
    } else {
      setStoreId('none')
      setStore('none')
    }
  }

  function selectOrder (value) {
    setOrder(orderList.find(a => a.orderName === value))
  }

  function saveReturnInfo () {
    axios
      .post(
        '/api/returns/createReturn',
        {
          body: JSON.stringify({
            orderId: order._id,
            reason
            // actionTaken,
            // resolved,
            // rts,
            // receivedDate
          })
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(res => {
        if (res.data.success) {
          setSnackbarMessage(res.data.message)
          setSnackbarOpen(true)
          getRows()
        } else {
          setSnackbarMessage(res.data.message)
          setSnackbarOpen(true)
        }
      })
  }

  const saveCustomReturnInfo = () => {
    axios
      .post(
        '/api/returns/createCustomReturn',
        {
          body: JSON.stringify({
            orderId: customOrder,
            clientId: clientId,
            storeId: storeId,
            reason
          })
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(res => {
        if (res.data.success) {
          setSnackbarMessage(res.data.message)
          setSnackbarOpen(true)
          getRows()
        } else {
          setSnackbarMessage(res.data.message)
          setSnackbarOpen(true)
        }
      })
  }

  return (
    <div>
      <MDBContainer>
        <Paper style={{ padding: 25 }} elevation={5}>
          <Grid
            container
            justify='space-evenly'
            alignItems='center'
            alignContent='center'
          >
            <Grid item>
              <InputLabel id='client-label' shrink>
                Client
              </InputLabel>
              <Select
                labelId='client-label'
                value={client || ''}
                onChange={e => changeClient(e.target.value)}
              >
                <MenuItem value='none' disabled>
                  Select
                </MenuItem>
                {clientList
                  .sort((a, b) => a.name > b.name)
                  .map((row, i) => {
                    return (
                      <MenuItem key={i} value={row}>
                        {row.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </Grid>

            <Grid item>
              <InputLabel id='store-label' shrink>
                Store
              </InputLabel>
              <Select
                labelId='store-label'
                value={store}
                onChange={e => changeStore(e.target.value)}
                disabled={clientId === 'none'}
              >
                <MenuItem value='none' disabled>
                  Select
                </MenuItem>
                {storeList.map((row, i) => {
                  return (
                    <MenuItem key={row.name} value={row}>
                      {row.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item>
              <FormControlLabel
                disabled={clientId === 'none' || storeId === 'none'}
                style={{ paddingTop: 25 }}
                control={
                  <Checkbox
                    name='checkedA'
                    onChange={() => {
                      setOrder()
                      setIsCustom(!isCustom)
                    }}
                  />
                }
                label='Custom Order'
              />
            </Grid>
            <Grid item>
              {!isCustom ? (
                <Autocomplete
                  options={orderNameList}
                  // getOptionLabel={(option) => option.title}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label='Order No'
                      variant='outlined'
                    />
                  )}
                  onChange={(e, value) => selectOrder(value)}
                  style={{ minWidth: 300 }}
                />
              ) : (
                <TextField
                  label='Order No'
                  variant='outlined'
                  onChange={e => setCustomOrder(e.target.value)}
                />
              )}
            </Grid>
          </Grid>
          <Divider style={{ margin: '25px 0' }} />
          {order !== undefined ? (
            <>
              <Grid
                container
                justify='space-evenly'
                alignItems='center'
                alignContent='center'
              >
                <Grid item>
                  <Typography variant='body2'>Order Name</Typography>
                  <Typography variant='body1'>
                    <b>{order.orderName}</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    label='Reason'
                    variant='outlined'
                    value={reason}
                    onChange={e => setReason(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justify='space-evenly'
                alignItems='center'
                alignContent='center'
              >
                <Grid item>
                  <Button
                    variant='contained'
                    size='large'
                    disabled={reason === ''}
                    color='primary'
                    style={{ marginTop: 20 }}
                    onClick={() => saveReturnInfo()}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : customOrder ? (
            <>
              <Grid
                container
                justify='space-evenly'
                alignItems='center'
                alignContent='center'
              >
                <Grid item>
                  <Typography variant='body2'>Custom Order Name</Typography>
                  <Typography variant='body1'>
                    <b>{customOrder}</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    label='Reason'
                    variant='outlined'
                    value={reason}
                    onChange={e => setReason(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justify='space-evenly'
                alignItems='center'
                alignContent='center'
              >
                <Grid item>
                  <Button
                    variant='contained'
                    size='large'
                    disabled={reason === ''}
                    color='primary'
                    style={{ marginTop: 20 }}
                    onClick={() => saveCustomReturnInfo()}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Snackbar
            message={snackbarMessage}
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
          />
        </Paper>
      </MDBContainer>
    </div>
  )
}

export default CreateReturnsSection
