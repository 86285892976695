import React, { Component } from 'react'
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCollapse,
  MDBCollapseHeader,
  MDBContainer,
  MDBIcon,
  MDBInputGroup,
  MDBRow
} from 'mdbreact'

import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import 'date-fns'
import axios from 'axios'
import '../../index.css'
import Grid from '@material-ui/core/Grid/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField/TextField'
import { CSVLink } from 'react-csv'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import { setEnvironmentVariables } from '../../redux/actions'

class createShipmentsPage extends Component {
  constructor (props) {
    super(props)
    // Don't call this.setState() here!
    this.state = {
      fileName: '',
      collapseID: 'collapse1',
      fileData: '',
      sku: '',
      quantity: 0,
      eta: new Date(),
      client: '',
      title: '',
      asn: '',
      detailRows: {},
      detailInputs: {},
      skuRows: [],
      clientList: [],
      clientInv: [],
      clientInvSku: [],
      snackbarOpen: false,
      disableButton: false,
      authQueryReceived: false,
      isLoggedIn: false
    }

    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.handleUploadClick = this.handleUploadClick.bind(this)
    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.handleAsnChange = this.handleAsnChange.bind(this)
    this.handleQuantityChange = this.handleQuantityChange.bind(this)
    this.handleEtaChange = this.handleEtaChange.bind(this)
    this.handleStoreChange = this.handleStoreChange.bind(this)
    this.handleSubmitClick = this.handleSubmitClick.bind(this)
    this.handleAddMore = this.handleAddMore.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.closeSnackbar = this.closeSnackbar.bind(this)
    this.handleClientChange = this.handleClientChange.bind(this)
    this.handleSKUChange = this.handleSKUChange.bind(this)
  }

  componentDidMount () {
    window.fetch('/api/env/')
      .then(res => res.json())
      .then(data => {
        this.props.setEnvironmentVariables(data)

        // Add Google Analytics
        ReactGA.initialize(data.public.analyticsTrackingId, { debug: false })
        ReactGA.pageview(window.location.pathname + window.location.search)
      })

    window.fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        this.setState({ authQueryReceived: true, isLoggedIn: data.success })
        if (data.success) {
          axios.get('/api/client/getClients', this.state.fileData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
            .then(res => {
              this.setState({ clientList: res.data.clients })
              axios.get('/api/item/getClientInv/' + res.data.clients[0]._id)
                .then(res => {
                  const clientInvSku = []
                  res.data.inventory.forEach(a => {
                    clientInvSku.push(a.sku)
                  })

                  // IMPLEMENT THIS SETSTATE SKUROWS PROPERLY AS A FUNCTION, BRAINFRIED ATM... CTRL+F SKUROWS
                  this.setState({
                    clientInv: res.data.inventory,
                    clientInvSku: clientInvSku,
                    skuRows: []
                  })
                })
            })
        }
      })
  }

  handleFileUpload (e) {
    const data = new FormData()
    const file = e.target.files[0]

    data.append('file', e.target.files[0])
    if (file) {
      this.setState({ fileName: e.target.files[0].name, fileData: data })
    }
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }))
  }

  handleUploadClick (e) {
    // TODO send file
    if (this.state.fileData !== '') {
      axios.post('/api/shipment/createShipmentFromCSV', this.state.fileData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          if (res.data.success){
            this.setState({
              snackbarOpen: true,
              snackbarMessage: 'CSV file accepted, please double check if there were any issues or mistakes'
            })
          }else{
            this.setState({
              snackbarOpen: true,
              snackbarMessage: res.data.errorMessage
            })
          }
        })
    } else {
      this.setState({ snackbarOpen: true, snackbarMessage: 'Please select a csv file' })
    }
  }

  handleTitleChange (e) {
    this.setState({ title: e })
  }

  handleAsnChange (e) {
    this.setState({ asn: e })
  }

  handleQuantityChange (e) {
    const index = e.target.id.split('_')[1]
    const newArr = this.state.skuRows
    newArr[index].incomingQty = parseInt(e.target.value)
    this.setState({ skuRows: newArr })
  }

  handleEtaChange (date) {
    this.setState({ eta: date })
  }

  handleStoreChange (e) {
    this.setState({ store: e.target.value })
  }

  handleSubmitClick (e) {
    let hasZeroQty = false
    this.state.skuRows.map(row => {
      if (row.incomingQty === 0) {
        hasZeroQty = true
      }
    })
    if (this.state.skuRows.length === 0) {
      hasZeroQty = true
    }
    if (this.state.title === '') {
      this.setState({ snackbarOpen: true, snackbarMessage: 'Please create a title for shipment' })
    } else if (this.state.asn === '') {
      this.setState({ snackbarOpen: true, snackbarMessage: 'Please create an ASN for shipment' })
    } else if (hasZeroQty) {
      this.setState({ snackbarOpen: true, snackbarMessage: 'There is a row with 0 quantity' })
    } else {
      axios.post('/api/shipment/createShipment', {
        body: JSON.stringify({
          clientId: this.state.client,
          title: this.state.title,
          asn: this.state.asn,
          eta: this.state.eta,
          contents: this.state.skuRows
        })
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => { })
        .then(this.setState({
          snackbarOpen: true,
          snackbarMessage: 'Created new shipment',
          disableButton: true,
          title: '',
          asn: '',
          skuRows: [{ sku: '', incomingQty: 0, itemId: '' }]
        }))
    }
  }

  handleAddMore (e) {
    const newRow = {
      sku: this.state.clientInv[0].sku,
      incomingQty: 0,
      itemId: this.state.clientInv[0]._id
    }

    this.setState({ skuRows: this.state.skuRows.concat(newRow) })
  }

  handleRemove (e) {
    const newArr = this.state.skuRows
    newArr.pop()
    this.setState({ skuRows: newArr })
  }

  closeSnackbar (e) {
    this.setState({ snackbarOpen: false, disableButton: false })
  }

  handleClientChange (e) {
    const json = JSON.parse(e.target.value)
    const id = json.id
    const name = json.name
    this.setState({ client: id, clientName: name })
    axios.get('/api/item/getClientInv/' + id)
      .then(res => {
        // IMPLEMENT THIS SETSTATE PROPERLY AS A FUNCTION, BRAINFRIED ATM... CTRL+F SKUROWS
        const clientInvSku = []

        res.data.inventory.forEach(a => {
          clientInvSku.push(a.sku)
        })

        this.setState({
          clientInv: res.data.inventory,
          clientInvSku: clientInvSku,
          skuRows: []
        }, function () {
          if (clientInvSku.length > 0) {
            this.handleAddMore()
          }
        })
      })
  }

  handleSKUChange (a, e, value) {
    const result = this.state.clientInv.find(clientInv => clientInv.sku === value)
    if (result) {
      const index = e.id.split('-')[0]
      const newArr = this.state.skuRows
      newArr[index].itemId = result._id
      newArr[index].sku = value
    }
  }

  render () {
    const { collapseID } = this.state

    const csvData = [
      ['shipmentTitle', 'asn', 'clientName', 'eta', 'sku', 'incomingQty'],
      ['Sample title', 'ASN', 'F45/White Glo/Vsculptor', '2020-06-26', 'SKU123', 200]
    ]

    if (this.state.authQueryReceived && !this.state.isLoggedIn) {
      this.props.setIsLoggedIn(false)
      this.props.setAuthQueryReceived(false)
      return <Redirect to={{ pathname: '/login', state: { referrer: '/create-shipment' } }} />
    }

    return (
      <MDBContainer>
        {this.state.authQueryReceived ? <div>
          <MDBBreadcrumb>
            <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
            <MDBBreadcrumbItem>Shipments</MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>Create Shipment</MDBBreadcrumbItem>
          </MDBBreadcrumb>
          <MDBContainer className='md-accordion mt-5'>
            <MDBCard className='mt-3' style={{ backgroundColor: 'rgba(0,0,0,.03)' }}>
              <MDBCollapseHeader
                tagClassName='d-flex justify-content-between'
                onClick={this.toggleCollapse('collapse1')}
              >
                Upload a file
                <MDBIcon icon={collapseID === 'collapse1' ? 'angle-up' : 'angle-down'} />
              </MDBCollapseHeader>
              <MDBCollapse id='collapse1' isOpen={collapseID}>
                <MDBCardBody>
                  <MDBRow center>
                    <CSVLink data={csvData} filename='createShipment.csv'>Download template</CSVLink>
                  </MDBRow>
                  <br />
                  <div className='input-group'>
                    <div className='input-group-prepend'>
                      <span className='input-group-text' id='inputGroupFileAddon01'>
                        Upload
                      </span>
                    </div>
                    <div className='custom-file'>
                      <input
                        type='file'
                        className='custom-file-input'
                        id='inputGroupFile01'
                        aria-describedby='inputGroupFileAddon01'
                        onChange={this.handleFileUpload}
                      />
                      <label className='custom-file-label' htmlFor='inputGroupFile01'>
                        {this.state.fileName !== '' ? this.state.fileName : 'Choose file'}
                      </label>
                    </div>
                  </div>
                  <br />
                  <MDBRow center>
                    <MDBBtn
                      rounded onClick={this.handleUploadClick}
                      color='primary'
                    ><b>Submit</b>
                    </MDBBtn>
                  </MDBRow>
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>
            <MDBCard className='mt-3' style={{ backgroundColor: 'rgba(0,0,0,.03)' }}>
              <MDBCollapseHeader
                tagClassName='d-flex justify-content-between'
                onClick={this.toggleCollapse('collapse2')}
              >
                Add manually
                <MDBIcon icon={collapseID === 'collapse2' ? 'angle-up' : 'angle-down'} />
              </MDBCollapseHeader>
              <MDBCollapse id='collapse2' isOpen={collapseID}>
                <MDBCardBody>
                  <MDBContainer>
                    <br />
                    <div>
                      <label htmlFor='select-client'>Select Client</label>
                      <select id='select-client' className='browser-default custom-select' defaultValue='disabled' onChange={this.handleClientChange} value={this.value}>
                        <option value='disabled' disabled>Select Client</option>
                        {this.state.clientList.sort((a, b) => a.name > b.name).map((client, i) => {
                          if (client === this.state.clientList[0] && this.state.client === '') {
                            this.setState({ client: client._id, clientName: client.name })
                          }
                          return (
                            <option
                              key={i} value={JSON.stringify({ id: client._id, name: client.name })}
                            >{client.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <br />

                    <MDBInputGroup
                      id='shipment-title' containerClassName='mb-3' hint='Title'
                      label='Title' value={this.state.title}
                      onChange={(e) => this.handleTitleChange(e.target.value)}
                    />

                    <MDBInputGroup
                      id='shipment-asn' containerClassName='mb-3' hint='ASN'
                      label='ASN' value={this.state.asn}
                      onChange={(e) => this.handleAsnChange(e.target.value)}
                    />

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        format='yyyy-MM-dd'
                        margin='normal'
                        id='date-picker-inline'
                        label='ETA'
                        onChange={this.handleEtaChange}
                        value={this.state.eta}
                        KeyboardButtonProps={{
                          'aria-label': 'change date'
                        }}
                        disableToolbar
                        variant='inline'
                      />
                    </MuiPickersUtilsProvider>
                    {/* <MDBInputGroup prepend="Arrival Date" inputs={
                                        <MDBDatePickerV5 theme="warning" format="YYYY-MM-DD" getValue={(e) => this.setState({ eta: e })} onChange={this.handleEtaChange} />
                                    } /> */}
                    <br />
                    {/* <MDBInputGroup containerClassName="mb-3" hint="SKU" onChange={this.handleSKUChange} />
                                    <MDBInputGroup containerClassName="mb-3" hint="Expected Quantity" onChange={this.handleQuantityChange} /> */}
                    {this.state.skuRows.length > 0 && this.state.skuRows.map((item, index) => {
                      return (
                        <div key={index}>
                          <hr />
                          <Grid container spacing={4}>
                            <Grid item xs={6}>
                              {/* <MDBInputGroup id={"SKU_" + index} containerClassName="mb-3" */}
                              {/* hint="SKU10000" label="SKU" */}
                              {/* onChange={this.handleSKUChange}/> */}
                              {/* <label htmlFor="select-client">SKU</label> */}
                              {/* <select id={"SKU_" + index} */}
                              {/* className="browser-default custom-select"> */}
                              {/* <option disabled>Select SKU</option> */}
                              {/* {this.state.clientInv.length > 0 && this.state.clientInv.sort((a, b) => a.sku < b.sku).map((inv, i) => { */}
                              {/* return ( */}
                              {/* <option id={i + "_" + inv.sku + "_" + index} key={i} */}
                              {/* onClick={this.handleSKUChange} */}
                              {/* value={inv._id}>{inv.sku + " | " + inv.title}</option> */}
                              {/* ) */}
                              {/* })} */}
                              {/* </select> */}
                              <Autocomplete
                                id={index.toString()}
                                options={this.state.clientInvSku}
                                // getOptionLabel={(option) => option.title}
                                renderInput={(params) => <TextField
                                  {...params} label='SKU'
                                  variant='outlined'
                                  fullWidth
                                                         />}
                                onChange={(e, value) => this.handleSKUChange(this, e.target, value)}

                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                id={'Qty_' + index} fullWidth
                                placeholder='0' label='Expected Quantity'
                                variant='outlined'
                                onChange={this.handleQuantityChange}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      )
                    })}
                    <MDBRow center style={{ marginTop: 25 }}>
                      <MDBBtn rounded onClick={this.handleAddMore} color='success'><b>Add
                      SKU
                                                                                   </b>
                      </MDBBtn>
                      <MDBBtn
                        rounded onClick={this.handleRemove}
                        color='danger'
                      ><b>Remove</b>
                      </MDBBtn>
                    </MDBRow>
                    <br />
                    <MDBRow center>
                      <MDBBtn
                        rounded onClick={this.handleSubmitClick}
                        color='primary'
                        disabled={this.state.disableButton}
                      ><b>Submit</b>
                      </MDBBtn>
                    </MDBRow>
                  </MDBContainer>
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>
          </MDBContainer>
          <Snackbar
            open={this.state.snackbarOpen} autoHideDuration={6000} message={this.state.snackbarMessage}
            onClose={this.closeSnackbar}
          />
                                        </div> : <div />}
      </MDBContainer>
    )
  }
}

// Access to Redux store props
const mapStateToProps = (state, ownProps) => {
  // redux state fields are mapped to the props fields here.
  // This class component can access the redux state field via this.props.[fieldname]
  return {
    environmentVariables: state.environmentVariables
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setEnvironmentVariables: (data) => {
      dispatch(setEnvironmentVariables(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(createShipmentsPage)
