import EditIcon from '@material-ui/icons/Edit'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import DialogTitle from '@material-ui/core/DialogTitle'
import TableFooter from '@material-ui/core/TableFooter/TableFooter'
import Typography from '@material-ui/core/Typography/Typography'
import OrderContentsRow from './OrderContentsRow'
import ShopifySpecificStat from './ShopifySpecificStat'
import EbaySpecificStat from './EbaySpecificStat'
import React, { useEffect, useState } from 'react'
import { DialogContentText, IconButton } from '@material-ui/core'
import Autocomplete from 'react-google-autocomplete'
import './styles.css'

const OrderContentsTable = props => {
  const {
    dbOrder,
    order,
    setSnackbarOpen,
    setSnackbarMessage,
    refunded,
    pickedArray,
    platform
  } = props
  const [openShippingAddressModal, setOpenShippingAddressModal] = useState(
    false
  )
  const [
    openDirtyShippingAddressModal,
    setOpenDirtyShippingAddressModal
  ] = useState(false)
  const [effectiveShippingAddress, setEffectiveShippingAddress] = useState({})
  const [newShippingAddress, setNewShippingAddress] = useState({})
  const [addressTypographyColor, setAddressTypographyColor] = React.useState(
    'initial'
  )
  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])
  const [autoChanged, setAutoChanged] = React.useState(false)

  useEffect(() => {
    if (platform === 'Shopify' && dbOrder.odeoShippingAddress) {
      const shippingAddress = {
        platform,
        name: dbOrder.odeoShippingAddress.name
          ? dbOrder.odeoShippingAddress.name
          : dbOrder.odeoShippingAddress.first_name +
            ' ' +
            dbOrder.odeoShippingAddress.last_name,
        first_name: dbOrder.odeoShippingAddress.first_name,
        last_name: dbOrder.odeoShippingAddress.last_name,
        company: dbOrder.odeoShippingAddress.company,
        address1: dbOrder.odeoShippingAddress.address1,
        address2: dbOrder.odeoShippingAddress.address2,
        city: dbOrder.odeoShippingAddress.city,
        province_code: dbOrder.odeoShippingAddress.province_code,
        zip: dbOrder.odeoShippingAddress.zip,
        country: dbOrder.odeoShippingAddress.country,
        country_code: dbOrder.shippingAddress.country_code,
        phone: dbOrder.odeoShippingAddress.phone,
        email: dbOrder.odeoShippingAddress.email
      }
      setEffectiveShippingAddress(shippingAddress)
      setAddressTypographyColor('initial')
      setNewShippingAddress({ ...shippingAddress, isDirty: false })
    } else if (platform === 'Shopify' && dbOrder.shippingAddress) {
      const shippingAddress = {
        platform,
        name: dbOrder.shippingAddress.name
          ? dbOrder.shippingAddress.name
          : dbOrder.shippingAddress.first_name +
            ' ' +
            dbOrder.shippingAddress.last_name,
        first_name: dbOrder.shippingAddress.first_name,
        last_name: dbOrder.shippingAddress.last_name,
        company: dbOrder.shippingAddress.company,
        address1: dbOrder.shippingAddress.address1,
        address2: dbOrder.shippingAddress.address2,
        city: dbOrder.shippingAddress.city,
        province_code: dbOrder.shippingAddress.province_code,
        zip: dbOrder.shippingAddress.zip,
        country: dbOrder.shippingAddress.country,
        country_code: dbOrder.shippingAddress.country_code,
        phone: dbOrder.shippingAddress.phone,
        email: dbOrder.email,
        addressIsCorrect: dbOrder.addressVerification
          ? dbOrder.addressVerification.addressIsCorrect
          : true
      }
      setEffectiveShippingAddress(shippingAddress)
      if (
        !dbOrder.addressVerification ||
        dbOrder.addressVerification.addressIsCorrect
      ) {
        setAddressTypographyColor('initial')
      } else {
        setAddressTypographyColor('error')
      }
      setNewShippingAddress({ ...shippingAddress, isDirty: false })
    } else if (platform === 'Inhouse') {
      const shippingAddress = {
        platform,
        name:
          dbOrder.shippingAddress.firstName +
          ' ' +
          dbOrder.shippingAddress.lastName,
        firstName: dbOrder.shippingAddress.firstName,
        lastName: dbOrder.shippingAddress.lastName,
        company: dbOrder.shippingAddress.company,
        address1: dbOrder.shippingAddress.address1,
        address2: dbOrder.shippingAddress.address2,
        suburb: dbOrder.shippingAddress.suburb,
        state: dbOrder.shippingAddress.state,
        postcode: dbOrder.shippingAddress.postcode,
        country_code: dbOrder.shippingAddress.country_code,
        country: dbOrder.shippingAddress.country,
        phone: dbOrder.shippingAddress.phone,
        email: dbOrder.email ? dbOrder.email : ''
      }
      setEffectiveShippingAddress(shippingAddress)
      setAddressTypographyColor('initial')
      setNewShippingAddress({ ...shippingAddress, isDirty: false })
    } else if (platform === 'eBay' && dbOrder.odeoShippingAddress) {
      const shippingAddress = {
        platform,
        Name: dbOrder.odeoShippingAddress.Name,
        Street1: dbOrder.odeoShippingAddress.Street1,
        Street2: dbOrder.odeoShippingAddress.Street2,
        CityName: dbOrder.odeoShippingAddress.CityName,
        StateOrProvince: dbOrder.odeoShippingAddress.StateOrProvince,
        PostalCode: dbOrder.odeoShippingAddress.PostalCode,
        CountryName: dbOrder.odeoShippingAddress.CountryName,
        Country: dbOrder.odeoShippingAddress.Country,
        Phone: dbOrder.odeoShippingAddress.Phone,
        email: dbOrder.odeoShippingAddress.email
      }
      setEffectiveShippingAddress(shippingAddress)
      setAddressTypographyColor('initial')
      setNewShippingAddress({ ...shippingAddress, isDirty: false })
    } else if (platform === 'eBay' && dbOrder.ShippingAddress) {
      const shippingAddress = {
        platform,
        Name: dbOrder.ShippingAddress.Name,
        Street1: dbOrder.ShippingAddress.Street1,
        Street2: dbOrder.ShippingAddress.Street2,
        CityName: dbOrder.ShippingAddress.CityName,
        StateOrProvince: dbOrder.ShippingAddress.StateOrProvince,
        PostalCode: dbOrder.ShippingAddress.PostalCode,
        CountryName: dbOrder.ShippingAddress.CountryName,
        Country: dbOrder.ShippingAddress.Country,
        Phone: dbOrder.ShippingAddress.Phone,
        email: Array.isArray(dbOrder.TransactionArray.Transaction)
          ? dbOrder.TransactionArray.Transaction[0].Buyer.StaticAlias
          : dbOrder.TransactionArray.Transaction.Buyer.StaticAlias
      }
      setEffectiveShippingAddress(shippingAddress)
      setAddressTypographyColor('initial')
      setNewShippingAddress({ ...shippingAddress, isDirty: false })
    }
  }, [dbOrder, platform])

  const handleAddressFieldChange = e => {
    switch (e.target.name) {
      case 'firstName':
        if (e.target.value.trim() !== newShippingAddress.firstName) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            firstName: e.target.value
          })
        }
        break
      case 'first_name':
        if (e.target.value !== newShippingAddress.first_name) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            first_name: e.target.value,
            name: e.target.value + ' ' + newShippingAddress.last_name
          })
        }
        break
      case 'lastName':
        if (e.target.value !== newShippingAddress.lastName) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            lastName: e.target.value
          })
        }
        break
      case 'last_name':
        if (e.target.value !== newShippingAddress.last_name) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            last_name: e.target.value,
            name: newShippingAddress.first_name + ' ' + e.target.value
          })
        }
        break
      case 'company':
        if (e.target.value !== newShippingAddress.company) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            company: e.target.value
          })
        }
        break
      case 'address1':
        if (e.target.value !== newShippingAddress.address1) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            address1: e.target.value
          })
        }
        break
      case 'street1':
        if (e.target.value !== newShippingAddress.street1) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            street1: e.target.value
          })
        }
        break
      case 'address2':
        if (e.target.value !== newShippingAddress.address2) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            address2: e.target.value
          })
        }
        break
      case 'street2':
        if (e.target.value !== newShippingAddress.street2) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            street2: e.target.value
          })
        }
        break
      case 'suburb':
        if (e.target.value !== newShippingAddress.suburb) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            suburb: e.target.value
          })
        }
        break
      case 'city':
        if (e.target.value !== newShippingAddress.city) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            city: e.target.value
          })
        }
        break
      case 'state':
        if (e.target.value !== newShippingAddress.state) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            state: e.target.value
          })
        }
        break
      case 'province_code':
        if (e.target.value !== newShippingAddress.province_code) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            province_code: e.target.value
          })
        }
        break
      case 'postcode':
        if (e.target.value !== newShippingAddress.postcode) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            postcode: e.target.value
          })
        }
        break
      case 'zip':
        if (e.target.value !== newShippingAddress.zip) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            zip: e.target.value
          })
        }
        break
      case 'country_code':
        if (e.target.value !== newShippingAddress.country_code) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            country_code: e.target.value
          })
        }
        break
      case 'country':
        if (e.target.value !== newShippingAddress.country) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            country: e.target.value
          })
        }
        break
      case 'phone':
        if (e.target.value !== newShippingAddress.phone) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            phone: e.target.value
          })
        }
        break
      case 'email':
        if (e.target.value !== newShippingAddress.email) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            email: e.target.value
          })
        }
        break
      case 'Name':
        if (e.target.value !== newShippingAddress.Name) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            Name: e.target.value
          })
        }
        break
      case 'Street1':
        if (e.target.value !== newShippingAddress.Street1) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            Street1: e.target.value
          })
        }
        break
      case 'Street2':
        if (e.target.value !== newShippingAddress.Street2) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            Street2: e.target.value
          })
        }
        break
      case 'CityName':
        if (e.target.value !== newShippingAddress.CityName) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            CityName: e.target.value
          })
        }
        break
      case 'StateOrProvince':
        if (e.target.value !== newShippingAddress.StateOrProvince) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            StateOrProvince: e.target.value
          })
        }
        break
      case 'PostalCode':
        if (e.target.value !== newShippingAddress.PostalCode) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            PostalCode: e.target.value
          })
        }
        break
      case 'CountryName':
        if (e.target.value !== newShippingAddress.CountryName) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            CountryName: e.target.value
          })
        }
        break
      case 'Phone':
        if (e.target.value !== newShippingAddress.Phone) {
          setNewShippingAddress({
            ...newShippingAddress,
            isDirty: true,
            Phone: e.target.value
          })
        }
        break
      default:
    }
  }

  const handleShippingAddressSave = () => {
    const address = { ...newShippingAddress }
    delete address.isDirty
    delete address.platform

    window
      .fetch('/api/order/updateOrderShippingAddress', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          orderId: dbOrder._id,
          clientId: dbOrder.clientId,
          newShippingAddress: { ...address }
        })
      })
      .then(res => res.json())
      .then(result => {
        if (result.success) {
          setEffectiveShippingAddress({ ...address, platform })
          setNewShippingAddress({ ...address, isDirty: false, platform })
          setAddressTypographyColor('initial')
          setOpenShippingAddressModal(!openShippingAddressModal)
        }
      })
    setAutoChanged(false)
  }

  const handleShippingAddressCancel = () => {
    if (newShippingAddress.isDirty) {
      setOpenDirtyShippingAddressModal(true)
    } else {
      setOpenShippingAddressModal(false)
      setAutoChanged(false)
    }
  }

  const handleDirtyShippingAddressModalClose = forceClose => {
    setOpenDirtyShippingAddressModal(false)
    if (forceClose) {
      // ignore changes, reload the original address
      setNewShippingAddress({ ...effectiveShippingAddress, isDirty: false })
      setOpenShippingAddressModal(false)
      setAutoChanged(false)
    }
  }

  const handleClose = () => {}

  const handleEditShippingAddress = () => {
    setOpenShippingAddressModal(true)
  }

  const composeShippingAddressDom = () => {
    if (effectiveShippingAddress.platform === 'Shopify') {
      return (
        <Typography variant='body1' color={addressTypographyColor}>
          {effectiveShippingAddress.name}
          <br />
          {effectiveShippingAddress.company &&
            effectiveShippingAddress.company.trim().length > 0 && (
              <>
                {effectiveShippingAddress.company}
                <br />
              </>
            )}
          {effectiveShippingAddress.address1}
          <br />
          {effectiveShippingAddress.address2 && (
            <>
              {effectiveShippingAddress.address2}
              <br />
            </>
          )}
          {effectiveShippingAddress.city}
          <br />
          {effectiveShippingAddress.province_code}{' '}
          {effectiveShippingAddress.zip}
          <br />
          {effectiveShippingAddress.country}
          <br />
          {effectiveShippingAddress.phone}
          <br />
          {effectiveShippingAddress.email}
          <br />
        </Typography>
      )
    } else if (effectiveShippingAddress.platform === 'Inhouse') {
      return (
        <Typography variant='body1'>
          {effectiveShippingAddress.name}
          <br />
          {effectiveShippingAddress.address1}
          <br />
          {effectiveShippingAddress.address2 && (
            <>
              {effectiveShippingAddress.address2}
              <br />
            </>
          )}
          {effectiveShippingAddress.suburb}
          <br />
          {effectiveShippingAddress.state} {effectiveShippingAddress.postcode}
          <br />
          {effectiveShippingAddress.country}
          <br />
          {effectiveShippingAddress.phone}
          <br />
          {effectiveShippingAddress.email}
          <br />
        </Typography>
      )
    } else if (effectiveShippingAddress.platform === 'eBay') {
      return (
        <Typography variant='body1'>
          {effectiveShippingAddress.Name}
          <br />
          {effectiveShippingAddress.Street1}
          <br />
          {effectiveShippingAddress.Street2}
          <br />
          {effectiveShippingAddress.CityName}
          <br />
          {effectiveShippingAddress.StateOrProvince}{' '}
          {effectiveShippingAddress.PostalCode}
          <br />
          {effectiveShippingAddress.CountryName}
          <br />
          {effectiveShippingAddress.Phone}
          <br />
          {effectiveShippingAddress.email}
          <br />
        </Typography>
      )
    }
  }

  const handleSelectShopifyAddress = address => {
    const tempShippingAddress = newShippingAddress
    Promise.all([
      getStreetAddress(address).then(streetAddress => {
        tempShippingAddress.address1 = streetAddress
      }),
      getSuburb(address).then(suburb => {
        tempShippingAddress.city = suburb
      }),

      getPostcode(address).then(postcode => {
        tempShippingAddress.zip = postcode
      }),
      getState(address).then(state => {
        tempShippingAddress.province_code = state
      }),
      getCountry(address).then(country => {
        tempShippingAddress.country = country.name
      })
    ]).then(() => {
      tempShippingAddress.isDirty = true
      setNewShippingAddress(tempShippingAddress)
      setAutoChanged(true)
      forceUpdate()
    })
  }

  const handleSelectEbayAddress = address => {
    const tempShippingAddress = newShippingAddress
    Promise.all([
      getStreetAddress(address).then(streetAddress => {
        tempShippingAddress.street1 = streetAddress
      }),
      getSuburb(address).then(suburb => {
        tempShippingAddress.cityName = suburb
      }),

      getPostcode(address).then(postcode => {
        tempShippingAddress.PostalCode = postcode
      }),
      getState(address).then(state => {
        tempShippingAddress.StateOrProvince = state
      }),
      getCountry(address).then(country => {
        tempShippingAddress.CountryName = country.name
      })
    ]).then(() => {
      tempShippingAddress.isDirty = true
      setNewShippingAddress(tempShippingAddress)
      setAutoChanged(true)
      forceUpdate()
    })
  }

  const handleSelectInhouseAddress = address => {
    const tempShippingAddress = newShippingAddress
    Promise.all([
      getStreetAddress(address).then(streetAddress => {
        tempShippingAddress.address1 = streetAddress
      }),
      getSuburb(address).then(suburb => {
        tempShippingAddress.suburb = suburb
      }),

      getPostcode(address).then(postcode => {
        tempShippingAddress.postcode = postcode
      }),
      getState(address).then(state => {
        tempShippingAddress.state = state
      }),
      getCountry(address).then(country => {
        tempShippingAddress.country = country.name
        tempShippingAddress.country_code = country.code
      })
    ]).then(() => {
      tempShippingAddress.isDirty = true
      setNewShippingAddress(tempShippingAddress)
      setAutoChanged(true)
      forceUpdate()
    })
  }

  const getStreetAddress = async address => {
    let streetAddress = ''
    return Promise.all(
      address.address_components.map(component => {
        if (component.types.includes('street_number')) {
          streetAddress = streetAddress + component.long_name
        }
        if (component.types.includes('route')) {
          streetAddress = streetAddress + ' ' + component.long_name
        }
      })
    ).then(() => {
      return streetAddress
    })
  }

  const getSuburb = async address => {
    let suburb = ''
    return Promise.all(
      address.address_components.map(component => {
        if (component.types.includes('locality')) {
          suburb = component.long_name
        }
      })
    ).then(() => {
      return suburb
    })
  }

  const getPostcode = async address => {
    let postcode = ''
    return Promise.all(
      address.address_components.map(component => {
        if (component.types.includes('postal_code')) {
          postcode = component.long_name
        }
      })
    ).then(() => {
      return postcode
    })
  }

  const getState = async address => {
    let state = ''
    return Promise.all(
      address.address_components.map(component => {
        if (component.types.includes('administrative_area_level_1')) {
          state = component.short_name
        }
      })
    ).then(() => {
      return state
    })
  }

  const getCountry = async address => {
    const country = {}
    return Promise.all(
      address.address_components.map(component => {
        if (component.types.includes('country')) {
          country.name = component.long_name
          country.code = component.short_name
        }
      })
    ).then(() => {
      return country
    })
  }
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>In Stock</TableCell>
            <TableCell>SKU</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Quantity to fulfil</TableCell>
            <TableCell>Quantity Scanned</TableCell>
            {refunded && <TableCell>Restock</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {pickedArray.length !== 0 ? (
            pickedArray.map((item, i) => {
              return (
                <OrderContentsRow
                  dbOrder={dbOrder}
                  setSnackbarOpen={setSnackbarOpen}
                  setSnackbarMessage={setSnackbarMessage}
                  key={i}
                  item={item}
                  refunded={refunded}
                  pickedArray={pickedArray}
                  platform={platform}
                />
              )
            })
          ) : (
            <TableRow>
              <TableCell>Loading...</TableCell>
              <TableCell>Loading...</TableCell>
              <TableCell>Loading...</TableCell>
              <TableCell>Loading...</TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell style={{ verticalAlign: 'top' }}>
              <IconButton onClick={handleEditShippingAddress}>
                <EditIcon fontSize='inherit' />
              </IconButton>
            </TableCell>
            <TableCell>{composeShippingAddressDom()}</TableCell>
            <TableCell style={{ verticalAlign: 'top' }}>
              <Typography variant='body2'>Buyer Notes:</Typography>
              <Typography variant='body1' style={{ color: 'red' }}>
                {dbOrder.notes}
              </Typography>
            </TableCell>
            {platform === 'Shopify' && (
              <>
                <ShopifySpecificStat order={order} />
              </>
            )}
            {platform === 'eBay' && <EbaySpecificStat order={dbOrder} />}
          </TableRow>
        </TableFooter>
      </Table>

      {/* Shipping Address Modal */}
      <Dialog
        open={openShippingAddressModal}
        onClose={handleShippingAddressCancel}
        fullWidth
        maxWidth='sm'
        aria-labelledby='form-dialog-title'
        className='editAddressDialog'
      >
        <DialogTitle id='form-dialog-title'>Shipping Address</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {newShippingAddress.platform === 'Shopify' ? (
              <>
                <Grid item xs={12}>
                  <Autocomplete
                    apiKey='AIzaSyCUNzg-MJYTKkF1p15Lle5MRlCyrPMrZHE'
                    className='autocomplete'
                    onPlaceSelected={place => {
                      handleSelectShopifyAddress(place)
                    }}
                    types={['address']}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    margin='dense'
                    name='first_name'
                    label='First Name'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.first_name}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    margin='dense'
                    name='last_name'
                    label='Last Name'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.last_name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin='dense'
                    name='company'
                    label='Company'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.company}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin='dense'
                    name='address1'
                    variant={autoChanged ? 'filled' : 'standard'}
                    label='Address'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.address1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin='dense'
                    name='address2'
                    label='Apartment, suite, etc'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.address2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin='dense'
                    variant={autoChanged ? 'filled' : 'standard'}
                    name='city'
                    label='Suburb'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.city}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    margin='dense'
                    name='country'
                    variant={autoChanged ? 'filled' : 'standard'}
                    label='Country/Region'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.country}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    margin='dense'
                    name='province_code'
                    variant={autoChanged ? 'filled' : 'standard'}
                    label='State/territory'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.province_code}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    margin='dense'
                    name='zip'
                    variant={autoChanged ? 'filled' : 'standard'}
                    label='Postcode'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.zip}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    margin='dense'
                    name='phone'
                    label='Phone'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.phone}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    margin='dense'
                    name='email'
                    label='Email'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.email}
                  />
                </Grid>
              </>
            ) : newShippingAddress.platform === 'eBay' ? (
              <>
                <Grid item xs={12}>
                  <Autocomplete
                    apiKey='AIzaSyCUNzg-MJYTKkF1p15Lle5MRlCyrPMrZHE'
                    className='autocomplete'
                    onPlaceSelected={place => {
                      handleSelectEbayAddress(place)
                    }}
                    types={['address']}
                    componentRestrictions={{ country: 'au' }}
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    margin='dense'
                    name='Name'
                    label='Full Name'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.Name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin='dense'
                    name='company'
                    label='Company'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.company}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin='dense'
                    name='Street1'
                    variant={autoChanged ? 'filled' : 'standard'}
                    label='Street 1'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.Street1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin='dense'
                    name='Street2'
                    label='Street 2'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.Street2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin='dense'
                    name='CityName'
                    variant={autoChanged ? 'filled' : 'standard'}
                    label='Suburb'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.CityName}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    margin='dense'
                    name='CountryName'
                    label='Country'
                    variant={autoChanged ? 'filled' : 'standard'}
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.CountryName}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    margin='dense'
                    name='StateOrProvince'
                    variant={autoChanged ? 'filled' : 'standard'}
                    label='State'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.StateOrProvince}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    margin='dense'
                    name='PostalCode'
                    variant={autoChanged ? 'filled' : 'standard'}
                    label='Postcode'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.PostalCode}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    margin='dense'
                    name='phone'
                    label='Phone'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.Phone}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    margin='dense'
                    name='email'
                    label='Email'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.email}
                  />
                </Grid>
              </>
            ) : (
              <>
                {/* Inhouse shipping address */}
                <Grid item xs={12}>
                  <Autocomplete
                    apiKey='AIzaSyCUNzg-MJYTKkF1p15Lle5MRlCyrPMrZHE'
                    className='autocomplete'
                    onPlaceSelected={place => {
                      handleSelectInhouseAddress(place)
                    }}
                    types={['address']}
                    componentRestrictions={{ country: 'au' }}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    margin='dense'
                    name='firstName'
                    label='First Name'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.firstName}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    margin='dense'
                    name='lastName'
                    label='Last Name'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin='dense'
                    name='company'
                    label='Company'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.company}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin='dense'
                    name='address1'
                    variant={autoChanged ? 'filled' : 'standard'}
                    label='Street 1'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.address1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin='dense'
                    name='address2'
                    label='Street 2'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.address2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin='dense'
                    name='suburb'
                    variant={autoChanged ? 'filled' : 'standard'}
                    label='Suburb'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.suburb}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin='dense'
                    name='country'
                    variant={autoChanged ? 'filled' : 'standard'}
                    label='Country'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.country}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin='dense'
                    name='country_code'
                    variant={autoChanged ? 'filled' : 'standard'}
                    label='Country Code'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.country_code}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin='dense'
                    name='state'
                    variant={autoChanged ? 'filled' : 'standard'}
                    label='State'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.state}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin='dense'
                    name='postcode'
                    variant={autoChanged ? 'filled' : 'standard'}
                    label='Postcode'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.postcode}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    margin='dense'
                    name='phone'
                    label='Phone'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.Phone}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    margin='dense'
                    name='email'
                    label='Email'
                    fullWidth
                    onChange={handleAddressFieldChange}
                    value={newShippingAddress.email}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleShippingAddressCancel} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleShippingAddressSave} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDirtyShippingAddressModal}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Discard unsaved changes?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            There are unsaved changes. Are you sure you discard the changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleDirtyShippingAddressModalClose(false)}
            color='primary'
            autoFocus
          >
            No
          </Button>
          <Button
            onClick={() => handleDirtyShippingAddressModalClose(true)}
            color='primary'
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default OrderContentsTable
