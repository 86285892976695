import React, { useState, useEffect } from 'react'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import Typography from '@material-ui/core/Typography/Typography'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Select from '@material-ui/core/Select/Select'
import Button from '@material-ui/core/Button/Button'
import Grid from '@material-ui/core/Grid/Grid'
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress'
import Pagination from '@material-ui/lab/Pagination'
import { useSelector, useDispatch } from 'react-redux'
import { DateTime } from 'luxon'
import { SET_USER_PREFERENCES } from '../../redux/constants/actionTypes'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import NativeSelect from '@material-ui/core/NativeSelect'

const OrdersTable = props => {
  const reduxState = useSelector(state => state)
  const dispatch = useDispatch()
  const {
    orders,
    history,
    showAll,
    fetchOrders,
    fetchAllOrders,
    loader
  } = props
  const [sort, setSort] = useState()
  const [asc, setAsc] = useState()
  const [, updateState] = React.useState()
  const [page, setPage] = React.useState(1)
  const [clients, setClients] = React.useState()
  const [filterClient, setFilterClient] = React.useState('all')
  const [filteredOrders, setFilteredOrders] = React.useState()
  const [rowsPerPage, setRowsPerPage] = React.useState(100)
  const forceUpdate = React.useCallback(() => updateState({}), [])

  useEffect(() => {
    // Manage user preferences for sorting the table
    if (reduxState.userPreferences.orderSortCriteria) {
      setSort(reduxState.userPreferences.orderSortCriteria)
      forceUpdate()
    } else {
      setSort('orderName')
      forceUpdate()
    }
    if (reduxState.userPreferences.orderSortDirection) {
      setAsc(reduxState.userPreferences.orderSortDirection)
      forceUpdate()
    } else {
      setAsc('asc')
      forceUpdate()
    }

    if (reduxState.userPreferences.clientFilter) {
      setFilterClient(reduxState.userPreferences.clientFilter)
      forceUpdate()
    } else {
      setFilterClient('all')
    }
    window
      .fetch('/api/client/getClients')
      .then(res => res.json())
      .then(data => {
        setClients(data.clients)
      })
  }, [])

  useEffect(() => {
    if (filterClient) {
      const newFilteredOrders = []
      Promise.all(
        orders.map(order => {
          if (filterClient === 'all' || order.clientId === filterClient) {
            newFilteredOrders.push(order)
          }
        })
      ).then(() => {
        setPage(1)
        setFilteredOrders(newFilteredOrders)
        forceUpdate()
      })
    } else {
      setFilteredOrders(orders)
      forceUpdate()
    }
  }, [orders])

  const handleChangeOrderSortingPreferences = e => {
    setSort(e.target.value)
    dispatch({
      type: SET_USER_PREFERENCES,
      payload: {
        ...reduxState.userPreferences,
        orderSortCriteria: e.target.value
      }
    })
    window.fetch('/api/userPreferences/setPreference', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userId: reduxState.userPreferences.userId,
        preferences: {
          orderSortCriteria: e.target.value
        }
      })
    })
  }

  const handleChangeOrderDirectionPreferences = e => {
    setAsc(e.target.value)
    dispatch({
      type: SET_USER_PREFERENCES,
      payload: {
        ...reduxState.userPreferences,
        orderSortDirection: e.target.value
      }
    })
    window.fetch('/api/userPreferences/setPreference', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userId: reduxState.userPreferences.userId,
        preferences: {
          orderSortDirection: e.target.value
        }
      })
    })
  }

  const handleClientFilterChange = e => {
    setFilterClient(e.target.value)
    const newFilteredOrders = []
    Promise.all(
      orders.map(order => {
        if (e.target.value === 'all' || order.clientId === e.target.value) {
          newFilteredOrders.push(order)
        }
      })
    ).then(() => {
      setPage(1)
      setFilteredOrders(newFilteredOrders)
      forceUpdate()
    })

    // Set preferences
    dispatch({
      type: SET_USER_PREFERENCES,
      payload: {
        ...reduxState.userPreferences,
        clientFilter: e.target.value
      }
    })
    window.fetch('/api/userPreferences/setPreference', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        userId: reduxState.userPreferences.userId,
        preferences: {
          clientFilter: e.target.value
        }
      })
    })
  }

  const handlePageChange = (e, value) => {
    setPage(value)
  }

  return (
    <>
      {sort && asc && (
        <Grid
          container
          justify='space-between'
          alignContent='space-between'
          alignItems='center'
        >
          <Grid item>
            <Button
              onClick={() => (showAll ? fetchOrders() : fetchAllOrders())}
            >
              {showAll ? 'Show filtered ' : 'Show All'}
            </Button>
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems='center'>
              <Grid item>
                <Typography variant='caption'>Client: </Typography>
              </Grid>
              <Grid item>
                <Select
                  variant='standard'
                  onChange={e => handleClientFilterChange(e)}
                  value={filterClient}
                >
                  <MenuItem value='all'>All</MenuItem>
                  {clients &&
                    clients.map(client => {
                      return (
                        <MenuItem key={client._id} value={client._id}>
                          {client.name}
                        </MenuItem>
                      )
                    })}
                </Select>
              </Grid>
              <Grid item>
                <Typography variant='caption'>Sorting: </Typography>
              </Grid>
              <Grid item>
                <Select
                  variant='standard'
                  onChange={e => handleChangeOrderSortingPreferences(e)}
                  value={sort}
                >
                  <MenuItem value='orderName'>Order Name</MenuItem>
                  <MenuItem value='storeName'>Store Name</MenuItem>
                  <MenuItem value='notes'>Notes</MenuItem>
                  <MenuItem value='createdAt'>Ordered At</MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <Select
                  variant='standard'
                  onChange={e => handleChangeOrderDirectionPreferences(e)}
                  value={asc}
                >
                  <MenuItem value='asc'>ASC</MenuItem>
                  <MenuItem value='desc'>DESC</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {loader ? (
        <LinearProgress style={{ width: '100%', margin: '25px 5px' }} />
      ) : (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order Name</TableCell>
                <TableCell>Store Name</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>Ordered At</TableCell>
                {showAll && (
                  <>
                    <TableCell>Fulfilled</TableCell>
                    <TableCell>Blocked</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrders &&
                filteredOrders
                  .sort(function (a, b) {
                    if (sort === 'createdAt') {
                      if (asc === 'asc') {
                        return new Date(a[sort]) < new Date(b[sort]) ? -1 : 1
                      } else {
                        return new Date(a[sort]) > new Date(b[sort]) ? -1 : 1
                      }
                    }
                    if (asc === 'asc') {
                      return a[sort] < b[sort] ? -1 : 1
                    } else {
                      return a[sort] > b[sort] ? -1 : 1
                    }
                  })
                  .map((o, i) => {
                    if (filterClient === 'all' || o.clientId === filterClient) {
                      if (
                        i >= (page - 1) * rowsPerPage &&
                        i < page * rowsPerPage
                      ) {
                        return (
                          <TableRow
                            key={i}
                            style={{ backgroundColor: o.picked && 'limegreen' }}
                            hover
                            onClick={() => history.push('/order/' + o._id)}
                          >
                            <TableCell>
                              {o.orderName ? o.orderName : o.OrderID}
                            </TableCell>
                            <TableCell>
                              {o.storeName ? o.storeName : 'Unknown'}
                            </TableCell>
                            <TableCell>{o.notes}</TableCell>
                            <TableCell>
                              {DateTime.fromISO(o.createdAt)
                                .setLocale('en-AU')
                                .toFormat('d-LLL-yyyy h:mm:ss a')}
                            </TableCell>
                          </TableRow>
                        )
                      }
                    }
                  })}
            </TableBody>
          </Table>
          <Grid container justify='center' style={{ marginTop: 25 }}>
            <Grid item style={{ marginLeft: '25%' }}>
              <Pagination
                count={Math.ceil(
                  filteredOrders
                    ? filteredOrders.length / rowsPerPage
                    : orders.length / rowsPerPage
                )}
                page={page}
                onChange={handlePageChange}
              />
            </Grid>
            <Grid item style={{ marginLeft: '20%' }}>
              <FormControl style={{ width: 120 }}>
                <InputLabel htmlFor='age-native-label-placeholder'>
                  Orders Per Page
                </InputLabel>
                <NativeSelect
                  value={rowsPerPage}
                  onChange={e => setRowsPerPage(e.target.value)}
                  inputProps={{
                    name: 'age',
                    id: 'age-native-label-placeholder'
                  }}
                >
                  <option aria-label='None' value='' disabled>
                    Orders Per Page
                  </option>
                  <option value={100}>100</option>
                  <option value={50}>50</option>
                  <option value={20}>20</option>
                  <option value={10}>10</option>
                </NativeSelect>
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default OrdersTable
