import React, { useEffect, useState } from 'react'
import { MDBContainer } from 'mdbreact'
import 'date-fns'
import '../../index.css'
import Paper from '@material-ui/core/Paper/Paper'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import axios from 'axios'
import { CSVLink } from 'react-csv'
import Grid from '@material-ui/core/Grid/Grid'
import Button from '@material-ui/core/Button/Button'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import MUIDataTable from 'mui-datatables'
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress'

const OrderReportPage = props => {
  var start = new Date()
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  var end = new Date()
  end.setHours(0)
  end.setMinutes(0)
  const [startDate, setStartDate] = useState(start)
  const [endDate, setEndDate] = useState(end)
  const [orders, setOrders] = useState([])
  const [client, setClient] = useState({})
  const [clientList, setClientList] = useState([])
  const [, updateState] = React.useState()
  const [isLoading, setIsLoading] = React.useState(false)
  const forceUpdate = React.useCallback(() => updateState({}), [])

  useEffect(() => {
    window
      .fetch('/api/client/getClients')
      .then(res => res.json())
      .then(data => {
        setClientList(data.clients)
        setClient(data.clients[0])
      })
  }, [])

  useEffect(() => {
    getOrderReport('from', startDate)
  }, [client])

  useEffect(() => {
    getOrderReport()
  }, [startDate, endDate])

  function handleDateChange (fromOrTo, e) {
    if (fromOrTo === 'from') {
      setStartDate(e)
    } else {
      setEndDate(e)
    }
  }

  function getOrderReport () {
    setIsLoading(true)
    axios
      .get(
        '/api/order/orderReport/' + startDate + '/' + endDate + '/' + client._id
      )
      .then(res => {
        parseOrders(res.data.orders)
      })
  }

  function parseOrders (orders) {
    const myOrders = []

    orders.map(order => {
      if (order.lineItems) {
        order.lineItems.map(item => {
          myOrders.push({
            lineItemId: item.lineItemId,
            reason: 'Fulfilling Order ' + order.orderName,
            sku: item.sku,
            qty: item.qty,
            date: order.fulfillment.fulfilledAt || ''
          })
        })
      }
    })
    setIsLoading(false)
    setOrders(myOrders)
    forceUpdate()
  }

  const columns = [
    { name: 'lineItemId', label: 'Line Items' },
    { name: 'sku', label: 'SKU' },
    { name: 'qty', label: 'Quantity Sent' },
    { name: 'reason', label: 'Reason' },
    { name: 'date', label: 'Fulfillment Date' }
  ]

  return (
    <MDBContainer>
      {/* <MDBBreadcrumb>
                        <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
                        <MDBBreadcrumbItem>Items</MDBBreadcrumbItem>
                        <MDBBreadcrumbItem active>Shipment Report</MDBBreadcrumbItem>
                    </MDBBreadcrumb> */}
      <Paper style={{ padding: 20 }}>
        <Grid
          container
          justify='space-between'
          alignItems='center'
          alignContent='flex-end'
        >
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                format='yyyy-MM-dd'
                margin='normal'
                id='from-date'
                label='From:'
                onChange={e => handleDateChange('from', e)}
                value={startDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                variant='inline'
                style={{ margin: 10 }}
              />
              <KeyboardDatePicker
                format='yyyy-MM-dd'
                margin='normal'
                id='to-date'
                label='To:'
                onChange={e => handleDateChange('to', e)}
                value={endDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                variant='inline'
                style={{ margin: 10 }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <InputLabel id='client-label' shrink>
              Client
            </InputLabel>
            <Select
              labelId='client-label'
              value={client}
              onChange={e => {
                setClient(e.target.value)
              }}
            >
              {clientList
                .sort((a, b) => a.name > b.name)
                .map((row, i) => {
                  return (
                    <MenuItem key={i} value={row}>
                      {row.name}
                    </MenuItem>
                  )
                })}
            </Select>
          </Grid>
          <Grid item>
            <Button variant='contained' color='primary'>
              <CSVLink
                data={orders}
                filename={
                  'orderReports-' +
                  client.name +
                  '-' +
                  startDate.toLocaleDateString('en-GB') +
                  '-' +
                  endDate.toLocaleDateString('en-GB') +
                  '.csv'
                }
                style={{ color: 'white' }}
              >
                Download Report
              </CSVLink>
            </Button>
          </Grid>
        </Grid>
        {isLoading ? (
          <LinearProgress style={{ width: '100%', margin: '25px 5px' }} />
        ) : (
          <MUIDataTable
            title='Order Report'
            data={orders}
            columns={columns}
            options={{
              download: false,
              isSelectable: false,
              elevation: 0,
              selectableRows: 'none',
              selectableRowsHeader: false
            }}
          />
        )}
      </Paper>
    </MDBContainer>
  )
}

export default OrderReportPage
