import React from 'react'
import { MDBContainer, MDBFooter } from 'mdbreact'
import { useSelector } from 'react-redux'
import '../../App.css'

const FooterPage = () => {
  const reduxState = useSelector(state => state)

  return (
    <MDBFooter color='blue' className='font-small pt-1 mt-4 fixed-bottom'>
      <div className='footer-copyright text-center py-1'>
        <MDBContainer fluid>
          Copyright {new Date().getFullYear()} <a href='https://odeo.com.au' target='_blank' rel='noopener noreferrer'> ODEO </a>
        </MDBContainer>
      </div>
      {reduxState.appVersion && reduxState.environmentVariables.backendVersion
        ? <div className='footer-copyright text-center py-1'>
          <MDBContainer fluid>
          Versions {reduxState.appVersion} / {reduxState.environmentVariables.backendVersion}
          </MDBContainer>
          </div>
        : <div className='footer-copyright text-center py-1'>
          <MDBContainer fluid>Versions</MDBContainer>
        </div>}
    </MDBFooter>
  )
}

export default FooterPage
