import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import 'date-fns'
import FormControl from '@material-ui/core/FormControl/FormControl'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import TextField from '@material-ui/core/TextField/TextField'

const PickingList = props => {
  var start = new Date()
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  var end = new Date()
  end.setHours(23)
  end.setMinutes(59)

  const [startDate, setStartDate] = useState(start)
  const [endDate, setEndDate] = useState(end)
  const [clientMap, setClientMap] = useState([])
  const [client, setClient] = useState({})
  const [pickList, setPickList] = useState([])

  useEffect(() => {
    window
      .fetch('/api/client/getClients')
      .then(res => res.json())
      .then(data => {
        setClientMap(data.clients)
        setClient(data.clients[0])
      })
  }, [])

  useEffect(() => {
    if (startDate && endDate && client) {
      window
        .fetch(
          '/api/order/getQtyCounts/' +
            startDate +
            '/' +
            endDate +
            '/' +
            client._id
        )
        .then(res => res.json())
        .then(data => {
          setPickList(data)
        })
    }
  }, [startDate, endDate, client])

  function handleDateChange (fromOrTo, e) {
    if (fromOrTo === 'from') {
      setStartDate(e)
    } else {
      setEndDate(e)
    }
  }

  function handleTimeChange (fromOrTo, e) {
    e = e.target.value
    if (fromOrTo === 'from') {
      const newTime = new Date(startDate)
      newTime.setHours(e.substring(0, 2))
      newTime.setMinutes(e.substring(3, 5))
      setStartDate(newTime)
    } else {
      const newTime = new Date(endDate)
      newTime.setHours(e.substring(0, 2))
      newTime.setMinutes(e.substring(3, 5))
      setEndDate(newTime)
    }
  }

  function handleChangeClient (value) {
    setClient(value)
  }

  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    }
  }))
  const classes = useStyles()

  return (
    <>
      <Grid
        container
        alignContent='space-between'
        justify='space-between'
        alignItems='stretch'
      >
        <Grid item>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
              <Grid item>
                <Grid container direction='column'>
                  <Grid item>
                    <KeyboardDatePicker
                      format='yyyy-MM-dd'
                      margin='normal'
                      id='from-date'
                      label='From:'
                      onChange={e => handleDateChange('from', e)}
                      value={startDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      variant='inline'
                      style={{ margin: 10 }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id='from-time'
                      label='From Time:'
                      type='time'
                      defaultValue='00:00'
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                      onChange={e => handleTimeChange('from', e)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction='column'>
                  <Grid item>
                    <KeyboardDatePicker
                      format='yyyy-MM-dd'
                      margin='normal'
                      id='to-date'
                      label='To:'
                      onChange={e => handleDateChange('to', e)}
                      value={endDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      variant='inline'
                      style={{ margin: 10 }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id='to-time'
                      label='To Time:'
                      type='time'
                      defaultValue='23:59'
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{
                        step: 300 // 5 min
                      }}
                      onChange={e => handleTimeChange('to', e)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
          <FormControl className={classes.formControl}>
            <InputLabel id='demo-simple-select-label'>Client</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={client}
              onChange={e => handleChangeClient(e.target.value)}
              style={{ marginTop: 15 }}
            >
              {clientMap.map((row, i) => {
                return (
                  <MenuItem key={i} value={row}>
                    {row.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>SKU</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Qty</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pickList.map((a, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{a.sku}</TableCell>
                <TableCell>{a.title}</TableCell>
                <TableCell>{a.qty}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}

export default PickingList
