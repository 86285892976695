import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBCollapse,
  MDBCollapseHeader,
  MDBContainer,
  MDBIcon,
  MDBDataTable,
  MDBBtn
} from 'mdbreact'
import Paper from '@material-ui/core/Paper/Paper'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import FormGroup from '@material-ui/core/FormGroup/FormGroup'
import Button from '@material-ui/core/Button/Button'
import Grid from '@material-ui/core/Grid/Grid'
import Table from '@material-ui/core/Table/Table'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableBody from '@material-ui/core/TableBody/TableBody'
import EditReturnsRow from './EditReturnsRow'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import ReactGA from 'react-ga'
import CreateReturnsSection from './CreateReturnsSection'
import { useHistory } from 'react-router'
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress'
import Pagination from '@material-ui/lab/Pagination'

const ReturnsPage = props => {
  const reduxState = useSelector(state => state)
  const [returnsList, setReturnsList] = useState()
  const [getAll, setGetAll] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [unfulfilledReturnsList, setUnfulfilledReturnsList] = useState()
  const [authQueryReceived, setQueryReceived] = useState(false)
  const [collapseId, setCollapseId] = useState('collapse1')
  const columns = [
    { name: 'reason', label: 'Reason' },
    { name: 'solution', label: 'Solution' },
    { name: 'receivedDate', label: 'Received At' },
    {
      name: 'resolved',
      label: 'Resolved',
      options: {
        customBodyRenderLite: dataIndex => {
          const row = returnsList[dataIndex]
          return (
            <Grid container>
              <Grid item xs={6}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color='primary'
                        value={row.resolved}
                        checked={row.resolved}
                        name={row.resolved ? 'Resolved' : 'Not Resolved'}
                      />
                    }
                    label={row.resolved ? 'Resolved' : 'Not Resolved'}
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                {/* <Button variant="contained" color="primary" onClick={() => updateReturn(row)}> Update </Button> */}
              </Grid>
            </Grid>
          )
        }
      }
    }
  ]
  const history = useHistory()
  const [page, setPage] = React.useState(1)

  useEffect(() => {
    if (!props.isLoggedIn && !props.isLoading) {
      history.push('/login')
    }
    // Add Google Analytics
    if (
      reduxState.environmentVariables &&
      reduxState.environmentVariables.public &&
      reduxState.environmentVariables.public.analyticsTrackingId
    ) {
      ReactGA.initialize(
        reduxState.environmentVariables.public.analyticsTrackingId,
        { debug: false }
      )
      ReactGA.pageview(window.location.pathname + window.location.search)
    }

    window
      .fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        setLoggedIn(data.success)
        setQueryReceived(true)
      })
  }, [])

  useEffect(() => {
    getRows()
  }, [getAll])

  function getRows () {
    // let url = '/returns/'
    // if (!getAll) {
    //   url += 'getReturns'
    // }
    window
      .fetch('/api/returns/')
      .then(res => res.json())
      .then(data => {
        setReturnsList(data)
        console.log(data.length)
        // get unfulfilled returns list
        const newUnfulfilledList = []
        Promise.all(
          data.map(elem => {
            if (!elem.resolved) {
              newUnfulfilledList.push(elem)
            }
          })
        ).then(() => {
          console.log(newUnfulfilledList)
          setUnfulfilledReturnsList(newUnfulfilledList)
        })
      })
  }

  function toggleCollapse (id) {
    if (collapseId !== id) {
      setCollapseId(id)
    } else {
      setCollapseId('')
    }
  }

  const handlePageChange = (e, value) => {
    setPage(value)
  }

  return (
    <div>
      {props.isLoggedIn ? (
        <MDBContainer>
          <MDBBreadcrumb>
            <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>Edit Returns</MDBBreadcrumbItem>
          </MDBBreadcrumb>

          <MDBCard
            className='mt-3'
            style={{ backgroundColor: 'rgba(0,0,0,.03)' }}
          >
            <MDBCollapseHeader
              tagClassName='d-flex justify-content-between'
              onClick={() => toggleCollapse('collapse0')}
            >
              Create New Return
              <MDBIcon
                icon={collapseId === 'collapse0' ? 'angle-up' : 'angle-down'}
              />
            </MDBCollapseHeader>
            {collapseId === 'collapse0' && (
              <MDBCollapse id='collapse0' isOpen={collapseId}>
                <MDBCardBody>
                  <CreateReturnsSection getRows={getRows} />
                </MDBCardBody>
              </MDBCollapse>
            )}
          </MDBCard>
          <MDBCard
            className='mt-3'
            style={{ backgroundColor: 'rgba(0,0,0,.03)' }}
          >
            <MDBCollapseHeader
              tagClassName='d-flex justify-content-between'
              onClick={() => toggleCollapse('collapse1')}
            >
              View Returns
              <MDBIcon
                icon={collapseId === 'collapse1' ? 'angle-up' : 'angle-down'}
              />
            </MDBCollapseHeader>
            {collapseId === 'collapse1' && (
              <MDBCollapse id='collapse1' isOpen={collapseId}>
                <MDBCardBody>
                  <Paper style={{ padding: 25 }} elevation={5}>
                    <Grid container justify='flex-end'>
                      <Button
                        variant='contained'
                        onClick={() => {
                          setGetAll(!getAll)
                          setPage(1)
                        }}
                      >
                        Show {getAll ? 'unresolved only' : 'all'}
                      </Button>
                    </Grid>
                    {returnsList ? (
                      <>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Store</TableCell>
                              <TableCell>Order No</TableCell>
                              <TableCell>Reason</TableCell>
                              <TableCell>Action Taken</TableCell>
                              <TableCell>Resolved</TableCell>
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {getAll &&
                              returnsList &&
                              returnsList.map((row, i) => {
                                if (i >= (page - 1) * 10 && i < page * 10) {
                                  return (
                                    <EditReturnsRow
                                      key={i}
                                      row={row}
                                      setSnackbarOpen={setSnackbarOpen}
                                      setSnackbarMessage={setSnackbarMessage}
                                    />
                                  )
                                }
                              })}
                            {!getAll &&
                              unfulfilledReturnsList &&
                              unfulfilledReturnsList.map((row, i) => {
                                if (i >= (page - 1) * 10 && i < page * 10) {
                                  return (
                                    <EditReturnsRow
                                      key={i}
                                      row={row}
                                      setSnackbarOpen={setSnackbarOpen}
                                      setSnackbarMessage={setSnackbarMessage}
                                    />
                                  )
                                }
                              })}
                          </TableBody>
                        </Table>
                        <Grid
                          container
                          justify='center'
                          style={{ marginTop: 25 }}
                        >
                          <Pagination
                            count={
                              returnsList &&
                              unfulfilledReturnsList &&
                              (getAll
                                ? Math.ceil(returnsList.length / 10)
                                : Math.ceil(unfulfilledReturnsList.length / 10))
                            }
                            page={page}
                            onChange={handlePageChange}
                          />
                        </Grid>
                      </>
                    ) : (
                      <LinearProgress
                        style={{ width: '100%', margin: '25px 5px' }}
                      />
                    )}
                    <Snackbar
                      onClose={() => setSnackbarOpen(false)}
                      open={snackbarOpen}
                      message={snackbarMessage}
                    />
                  </Paper>
                </MDBCardBody>
              </MDBCollapse>
            )}
          </MDBCard>
        </MDBContainer>
      ) : (
        <div />
      )}
    </div>
  )
}

export default ReturnsPage
