import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField/TextField'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import Button from '@material-ui/core/Button/Button'
import axios from 'axios'

const ClientRatesPickingRow = props => {
  const { client, row, setSnackbarOpen, setSnackbarMessage } = props
  const [storeName, setStoreName] = useState({})
  const [threshold, setThreshold] = useState(row.threshold)
  const [origThreshold, setOrigThreshold] = useState(row.threshold)
  const [costUnderThreshold, setCostUnderThreshold] = useState(
    row.costUnderThreshold
  )
  const [origCostUnderThreshold, setOrigCostUnderThreshold] = useState(
    row.costUnderThreshold
  )
  const [costOverThreshold, setCostOverThreshold] = useState(
    row.costOverThreshold
  )
  const [origCostOverThreshold, setOrigCostOverThreshold] = useState(
    row.costOverThreshold
  )

  useEffect(() => {
    const store = client.stores.find(a => a._id === row.storeId)
    if (store) {
      setStoreName(store.platform + ' ' + store.name)
    }
  }, [])

  function updateRow () {
    axios
      .post(
        '/api/invoiceRates/updateRatePicking',
        {
          body: JSON.stringify({
            _id: row._id,
            threshold,
            costUnderThreshold,
            costOverThreshold
          })
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(res => {
        if (res.data.success) {
          setOrigThreshold(threshold)
          setOrigCostUnderThreshold(costUnderThreshold)
          setOrigCostOverThreshold(costOverThreshold)
          setSnackbarMessage('Updated picking')
        } else {
          setSnackbarMessage('Failed')
        }
        setSnackbarOpen(true)
      })
  }

  return (
    <TableRow>
      <TableCell>{storeName + ' ' + row.tag}</TableCell>
      <TableCell>
        <TextField
          type='number'
          helperText={'Saved as: ' + origThreshold}
          label='Threshold'
          value={threshold}
          onChange={e => setThreshold(e.target.value)}
        />
      </TableCell>
      <TableCell>
        <TextField
          type='number'
          helperText={'Saved as: ' + origCostUnderThreshold}
          label='Under'
          value={costUnderThreshold}
          onChange={e => setCostUnderThreshold(e.target.value)}
        />
      </TableCell>
      <TableCell>
        <TextField
          type='number'
          helperText={'Saved as: ' + origCostOverThreshold}
          label='Over'
          value={costOverThreshold}
          onChange={e => setCostOverThreshold(e.target.value)}
        />
      </TableCell>
      <TableCell>
        <Button variant='contained' onClick={() => updateRow()}>
          Update
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default ClientRatesPickingRow
