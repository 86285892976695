import Grid from '@material-ui/core/Grid/Grid'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Switch from '@material-ui/core/Switch/Switch'
import TextField from '@material-ui/core/TextField/TextField'
import React from 'react'
import Divider from '@material-ui/core/Divider/Divider'
import Typography from '@material-ui/core/Typography/Typography'
import { FormControl, FormHelperText } from '@material-ui/core'

const FulfillmentRows = (props) => {
  const { updateFulfillmentRows, i, row, boxTypes } = props

  return (
    <>
      <Grid container spacing={1} alignItems='flex-end' alignContent='flex-end' justify='space-evenly'>
        <Grid item>
          <Typography variant='caption'>{i + 1}</Typography>
        </Grid>
        <Grid item>
          <FormControl>
            <Select
              inputProps={{
                name: 'Box Type',
                id: 'box-type-label-placeholder'
              }}
              name='Material'
              variant='standard'
              defaultValue='None'
              onChange={e => updateFulfillmentRows(e.target.value, i, 'boxType')}
            >
              <MenuItem value='None' disabled>Select One</MenuItem>
              {boxTypes.map((a, i) => {
                return (<MenuItem key={i} value={a.name}>{a.name}</MenuItem>)
              })}
            </Select>
            <FormHelperText>Box Type</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Switch color='primary' onChange={e => updateFulfillmentRows(!row.polybag, i, 'polybag')} />} label='Polybag Used'
          />
        </Grid>
        {row.isEparcel === false
          ? <Grid item>
            <TextField
              // label={<span style={{position: 'relative', top: '-15px'}}><SearchIcon
              //     style={{position: 'relative', top: '5px'}}/>Filter List</span>}
              // defaultValue={props.tracking}
              // onBlur={(e) => updateRow(e.target.value, "trackingNumber")}

              label='Tracking Number'
              helperText='Tracking Number'
              style={{ left: '25px' }}
              onChange={e => updateFulfillmentRows(e.target.value, i, 'trackingNumber')}
            />
          </Grid>
          : <Grid item>
            <TextField
              label='Tracking Number'
              helperText='Tracking Number'
              style={{ left: '25px' }}
              onChange={e => updateFulfillmentRows(e.target.value, i, 'trackingNumber')}
            />
            </Grid>}
        <Grid item>

          <TextField
            // onChange={(e) => updateRow(e.target.value, "weight")}
            // value={props.rowObj.weight}
            inputProps={{
              name: 'Weight',
              id: 'weight-label-placeholder'
            }}
            name='Weight'
            label='Weight'
            variant='standard'
            helperText='grams'
            type='number'
            onChange={e => updateFulfillmentRows(e.target.value, i, 'weight')}
          />
        </Grid>
      </Grid>
      <Divider style={{ marginTop: 15 }} />
    </>
  )
}

export default FulfillmentRows
