import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import { v4 as uuidv4 } from 'uuid'
import { DateTime } from 'luxon'
import PasswordStrengthBar from 'react-password-strength-bar'
import { withSwalInstance } from 'sweetalert2-react'
import ReactGA from 'react-ga'
import swal from 'sweetalert2'
import { SET_ENVIRONMENT_VARIABLES } from '../../redux/constants/actionTypes'

const ResetPasswordPage = (props) => {
  const dispatch = useDispatch()
  const reduxState = useSelector(state => state)
  const history = useHistory()
  const SweetAlert = withSwalInstance(swal)
  const params = useParams()
  const [operationUuid, setOperationUuid] = useState(uuidv4())
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [differentPasswordError, setDifferentPasswordError] = useState(false)
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false)
  const [passwordChangeFail, setPasswordChangeFail] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [code, setCode] = useState(uuidv4()) // assign code with a random initial uuid value
  const [codeIsValid, setCodeIsValid] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [result, setResult] = useState({})
  const [environmentVariables, setEnvironmentVariables] = useState({})

  useEffect(() => {
    window.fetch('/api/env/')
      .then(res => res.json())
      .then(data => {
        setEnvironmentVariables(data)
        dispatch({ type: SET_ENVIRONMENT_VARIABLES, payload: data })

        // Add Google Analytics
        ReactGA.initialize(data.public.analyticsTrackingId, { debug: false })
        ReactGA.pageview(window.location.pathname + window.location.search)

        setOperationUuid(uuidv4())
        setCode(params.code)

        window.fetch('/api/clientsideLogging/write', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            loggingAuthKey: data.public.loggingAuthKey,
            appName: reduxState.appName,
            appVersion: reduxState.appVersion,
            clientSessionUuid: reduxState.clientSideSessionUuid,
            operationUuid: operationUuid,
            datetime: DateTime.local().toString(),
            component: 'ResetPasswordPage',
            message: `Verifying password reset code ${code}.`
          })
        })
      })

    window.fetch('/api/auth/verify-reset-password-request', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        code: params.code
      })
    }).then(res => res.json())
      .then(data => {
        window.fetch('/api/clientsideLogging/write', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            loggingAuthKey: reduxState.environmentVariables.public.loggingAuthKey,
            appName: reduxState.appName,
            appVersion: reduxState.appVersion,
            clientSessionUuid: reduxState.clientSideSessionUuid,
            operationUuid: operationUuid,
            datetime: DateTime.local().toString(),
            component: 'ResetPasswordPage',
            message: `Verify password reset request returned '${data.success}'.`,
            data
          })
        })
        if (data.op === 'VERIFY_RESET_PASSWORD_REQUEST') {
          setCodeIsValid(data.success)
        }
      })
  }, [])

  function handlePasswordChange (e) {
    setPassword(e.target.value)
  }

  function handlePasswordConfirmChange (e) {
    setPasswordConfirm(e.target.value)
  }

  function handleKeyDown (e) {
    if (e.key === 'Enter') {
      handleSaveClick(e)
    }
  }

  function handleSaveClick (e) {
    setOperationUuid(uuidv4())
    if (password === passwordConfirm && password !== '') {
      window.fetch('/api/clientsideLogging/write', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          loggingAuthKey: reduxState.environmentVariables.public.loggingAuthKey,
          appName: reduxState.appName,
          appVersion: reduxState.appVersion,
          clientSessionUuid: reduxState.clientSideSessionUuid,
          operationUuid: operationUuid,
          datetime: DateTime.local().toString(),
          component: 'ResetPasswordPage',
          message: 'Submitting new password ...'
        })
      })

      setDifferentPasswordError(false)
      window.fetch('/api/auth/submit-new-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          clientSessionUuid: reduxState.clientSideSessionUuid,
          operationUuid: operationUuid,
          code: code,
          newPassword: password
        })
      }).then(res => res.json())
        .then(data => {
          window.fetch('/api/clientsideLogging/write', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              loggingAuthKey: reduxState.environmentVariables.public.loggingAuthKey,
              appName: reduxState.appName,
              appVersion: reduxState.appVersion,
              clientSessionUuid: reduxState.clientSideSessionUuid,
              operationUuid: operationUuid,
              datetime: DateTime.local().toString(),
              component: 'ResetPasswordPage',
              message: `Submit password returns result ${data.success}.`,
              data
            })
          })

          setShowResult(true)
          if (data.op === 'SUBMIT_NEW_PASSWORD') {
            if (data.success) {
              setResult({ success: true, message: 'Congratulations! You have successfully reset your password. You can use your new password to login.', sweetalertType: 'success' })
            } else {
              setResult({ success: false, message: 'Sorry! Your reset password attempt is not successful.', sweetalertType: 'error' })
            }
          }

          if (data.success) {
            setPasswordChangeFail(false)
            setPasswordChangeSuccess(true)
            setSuccessMessage(data.message)
          } else {
            setPasswordChangeFail(true)
            setPasswordChangeSuccess(false)
            setErrorMessage(data.message)
          }
        })
    } else if (password !== '') {
      setDifferentPasswordError(true)
    }
  }

  return (
    <>
      {codeIsValid
        ? <MDBContainer>
          <MDBRow center>
            <MDBCol md='4'>
              <form>
                <p className='h4 text-center mb-4'>Change Password</p>
                <label htmlFor='defaultFormLoginEmailEx' className='grey-text'>
                            New Password
                </label>
                <input
                  type='password' id='defaultFormLoginPasswordEx' autoFocus className='form-control'
                  onChange={handlePasswordChange} onKeyDown={handleKeyDown}
                />
                <br />
                <label htmlFor='defaultFormLoginPasswordEx' className='grey-text'>
                            Confirm Password
                </label>
                <input
                  type='password' id='defaultFormLoginPasswordExConfirm' className='form-control'
                  onChange={handlePasswordConfirmChange} onKeyDown={handleKeyDown}
                />
                <br />

                {(password.length > 0)
                  ? <>
                    <label className='grey-text'>Password Strength</label>
                    <PasswordStrengthBar password={password} />
                  </>
                  : ''}
                <div className='text-center mt-4'>
                  <MDBBtn rounded color='indigo' onClick={handleSaveClick}>Submit</MDBBtn>
                </div>
                <div className='text-center'>
                  {!differentPasswordError ? <div />
                    : <div className='red-text'><br />Different passwords entered. Please enter the same password.</div>}
                  {!passwordChangeSuccess ? <div />
                    : <div className='green-text'><br />{successMessage}</div>}
                  {!passwordChangeFail ? <div />
                    : <div className='red-text'><br />{errorMessage}</div>}
                </div>
              </form>
            </MDBCol>
          </MDBRow>
          <MDBRow center>
            <SweetAlert
              show={showResult}
              title='Reset Password'
              text={result.message}
              type={result.sweetalertType}
              onConfirm={() => {
                setShowResult(false)
                if (result.success) {
                  history.push('/login')
                }
              }}
            />
          </MDBRow>
        </MDBContainer>
        : <h1>error</h1>}
    </>
  )
}

export default ResetPasswordPage
