import { Document, Image, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'

const PackSlip = (props) => {
  const { shippingAddress, pickedArray, orderNo, client } = props
  const [borderColor, setBorderColor] = useState('#000')
  const [banner, setBanner] = useState('')
  const [clientName, setClientName] = useState('us')
  const [clientUrl, setClientUrl] = useState('')
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (client.name === 'White Glo') {
      setBorderColor('#C0C0C0')
      setBanner('https://public-884jd1a.s3-ap-southeast-2.amazonaws.com/tl934/whiteglo_packslip.jpg')
      setClientName('White Glo')
      setClientUrl('https://whiteglo.com')
    } else if (client.name === 'F45') {
      setBorderColor('#030147')
      setBanner('https://public-884jd1a.s3-ap-southeast-2.amazonaws.com/tl934/f45_packslip.jpg')
      setClientName('F45')
      setClientUrl('f45store.com.au')
    }

    setLoaded(true)
  }, [])

  const styles = StyleSheet.create({
    page: {},
    section: {},
    borderTop: { borderTop: true, borderTopColor: borderColor, borderTopWidth: 5 },
    table: {
      display: 'table',
      width: 'auto'
    },
    tableHead: {
      flexDirection: 'row'
    },
    tableRow: { flexDirection: 'row' },
    tableCol: {
      textAlign: 'left',
      marginTop: 5
    },
    tableCell: { padding: 0 }
  })

  if (loaded) {
    return (
      <PDFViewer width='90%' height='80%' style={{ margin: '5vw' }}>
        <Document>
          <Page size='A5' orientation='portrait' wrap={false} style={styles.page}>
            <View style={styles.borderTop}>
              <View style={{ height: '15%', marginLeft: 20 }}>
                <Text
                  style={{ color: '#333', fontSize: 40 }}
                  paragraph
                >{shippingAddress.Name ? shippingAddress.Name : shippingAddress.name}
                </Text>
                <Text style={{
                  color: '#333'
                }}
                >Phone: {shippingAddress.Phone ? shippingAddress.Phone : shippingAddress.phone}
                </Text>
                <Text style={{ color: '#333' }}>
                  {/* Order Number: */}
                                    Order No: {orderNo}
                </Text>
              </View>
              <View
                style={[styles.borderTop, styles.table, {
                  borderTopWidth: 2,
                  padding: 10,
                  height: '75%'
                }]}
              >
                <View style={styles.table}>
                  <View style={styles.tableHead}>
                    <View style={[styles.tableCol, {
                      width: '15%'
                    }]}
                    >
                      <View style={styles.tableCell}>
                        <Text>Qty</Text>
                      </View>
                    </View>
                    <View style={[styles.tableCol, { width: '85%' }]}>
                      <View style={styles.tableCell}>
                        <Text>Title</Text>
                      </View>
                    </View>
                  </View>
                </View>
                {pickedArray.map((item, i) => {
                  return (
                    <View style={styles.tableRow} key={i}>
                      <View style={[styles.tableCol, {
                        width: '15%'
                      }]}
                      >
                        <View style={styles.tableCell}>
                          <Text style={{ fontSize: 12 }}>
                            {item.qtyToFulfil}
                          </Text>
                        </View>
                      </View>
                      <View style={[styles.tableCol, { width: '85%' }]}>
                        <View style={styles.tableCell}>
                          <Text style={{ fontSize: 12 }}>
                            {item.title}
                          </Text>
                        </View>
                      </View>
                    </View>
                  )
                })}
              </View>
              <View style={{ textAlign: 'right', height: '10%' }}>
                <Image src={banner} />
                <Text style={{
                  color: '#777',
                  marginRight: '5%',
                  position: 'relative',
                  top: -30,
                  fontSize: 12
                }}
                >Thank you for ordering with {clientName}
                </Text>
                <Text style={{
                  color: '#777',
                  marginRight: '5%',
                  position: 'relative',
                  top: -30,
                  fontSize: 12
                }}
                >{clientUrl}
                </Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    )
  } else {
    return null
  }
}

export default PackSlip
