import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper/Paper'
import Table from '@material-ui/core/Table/Table'
import TableBody from '@material-ui/core/TableBody/TableBody'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import AuspostRatesRow from './AuspostRatesRow'
import { Redirect } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'

const AustpostRatesPage = (props) => {
  const reduxState = useSelector(state => state)
  const [rates, setRates] = useState([])
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [authQueryReceived, setQueryReceived] = useState(false)

  useEffect(() => {
    // Add Google Analytics
    if (reduxState.environmentVariables && reduxState.environmentVariables.public && reduxState.environmentVariables.public.analyticsTrackingId) {
      ReactGA.initialize(reduxState.environmentVariables.public.analyticsTrackingId, { debug: false })
      ReactGA.pageview(window.location.pathname + window.location.search)
    }

    window.fetch('/api/auspostRates')
      .then(res => res.json())
      .then(data => {
        setRates(data)
      })
  }, [])

  useEffect(() => {
    window.fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        setLoggedIn(data.success)
        setQueryReceived(true)
      })
  })

  if (!props.isLoggedIn && !props.isLoading) {
    return <Redirect to={{ pathname: '/login', state: { referrer: '/auspostRates' } }} />
  }

  return (
    <div>
      {props.isLoggedIn
        ? <MDBContainer>
          <MDBBreadcrumb>
            <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>AusPost Rates</MDBBreadcrumbItem>
          </MDBBreadcrumb>
          <Paper style={{ padding: 25 }} elevation={5}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Base</TableCell>
                  <TableCell>Per Kg</TableCell>
                  <TableCell>500g</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {rates.sort((a, b) => a.code > b.code).map((rate, i) => {
                  return (
                    <AuspostRatesRow rate={rate} setSnackbarOpen={setSnackbarOpen} setSnackbarMessage={setSnackbarMessage} />
                  )
                })}
              </TableBody>
            </Table>
            <Snackbar message={snackbarMessage} open={snackbarOpen} onClose={() => setSnackbarOpen(false)} />
          </Paper>
          </MDBContainer>
        : <div />}
    </div>
  )
}

export default AustpostRatesPage
