import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import LoggedInNavBar from './Components/Base/loggedInNavBar'
import LoggedOutNavBar from './Components/Base/loggedOutNavBar'
import LoginPage from './Components/Accounts/loginPage'
import EditItemPage from './Components/Items/editItemPage'
import CreateItemPage from './Components/Items/createItemPage'
import CreateShipmentsPage from './Components/Shipments/createShipmentsPage'
import ManageShipmentsPage from './Components/Shipments/manageShipmentsPage'
import ManageShipmentsContentsPage from './Components/Shipments/manageShipmentsContentsPage'
import EventsPage from './Components/Events/EventsPage'
import BundleItemPage from './Components/Items/BundleItemPage'
import OrdersPage from './Components/Orders/OrdersPage'
import OrderContentsPage from './Components/Orders/OrderContentsPage'
import NotificationsPage from './Components/Notification/NotificationsPage'
import ReportsPage from './Components/Reports/ReportsPage'
import ClientMarginsPage from './Components/Invoicing/ClientMarginsPage'
import InternationalRatesPage from './Components/Invoicing/InternationalRatesPage'
import AuspostRatesPage from './Components/Invoicing/AuspostRatesPage'
import ReturnsPage from './Components/Returns/ReturnsPage'
import InvoicingPage from './Components/Invoicing/InvoicingPage'
import ClientRatesPage from './Components/Invoicing/ClientRatesPage'
import CreateOrdersPage from './Components/Orders/CreateOrdersPage'
import ResetPasswordPage from './Components/Accounts/ResetPasswordPage'
import MasterCase from './Components/Invoicing/MasterCase'
import ForgotPasswordPage from './Components/Accounts/ForgotPasswordPage'
import EparcelShipmentsPage from './Components/eParcel/EparcelShipmentsPage'
import EparcelManifestPage from './Components/eParcel/EparcelManifestPage'
import BatchPage from './Components/Batches/BatchPage'
import ViewReturnPage from './Components/Returns/ViewReturnPage'
import { SET_ENVIRONMENT_VARIABLES } from './redux/constants/actionTypes'
import FooterPage from './Components/Base/FooterPage'

const App = () => {
  const dispatch = useDispatch()
  const [authQueryReceived, setAuthQueryReceived] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [notificationCount, setNotificationCount] = useState(0)
  const [isLoading, setisLoading] = useState(true)

  useEffect(() => {
    setisLoading(true)
    window
      .fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        console.log(data)
        setAuthQueryReceived(true)
        setIsLoggedIn(data.success)
        setisLoading(false)
      })
    window
      .fetch('/api/env/')
      .then(res => res.json())
      .then(data => {
        dispatch({ type: SET_ENVIRONMENT_VARIABLES, payload: data })
      })
  }, [])

  return (
    <BrowserRouter>
      {isLoggedIn ? (
        <LoggedInNavBar
          setIsLoggedIn={setIsLoggedIn}
          setAuthQueryReceived={setAuthQueryReceived}
          notificationCount={notificationCount}
          setNotificationCount={setNotificationCount}
        />
      ) : (
        <LoggedOutNavBar />
      )}
      <div className='App' style={{ margin: '5px 0 100px 0', height: '100%' }}>
        <Switch>
          <Route
            path='/forgot-password'
            render={() => <ForgotPasswordPage />}
          />
          <Route
            path='/reset-password/:code'
            render={props => <ResetPasswordPage {...props} />}
          />
          <Route
            path='/create-shipment'
            render={props =>
              !isLoading && (
                <CreateShipmentsPage
                  {...props}
                  setAuthQueryReceived={setAuthQueryReceived}
                  setIsLoggedIn={setIsLoggedIn}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          <Route
            path='/login'
            render={props =>
              !isLoading && (
                <LoginPage
                  {...props}
                  setAuthQueryReceived={setAuthQueryReceived}
                  setIsLoggedIn={setIsLoggedIn}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          <Route
            path='/edit-items'
            render={props =>
              !isLoading && (
                <EditItemPage
                  {...props}
                  setAuthQueryReceived={setAuthQueryReceived}
                  setIsLoggedIn={setIsLoggedIn}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          <Route
            path='/masterCase'
            render={props =>
              !isLoading && (
                <MasterCase
                  {...props}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          {/* <Route path="/item-logs" render={(props) => <ItemLogsPage {...props} authQueryReceived={authQueryReceived} isLoggedIn={isLoggedIn}/>}/> */}
          <Route
            path='/create-items'
            render={props =>
              !isLoading && (
                <CreateItemPage
                  {...props}
                  setAuthQueryReceived={setAuthQueryReceived}
                  setIsLoggedIn={setIsLoggedIn}
                />
              )
            }
          />
          <Route
            path='/bundle-items'
            render={props =>
              !isLoading && (
                <BundleItemPage
                  {...props}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          <Route
            path='/events'
            render={props =>
              !isLoading && (
                <EventsPage
                  {...props}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          <Route
            path='/invoicing'
            render={props =>
              !isLoading && (
                <InvoicingPage
                  {...props}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          <Route
            path='/auspostRates'
            render={props =>
              !isLoading && (
                <AuspostRatesPage
                  {...props}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          <Route
            path='/clientRates'
            render={props =>
              !isLoading && (
                <ClientRatesPage
                  {...props}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          <Route
            path='/internationalRates'
            render={props =>
              !isLoading && (
                <InternationalRatesPage
                  {...props}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          <Route
            path='/clientMargins'
            render={props =>
              !isLoading && (
                <ClientMarginsPage
                  {...props}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          <Route
            path='/reports'
            render={props =>
              !isLoading && (
                <ReportsPage
                  {...props}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />

          <Route
            path='/returns'
            render={props =>
              !isLoading && (
                <ReturnsPage
                  {...props}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          <Route
            path='/return/:returnId'
            render={props =>
              !isLoading && (
                <ViewReturnPage
                  {...props}
                  setAuthQueryReceived={setAuthQueryReceived}
                  setIsLoggedIn={setIsLoggedIn}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          <Route
            path='/notification'
            render={props =>
              !isLoading && (
                <NotificationsPage
                  {...props}
                  setNotificationCount={setNotificationCount}
                  notificationCount={notificationCount}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          {/* <Route path="/report-shipment" render={(props) => <ShipmentReportPage {...props} authQueryReceived={authQueryReceived} isLoggedIn={isLoggedIn}/>}/> */}
          <Route
            path='/manage-shipment'
            render={props =>
              !isLoading && (
                <ManageShipmentsPage
                  {...props}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                />
              )
            }
          />
          <Route
            path='/manage-shipment-contents/:shipmentId'
            render={props =>
              !isLoading && (
                <ManageShipmentsContentsPage
                  {...props}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          <Route
            path='/createOrders'
            render={props =>
              !isLoading && (
                <CreateOrdersPage
                  {...props}
                  setAuthQueryReceived={setAuthQueryReceived}
                  setIsLoggedIn={setIsLoggedIn}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          <Route
            path='/orders'
            render={props =>
              !isLoading && (
                <OrdersPage
                  {...props}
                  setAuthQueryReceived={setAuthQueryReceived}
                  setIsLoggedIn={setIsLoggedIn}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          <Route
            path='/order/:orderId'
            render={props =>
              !isLoading && (
                <OrderContentsPage
                  {...props}
                  setAuthQueryReceived={setAuthQueryReceived}
                  setIsLoggedIn={setIsLoggedIn}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
          <Route
            path='/home'
            render={props =>
              !isLoading && (
                <EditItemPage
                  {...props}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                />
              )
            }
          />
          <Route
            path='/batches'
            render={props =>
              !isLoading && (
                <BatchPage
                  {...props}
                  setAuthQueryReceived={setAuthQueryReceived}
                  setIsLoggedIn={setIsLoggedIn}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                />
              )
            }
          />
          <Route
            path='/eparcel-shipments'
            render={props =>
              !isLoading && (
                <EparcelShipmentsPage
                  {...props}
                  setAuthQueryReceived={setAuthQueryReceived}
                  setIsLoggedIn={setIsLoggedIn}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                />
              )
            }
          />
          <Route
            path='/eparcel-manifests'
            render={props =>
              !isLoading && (
                <EparcelManifestPage
                  {...props}
                  setAuthQueryReceived={setAuthQueryReceived}
                  setIsLoggedIn={setIsLoggedIn}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                />
              )
            }
          />
          <Route
            exact
            path='/'
            render={props =>
              !isLoading && (
                <OrdersPage
                  {...props}
                  setAuthQueryReceived={setAuthQueryReceived}
                  setIsLoggedIn={setIsLoggedIn}
                  authQueryReceived={authQueryReceived}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                />
              )
            }
          />
        </Switch>
      </div>
      <FooterPage />
    </BrowserRouter>
  )
}

export default App
