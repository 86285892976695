import React, { useEffect, useState } from 'react'
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBBtn,
  MDBContainer
} from 'mdbreact'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import ManageShipmentsContentsRowPage from './manageShipmentsContentsRow'
import Button from '@material-ui/core/Button/Button'
import { ChevronLeft } from '@material-ui/icons'
import TableFooter from '@material-ui/core/TableFooter/TableFooter'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import axios from 'axios'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import TextField from '@material-ui/core/TextField/TextField'
import { Redirect } from 'react-router-dom'

const ManageShipmentsContentsPage = (props) => {
  var today = new Date()
  var dd = String(today.getDate()).padStart(2, '0')
  var mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
  var yyyy = today.getFullYear()
  const [selectedDate, setSelectedDate] = React.useState(
    new Date(yyyy + '-' + mm + '-' + dd)
  )
  const [shipment, setShipment] = useState(null)
  const [shipmentContents, setShipmentContents] = useState([])
  const [disableButton, setDisableButton] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [cancelled, setCancelled] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [notes, setNotes] = useState('')

  const handleDateChange = date => {
    setSelectedDate(date)
  }

  useEffect(() => {
    window
      .fetch('/api/shipment/getShipmentById/' + props.match.params.shipmentId)
      .then(res => res.json())
      .then(data => {
        data.shipment.contents.sort((a, b) => {
          if (a.sku < b.sku) {
            return -1
          } else {
            return 1
          }
        })
        setShipment(data.shipment)
        setShipmentContents(data.shipment.contents)
        setNotes(data.shipment.notes)
        setCancelled(data.shipment.cancelled)
        if (data.shipment.receivedDate) {
          setDisableButton(true)
        }
      })
  }, [])

  function receiveShipment () {
    const receiveShipment = shipment
    let missingQty = false

    shipmentContents.map(row => {
      if (row.receivedQty === undefined) {
        missingQty = true
      }
    })

    if (missingQty) {
      setSnackbarOpen(true)
      setSnackbarMessage('Please fill out all received quantities')
    } else {
      receiveShipment.contents = shipmentContents
      receiveShipment.receivedDate = selectedDate
      receiveShipment.notes = notes
      axios
        .post('/api/shipment/receiveShipment', receiveShipment, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          setDisableButton(true)
        })
    }
  }

  function saveNotes () {
    axios
      .post(
        '/api/shipment/saveNotes',
        { _id: shipment._id, notes },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(res => {
        setSnackbarMessage('Notes saved')
        setSnackbarOpen(true)
      })
  }

  if (props.authQueryReceived && !props.isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            referrer:
              '/manage-shipment-contents/' + props.match.params.shipmentId
          }
        }}
      />
    )
  }

  return (
    <MDBContainer>
      <Button href='/manage-shipment'>
        <ChevronLeft /> Back
      </Button>
      <MDBBreadcrumb>
        <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
        <MDBBreadcrumbItem>Shipments</MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>
          Manage Shipments Contents -{' '}
          <strong>{shipment !== null && shipment.title}</strong>
        </MDBBreadcrumbItem>
      </MDBBreadcrumb>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Row #</TableCell>
            <TableCell>Item</TableCell>
            <TableCell>Incoming Quantity</TableCell>
            <TableCell>Received Quantity</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {shipment !== null &&
            shipment.contents.map((content, i) => {
              return (
                <ManageShipmentsContentsRowPage
                  receivedDate={shipment.receivedDate}
                  content={content}
                  shipmentId={shipment._id}
                  key={i}
                  i={i}
                  setShipmentContents={setShipmentContents}
                  shipmentContents={shipmentContents}
                  cancelled={cancelled}
                />
              )
            })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant='inline'
                  format='yyyy/MM/dd'
                  margin='normal'
                  id='date-picker-inline'
                  label='Received Date'
                  value={selectedDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />
              </MuiPickersUtilsProvider>
            </TableCell>
            <TableCell>
              <TextField
                size='medium'
                variant='outlined'
                rows={4}
                multiline
                label='Notes'
                value={notes}
                onChange={e => setNotes(e.target.value)}
              />
              <MDBBtn rounded onClick={() => saveNotes()}>
                <b>Save notes</b>
              </MDBBtn>
            </TableCell>
            <TableCell />
            <Tooltip title='You will not be able to change anything after pressing this button'>
              <TableCell align='right'>
                <MDBBtn
                  rounded
                  disabled={
                    !!(shipment && (shipment.receivedDate || cancelled))
                  }
                  onClick={() => receiveShipment()}
                >
                  <b>Finalise All</b>
                </MDBBtn>
              </TableCell>
            </Tooltip>
          </TableRow>
        </TableFooter>
      </Table>
      <Snackbar
        open={disableButton || snackbarOpen || cancelled}
        autoHideDuration={6000}
        message={
          disableButton || cancelled
            ? 'Shipment locked, you can still update notes'
            : snackbarMessage
        }
        onClose={() => setSnackbarOpen(false)}
      />
    </MDBContainer>
  )
}

export default ManageShipmentsContentsPage
