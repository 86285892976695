export const SET_CLIENT_SESSION_UUID = 'SET_CLIENT_SESSION_UUID'
export const SET_MENU_INVOICING_AUTHORIZATION = 'SET_MENU_INVOICING_AUTHORIZATION'
export const SET_MENU_REPORTS_AUTHORIZATION = 'SET_MENU_REPORTS_AUTHORIZATION'
export const SET_MENU_RETURNS_AUTHORIZATION = 'SET_MENU_RETURNS_AUTHORIZATION'
export const SET_MENU_EVENTS_AUTHORIZATION = 'SET_MENU_EVENTS_AUTHORIZATION'
export const SET_MENU_NOTIFICATIONS_AUTHORIZATION = 'SET_MENU_NOTIFICATIONS_AUTHORIZATION'
export const SET_MENU_EPARCEL_AUTHORIZATION = 'SET_MENU_EPARCEL_AUTHORIZATION'
export const SET_ENVIRONMENT_VARIABLES = 'SET_ENVIRONMENT_VARIABLES'
export const SET_USER_PREFERENCES = 'SET_USER_PREFERENCES'
export const USER_LOGOUT = 'USER_LOGOUT'
