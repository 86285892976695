import Typography from '@material-ui/core/Typography/Typography'
import TableCell from '@material-ui/core/TableCell/TableCell'
import React from 'react'

const ShopifySpecificStat = props => {
  const { order } = props
  return (
    <TableCell colSpan={2} style={{ verticalAlign: 'top' }}>
      <Typography
        variant='caption'
        style={{
          color:
            order.displayFinancialStatus === ('REFUNDED' || 'PENDING') &&
            'tomato'
        }}
      >
        Financial Status: {order.displayFinancialStatus}
      </Typography>
      <br />
      <Typography
        variant='caption'
        style={{
          color:
            order.displayFulfillmentStatus === ('FULFILLED' || 'PENDING') &&
            'tomato'
        }}
      >
        Fulfillment Status: {order.displayFulfillmentStatus}
      </Typography>
      <br />
      <Typography variant='caption'>
        Total weight: {order.totalWeight}g
      </Typography>{' '}
      <br />
      {order.totalShippingPriceSet && (
        <>
          <Typography variant='caption'>
            Shipping Cost: ${order.totalShippingPriceSet.shopMoney.amount}{' '}
            {order.totalShippingPriceSet.shopMoney.currencyCode}
          </Typography>
          <br />
        </>
      )}
      <Typography variant='caption'>
        Total Price: ${order.totalPrice} {order.currencyCode}
      </Typography>
      <br />
      <Typography variant='caption'>
        Test order: {order.test ? 'TRUE' : 'FALSE'}
      </Typography>
      <br />
      <Typography variant='caption'>
        Customer repeat order count:{' '}
        {order.customer && order.customer.ordersCount}
      </Typography>
      {order.shippingLine && (
        <>
          <br />
          <Typography
            variant='caption'
            style={{
              color:
                (order.shippingLine.title.includes('xpress') ||
                  order.shippingLine.title.includes('XPRESS')) &&
                'tomato'
            }}
          >
            Shipping Type: {order.shippingLine.title}{' '}
          </Typography>
          <br />
        </>
      )}
    </TableCell>
  )
}

export default ShopifySpecificStat
