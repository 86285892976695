import TextField from '@material-ui/core/TextField/TextField'
import Paper from '@material-ui/core/Paper/Paper'
import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Button from '@material-ui/core/Button/Button'
import axios from 'axios'

const ClientRatesPickModal = (props) => {
  const { stores, client, setSnackbarOpen, setSnackbarMessage, setCreatePickModal, forceUpdate, setForceUpdate } = props
  const [store, setStore] = useState(stores[0])
  const [tag, setTag] = useState('')
  const [threshold, setThreshold] = useState(0)
  const [underThreshold, setUnderThreshold] = useState(0)
  const [overThreshold, setOverThreshold] = useState(0)

  function saveRow () {
    axios.post('/api/invoiceRates/createRatePicking', {
      body: JSON.stringify({
        clientId: client._id,
        threshold,
        underThreshold,
        overThreshold,
        tag,
        storeId: store._id
      })
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.data.success) {
          setSnackbarMessage('Created picking')
          setCreatePickModal(false)
          setForceUpdate(!forceUpdate)
        } else {
          setSnackbarMessage('Failed')
        }
        setSnackbarOpen(true)
      })
  }

  return (
    <Paper style={{ padding: 25, maxHeight: '80vh', maxWidth: '20vw', marginLeft: '40vw', marginTop: '10vh' }}>
      <Grid container direction='column' spacing={3}>
        <Grid item>
          <InputLabel id='client-label' shrink>Store</InputLabel>
          <Select labelId='client-label' value={store} onChange={e => setStore(e.target.value)}>
            {stores.sort((a, b) => a.name > b.name).map((row, i) => {
              return (
                <MenuItem key={i} value={row}>{row.name}</MenuItem>
              )
            })}
          </Select>
        </Grid>
        <Grid item>
          <TextField label='Item Type' fullwidth onChange={e => setTag(e.target.value)} />
        </Grid>
        <Grid item>
          <TextField label='Threshold' fullwidth onChange={e => setThreshold(e.target.value)} type='number' />
        </Grid>
        <Grid item>
          <TextField label='Cost under threshold' fullwidth onChange={e => setUnderThreshold(e.target.value)} type='number' />
        </Grid>
        <Grid item>
          <TextField label='Cost over threshold' fullwidth onChange={e => setOverThreshold(e.target.value)} type='number' />
        </Grid>
        <Grid item>
          <Grid container justify='flex-end' spacing={3}>
            <Grid item><Button variant='contained' color='primary' onClick={() => saveRow()}>Save</Button></Grid>
            <Grid item><Button variant='contained' onClick={() => setCreatePickModal(false)}>Cancel</Button></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ClientRatesPickModal
