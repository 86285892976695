import React from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import { CSVLink } from 'react-csv'

const CreateOrdersBulk = (props) => {
  const { setFileName, setFileData, fileName } = props

  const csvData = [
    ['qty', 'email', 'firstName', 'lastName', 'company', 'address1', 'address2', 'suburb', 'state', 'postcode', 'country', 'phone', 'notes'],
    ['qty of orders', 'asdf@gmail.com', 'firstName', 'lastName', 'company', 'address1', 'address2', 'suburb', 'state', 'postcode', 'country', 'phone', '']
  ]

  function handleFileUpload (e) {
    const data = new FormData()
    const file = e.target.files[0]

    data.append('file', e.target.files[0])
    if (file) {
      setFileName(e.target.files[0].name)
      setFileData(file)
    }
  }

  return (
    <Grid container spacing={5}>
      <Grid item>
        <CSVLink data={csvData} filename='createOrders.csv'>Download template</CSVLink>
      </Grid>
      <Grid item>
        <div className='input-group'>
          <div className='input-group-prepend'>
            <span className='input-group-text' id='inputGroupFileAddon01'>
                                                Upload
            </span>
          </div>
          <div className='custom-file'>
            <input
              type='file'
              className='custom-file-input'
              id='inputGroupFile01'
              aria-describedby='inputGroupFileAddon01'
              onChange={e => handleFileUpload(e)}
            />
            <label className='custom-file-label' htmlFor='inputGroupFile01'>
              {fileName !== '' ? fileName : 'Choose file'}
            </label>
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default CreateOrdersBulk
