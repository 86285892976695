import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper/Paper'
import Table from '@material-ui/core/Table/Table'
import TableBody from '@material-ui/core/TableBody/TableBody'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import { Redirect } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import ClientMarginsRow from './ClientMarginsRow'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import ClientMarginsRowInternational from './ClientMarginsRowInternational'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'

const ClientMarginsPage = (props) => {
  const reduxState = useSelector(state => state)
  const [rates, setRates] = useState({ margins: [] })
  const [clientList, setClientList] = useState([])
  const [client, setClient] = useState({})
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [domestic, setLocal] = useState(true)

  useEffect(() => {
    if (reduxState.environmentVariables && reduxState.environmentVariables.public && reduxState.environmentVariables.public.analyticsTrackingId) {
      ReactGA.initialize(reduxState.environmentVariables.public.analyticsTrackingId, { debug: false })
      ReactGA.pageview(window.location.pathname + window.location.search)
    }

    window.fetch('/api/client/getClients')
      .then(res => res.json())
      .then(data => {
        setClientList(data.clients)
        setClient(data.clients[0])
      })
  }, [])

  useEffect(() => {
    window.fetch('/api/clientInvoiceMultiplier/' + client._id)
      .then(res => res.json())
      .then(data => {
        setRates(null)
        setTimeout(function () {
          setRates(data)
        }, 10)
      })
  }, [client])

  if (!props.isLoggedIn && !props.isLoading) {
    return <Redirect to={{ pathname: '/login', state: { referrer: '/clientMargins' } }} />
  }

  return (
    <div>
      {props.isLoggedIn
        ? <MDBContainer>
          <MDBBreadcrumb>
            <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>Client Multipliers</MDBBreadcrumbItem>
          </MDBBreadcrumb>
          <Paper style={{ padding: 25 }} elevation={5}>
            <Grid container alignContent='space-between' alignItems='flex-end' justify='space-between'>
              <Grid item>
                <InputLabel id='client-label' shrink>Client</InputLabel>
                <Select labelId='client-label' value={client} onChange={e => setClient(e.target.value)}>
                  {clientList.sort((a, b) => a.name > b.name).map((row, i) => {
                    return (
                      <MenuItem key={i} value={row}>{row.name}</MenuItem>
                    )
                  })}
                </Select>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={domestic}
                      onChange={() => setLocal(!domestic)}
                      name='checkedB'
                      color='primary'
                    />
                  }
                  label={domestic ? 'Domestic' : 'International'}
                />
              </Grid>
            </Grid>
            <Table>
              {domestic
                ? <>
                  <TableHead>
                    <TableRow>
                      <TableCell>Code</TableCell>
                      <TableCell>Multiplier</TableCell>
                      <TableCell>Multiplier under 500g</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rates !== null && rates.margins.map((rate, i) => {
                      if (!/^-?\d+$/.test(rate.code)) {
                        return (
                          <ClientMarginsRow
                            key={i} rate={rate} id={rates._id}
                            setSnackbarOpen={setSnackbarOpen}
                            setSnackbarMessage={setSnackbarMessage}
                          />
                        )
                      } else {
                        return ''
                      }
                    })}
                  </TableBody>
                  </>
                : <>
                  <TableHead>
                    <TableRow>
                      <TableCell>Code</TableCell>
                      <TableCell>Multiplier</TableCell>
                      <TableCell>Multiplier under 500g</TableCell>
                      <TableCell>Multiplier 1kg</TableCell>
                      <TableCell>Multiplier 2kg</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rates !== null && rates.margins.map((rate, i) => {
                      if (/^-?\d+$/.test(rate.code)) {
                        return (
                          <ClientMarginsRowInternational
                            key={i} rate={rate} id={rates._id}
                            setSnackbarOpen={setSnackbarOpen}
                            setSnackbarMessage={setSnackbarMessage}
                          />
                        )
                      }
                    })}
                  </TableBody>
                </>}
            </Table>
            <Snackbar message={snackbarMessage} open={snackbarOpen} onClose={() => setSnackbarOpen(false)} />
          </Paper>
        </MDBContainer> : <div />}
    </div>
  )
}

export default ClientMarginsPage
