import React, { useEffect, useState } from 'react'
import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer } from 'mdbreact'
import 'date-fns'
import '../../index.css'
import Paper from '@material-ui/core/Paper/Paper'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import axios from 'axios'
import { CSVLink } from 'react-csv'
import Grid from '@material-ui/core/Grid/Grid'
import Button from '@material-ui/core/Button/Button'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import MUIDataTable from 'mui-datatables'

const ShipmentReportPage = props => {
  var start = new Date()
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  var end = new Date()
  end.setHours(23)
  end.setMinutes(59)
  const [shipments, setShipments] = useState([])
  const [startDate, setStartDate] = useState(start)
  const [endDate, setEndDate] = useState(end)
  const [clientMap, setClientMap] = useState([])
  const [client, setClient] = useState({})

  useEffect(() => {
    window
      .fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          window
            .fetch('/api/client/getClients')
            .then(res => res.json())
            .then(data => {
              setClientMap(data.clients)
              setClient(data.clients[0])
              // handleChangeClient(data.clients[0])
            })
        }
      })
  }, [])
  useEffect(() => {
    getShipmentReport('from', startDate)
  }, [client])

  useEffect(() => {
    getShipmentReport()
  }, [startDate, endDate])

  function handleDateChange (fromOrTo, e) {
    if (fromOrTo === 'from') {
      setStartDate(e)
    } else {
      setEndDate(e)
    }
  }

  function getShipmentReport () {
    axios
      .get(
        '/api/shipment/shipmentReport/' +
          startDate +
          '/' +
          endDate +
          '/' +
          client._id
      )
      .then(res => {
        parseShipments(res.data.shipments)
      })
  }

  function parseShipments (shipments) {
    const myShipments = []
    shipments.map(shipment => {
      shipment.contents.map(row => {
        const myObj = {
          date: new Date(shipment.receivedDate).toLocaleDateString('en-GB'),
          sku: row.sku,
          qty: row.receivedQty
        }
        myShipments.push(myObj)
      })
    })
    setShipments(myShipments)
  }

  const columns = [
    { name: 'date', label: 'Received Date' },
    { name: 'sku', label: 'SKU' },
    { name: 'qty', label: 'Quantity Received' }
  ]

  return (
    <MDBContainer>
      <MDBBreadcrumb>
        <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>Shipment Report</MDBBreadcrumbItem>
      </MDBBreadcrumb>
      <Paper style={{ padding: 20 }}>
        <Grid
          container
          justify='space-between'
          alignItems='center'
          alignContent='flex-end'
        >
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                format='yyyy-MM-dd'
                margin='normal'
                id='from-date'
                label='From:'
                onChange={e => handleDateChange('from', e)}
                value={startDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                variant='inline'
                style={{ margin: 10 }}
              />
              <KeyboardDatePicker
                format='yyyy-MM-dd'
                margin='normal'
                id='to-date'
                label='To:'
                onChange={e => handleDateChange('to', e)}
                value={endDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                variant='inline'
                style={{ margin: 10 }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <InputLabel id='client-label' shrink>
              Client
            </InputLabel>
            <Select
              labelId='client-label'
              value={client}
              onChange={e => setClient(e.target.value)}
            >
              {clientMap.map((row, i) => {
                return (
                  <MenuItem key={i} value={row}>
                    {row.name}
                  </MenuItem>
                )
              })}
            </Select>
          </Grid>
          <Grid item>
            <Button variant='contained' color='primary'>
              <CSVLink
                data={shipments}
                filename={
                  'shipmentReports-' +
                  client.name +
                  '-' +
                  startDate.toLocaleDateString('en-GB') +
                  '-' +
                  endDate.toLocaleDateString('en-GB') +
                  '.csv'
                }
                style={{ color: 'white' }}
              >
                Download Report
              </CSVLink>
            </Button>
          </Grid>
        </Grid>

        <MUIDataTable
          // title={"Shipment Report"}
          data={shipments}
          columns={columns}
          options={{
            download: false,
            isSelectable: false,
            elevation: 0,
            selectableRows: 'none',
            selectableRowsHeader: false
          }}
        />
      </Paper>
    </MDBContainer>
  )
}

export default ShipmentReportPage
