import { MDBContainer } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper/Paper'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import FormControl from '@material-ui/core/FormControl/FormControl'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import axios from 'axios'
import Button from '@material-ui/core/Button/Button'
import { CSVLink } from 'react-csv'
import { Redirect } from 'react-router-dom'
import Grid from '@material-ui/core/Grid/Grid'
import DateFnsUtils from '@date-io/date-fns'
import 'date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import MUIDataTable from 'mui-datatables'

const ItemLogsPage = props => {
  var start = new Date()
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  var end = new Date()
  end.setHours(23)
  end.setMinutes(59)
  const [startDate, setStartDate] = useState(start)
  const [endDate, setEndDate] = useState(end)
  const [clientMap, setClientMap] = useState([])
  const [client, setClient] = useState({})
  const [logs, setLogs] = useState([])
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [authQueryReceived, setQueryReceived] = useState(false)

  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  }))
  const classes = useStyles()

  useEffect(() => {
    window
      .fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        setLoggedIn(data.success)
        setQueryReceived(true)
        // this.setState({ authQueryReceived: true, isLoggedIn: data.success })
        if (data.success) {
          window
            .fetch('/api/client/getClients')
            .then(res => res.json())
            .then(data => {
              setClientMap(data.clients)
              setClient(data.clients[0])
              handleChangeClient(data.clients[0])
            })
        }
      })
  }, [])

  function handleDateChange (fromOrTo, e) {
    if (fromOrTo === 'from') {
      setStartDate(e)
    } else {
      setEndDate(e)
    }

    handleChangeClient(client, fromOrTo, e)
  }

  function handleChangeClient (value, fromOrTo, e) {
    setClient(value)
    axios.get('/api/item/getClientInv/' + value._id).then(res => {
      const logMap = []
      res.data.inventory.map((row, i) => {
        row.logs.map((log, i) => {
          const updatedAt = new Date(log.updatedAt)

          if (fromOrTo === 'from') {
            if (updatedAt > e && updatedAt < endDate) {
              logMap.push(parseLog(log, row))
            }
          } else {
            if (updatedAt > startDate && updatedAt < endDate) {
              logMap.push(parseLog(log, row))
            }
          }
        })
      })
      setLogs(logMap)
    })
  }

  function parseLog (log, row) {
    delete log._id
    log.sku = row.sku
    log.updatedAt = new Date(log.updatedAt).toLocaleString('en-GB')
    return log
  }

  const columns = [
    { name: 'sku', label: 'SKU' },
    { name: 'reason', label: 'Reason' },
    { name: 'adjustedQty', label: 'Adjusted Quantity' },
    { name: 'oldQuantity', label: 'Old Quantity' },
    { name: 'newQuantity', label: 'New Quantity' },
    { name: 'oldDisplayQuantity', label: 'Old Display Quantity' },
    { name: 'newDisplayQuantity', label: 'New Display Quantity' },
    { name: 'updatedAt', label: 'Updated At' }
  ]

  return (
    <MDBContainer>
      {authQueryReceived ? (
        <div>
          {isLoggedIn ? (
            <div />
          ) : (
            <Redirect
              to={{ pathname: '/login', state: { referrer: '/reports' } }}
            />
          )}
          <Paper elevation={10} style={{ padding: 25, margin: 25 }}>
            <Grid
              container
              alignContent='space-between'
              justify='space-between'
              alignItems='stretch'
            >
              <Grid item>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    format='yyyy-MM-dd'
                    margin='normal'
                    id='from-date'
                    label='From:'
                    onChange={e => handleDateChange('from', e)}
                    value={startDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    variant='inline'
                    style={{ margin: 10 }}
                  />
                  <KeyboardDatePicker
                    format='yyyy-MM-dd'
                    margin='normal'
                    id='to-date'
                    label='To:'
                    onChange={e => handleDateChange('to', e)}
                    value={endDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    variant='inline'
                    style={{ margin: 10 }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl}>
                  <InputLabel id='demo-simple-select-label'>Client</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={client}
                    onChange={e => handleChangeClient(e.target.value)}
                    style={{ marginTop: 15 }}
                  >
                    {clientMap.map((row, i) => {
                      return (
                        <MenuItem key={i} value={row}>
                          {row.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button variant='contained' color='primary'>
                  <CSVLink
                    filename={
                      client.name +
                      '-' +
                      new Date().toLocaleDateString('en-GB') +
                      '-logs.csv'
                    }
                    data={logs}
                    style={{ color: 'white' }}
                  >
                    Download Log
                  </CSVLink>
                </Button>
              </Grid>
            </Grid>

            <MUIDataTable
              title='Logs'
              data={logs}
              columns={columns}
              options={{
                download: false,
                isSelectable: false,
                elevation: 0,
                selectableRows: 'none',
                selectableRowsHeader: false
              }}
            />
          </Paper>
        </div>
      ) : (
        <div />
      )}
    </MDBContainer>
  )
}

export default ItemLogsPage
