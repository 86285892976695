import React, { useEffect, useState } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup/FormGroup'
import Button from '@material-ui/core/Button/Button'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableRow from '@material-ui/core/TableRow/TableRow'
import axios from 'axios'
import Typography from '@material-ui/core/Typography/Typography'
import { useHistory } from 'react-router'

const EditReturnsRow = props => {
  const { row } = props

  const history = useHistory()
  useEffect(() => {}, [])

  return (
    <TableRow>
      <TableCell>{row.storeNameStr}</TableCell>
      <TableCell>{row.orderData.orderName}</TableCell>
      <TableCell>
        <Typography noWrap style={{ overflow: 'hidden', maxWidth: 200 }}>
          {row.reason}
        </Typography>
      </TableCell>
      <TableCell>{row.solution || 'None'}</TableCell>
      <TableCell>
        {row.resolved ? (
          <Typography style={{ color: 'green' }}>Resolved</Typography>
        ) : (
          <Typography style={{ color: 'tomato' }}>Not Resolved</Typography>
        )}
      </TableCell>
      <TableCell>
        <Button
          variant='contained'
          color='primary'
          onClick={() => history.push('/return/' + row._id)}
        >
          View
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default EditReturnsRow
