import React, { useEffect, useState } from 'react'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import Button from '@material-ui/core/Button/Button'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import axios from 'axios'
import CancelIcon from '@material-ui/icons/Cancel'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import Typography from '@material-ui/core/Typography/Typography'
import Link from '@material-ui/core/Link'

const ManageShipmentsRows = (props) => {
  const {
    shipment,
    clientArray,
    setSnackbarOpen,
    setSnackbarMessage,
    activeShipmentsOnly
  } = props
  const [totalCount, setTotalCount] = useState(0)
  const [selectedDate, setSelectedDate] = React.useState(new Date(shipment.eta))
  const [cancelled, setCancelled] = useState(shipment.cancelled)
  const myCount = 0
  // shipment.contents.forEach(a => {
  //     myCount += parseInt(a.incomingQty);
  // });

  useEffect(() => {
    if (totalCount === 0) {
      setTotalCount(myCount)
    }
  })

  function returnClientName (clientId) {
    const result = clientArray.find(client => {
      return client[clientId]
    })
    if (result) {
      return result[clientId]
    }
  }

  const handleDateChange = date => {
    setSelectedDate(date)
    axios
      .post(
        '/api/shipment/updateEta',
        {
          _id: shipment._id,
          eta: date
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(res => {
        setSnackbarOpen(true)
        setSnackbarMessage('Updated ETA')
      })
  }

  const cancelShipment = () => {
    axios
      .post(
        '/api/shipment/cancelShipment',
        {
          _id: shipment._id,
          cancelled: true
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(res => {
        setSnackbarOpen(true)
        setSnackbarMessage('Cancelled shipment')
        setCancelled(true)
      })
  }

  return (
    <TableRow
      hidden={activeShipmentsOnly && (cancelled || shipment.receivedDate)}
    >
      <TableCell>
        {cancelled ? (
          <Typography style={{ color: 'tomato' }}>Cancelled</Typography>
        ) : (
          <Typography style={{ color: !!shipment.receivedDate && 'limegreen' }}>
            {shipment.receivedDate ? 'Completed' : 'Waiting'}
          </Typography>
        )}
      </TableCell>
      <TableCell>{shipment.title}</TableCell>
      <TableCell>{shipment.asn}</TableCell>
      <TableCell>{returnClientName(shipment.clientId)}</TableCell>
      {shipment.receivedDate ? (
        <TableCell>{shipment.eta.substr(0, 10)}</TableCell>
      ) : (
        <TableCell>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant='inline'
              format='yyyy/MM/dd'
              margin='normal'
              id='date-picker-inline'
              label='ETA'
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </MuiPickersUtilsProvider>
        </TableCell>
      )}
      {/* <TableCell>{shipment.contents.length}</TableCell> */}
      {/* <TableCell>{totalCount}</TableCell> */}
      <TableCell hidden={activeShipmentsOnly}>
        {shipment.receivedDate &&
          shipment.receivedDate.toString().substr(0, 10)}
      </TableCell>
      <TableCell>
        <Link href={'/manage-shipment-contents/' + shipment._id}>Contents</Link>
      </TableCell>
      <TableCell>
        <Tooltip title='Cancel shipment'>
          <Button
            disabled={cancelled || shipment.receivedDate}
            onClick={() => cancelShipment()}
          >
            <CancelIcon />
          </Button>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default ManageShipmentsRows
