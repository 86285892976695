import React, { useEffect, useState } from 'react'
import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer } from 'mdbreact'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import ManageShipmentsRows from './manageShipmentsRows'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import Switch from '@material-ui/core/Switch/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'
import { Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'

const ManageShipmentsPage = (props) => {
  const reduxState = useSelector(state => state)
  const [shipments, setShipments] = useState([])
  const [clientArray, setClientArray] = useState([])
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [activeShipmentsOnly, setActiveShipmentsOnly] = useState(true)
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [authQueryReceived, setQueryReceived] = useState(false)

  useEffect(() => {
    if (
      reduxState.environmentVariables &&
      reduxState.environmentVariables.public &&
      reduxState.environmentVariables.public.analyticsTrackingId
    ) {
      ReactGA.initialize(
        reduxState.environmentVariables.public.analyticsTrackingId,
        { debug: false }
      )
      ReactGA.pageview(window.location.pathname + window.location.search)
    }

    window
      .fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        setLoggedIn(data.success)
        setQueryReceived(true)
      })
    window
      .fetch('/api/shipment')
      .then(res => res.json())
      .then(data => {
        setShipments(data.shipment)
      })
    window
      .fetch('/api/client/clientNameArray')
      .then(res => res.json())
      .then(data => {
        setClientArray(data.clientNameArray)
      })
  }, [])

  if (!props.isLoggedIn && !props.isLoading) {
    return (
      <Redirect
        to={{ pathname: '/login', state: { referrer: '/manage-shipment' } }}
      />
    )
  }

  return (
    <div>
      {authQueryReceived && isLoggedIn ? (
        <MDBContainer>
          <MDBBreadcrumb>
            <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
            <MDBBreadcrumbItem>Shipments</MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>Manage Shipments</MDBBreadcrumbItem>
          </MDBBreadcrumb>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Shipment Status</TableCell>
                <TableCell>Shipment Title</TableCell>
                <TableCell>ASN</TableCell>
                <TableCell>Client</TableCell>
                <TableCell>ETA</TableCell>
                {/* <TableCell>Unique SKUs</TableCell> */}
                {/* <TableCell>Total Quantity</TableCell> */}
                <TableCell hidden={activeShipmentsOnly}>
                  Received Date
                </TableCell>
                <TableCell />
                <TableCell>
                  <Tooltip title='Hide fulfilled/cancelled shipments'>
                    <FormControlLabel
                      label={activeShipmentsOnly ? 'Hidden' : 'Showing'}
                      labelPlacement='start'
                      control={
                        <Switch
                          checked={activeShipmentsOnly}
                          onChange={() =>
                            setActiveShipmentsOnly(!activeShipmentsOnly)}
                          name='Hide cancel/completed'
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      }
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shipments.map((shipment, i) => {
                return (
                  <ManageShipmentsRows
                    key={i}
                    shipment={shipment}
                    clientArray={clientArray}
                    setSnackbarOpen={setSnackbarOpen}
                    activeShipmentsOnly={activeShipmentsOnly}
                    setSnackbarMessage={setSnackbarMessage}
                  />
                )
              })}
            </TableBody>
          </Table>
          <Snackbar
            onClose={() => setSnackbarOpen(false)}
            open={snackbarOpen}
            message={snackbarMessage}
          />
        </MDBContainer>
      ) : (
        <div />
      )}
    </div>
  )
}

export default ManageShipmentsPage
