import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import Paper from '@material-ui/core/Paper/Paper'
import Grid from '@material-ui/core/Grid/Grid'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Typography from '@material-ui/core/Typography/Typography'
import TextField from '@material-ui/core/TextField/TextField'
import Divider from '@material-ui/core/Divider/Divider'
import CreateOrderLineItemRow from './CreateOrderLineItemRow'
import Button from '@material-ui/core/Button/Button'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { ObjectID } from 'bson'
import axios from 'axios'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import CreateOrdersSingle from './CreateOrdersSingle'
import CreateOrdersBulk from './CreateOrdersBulk'

const CreateOrdersPage = (props) => {
  const reduxState = useSelector(state => state)
  const [countries, setCountries] = useState([])
  const [client, setClient] = useState({})
  const [orderName, setOrderName] = useState('')
  const [notes, setNotes] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [company, setCompany] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [suburb, setSuburb] = useState('')
  const [state, setState] = useState('')
  const [postcode, setPostcode] = useState('')
  const [country, setCountry] = useState('')
  const [country_code, setCountryCode] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [clientList, setClientList] = useState([])
  const [lineItems, setLineItems] = useState([])
  const [lineItemRows, setLineItemRows] = useState([{
    _id: new ObjectID(),
    rowId: 0,
    itemId: '',
    qty: 1,
    sku: ''
  }])
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [isBulk, setIsBulk] = useState(false)
  const [fileName, setFileName] = useState('')
  const [fileData, setFileData] = useState('')

  useEffect(() => {
    if (reduxState.environmentVariables && reduxState.environmentVariables.public && reduxState.environmentVariables.public.analyticsTrackingId) {
      ReactGA.initialize(reduxState.environmentVariables.public.analyticsTrackingId, { debug: false })
      ReactGA.pageview(window.location.pathname + window.location.search)
    }

    window.fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        props.setAuthQueryReceived(true)
        props.setIsLoggedIn(data.success)
      })
    window.fetch('/api/client/getClients')
      .then(res => res.json())
      .then(data => {
        setClientList(data.clients)
        setClient(data.clients[0])
      })
    window.fetch('/api/countries')
      .then(res => res.json())
      .then(data => {
        setCountries(data.countries)
      })
  }, [])

  useEffect(() => {
    window.fetch('/api/item/getClientInv/' + client._id)
      .then(res => res.json())
      .then(data => {
        setLineItems(data.inventory)
      })
  }, [client])

  // Must include this code block to redirect user to the login page if user is not logged in.
  // Otherwise if the session has timed out and the user access this page it will appear as loading indefinitely.
  // This code block must be placed immediately after the userEffect() blocks.
  if (!props.isLoggedIn && !props.isLoading) {
    return <Redirect to={{ pathname: '/login', state: { referrer: '/createOrders' } }} />
  }

  function addRow () {
    setLineItemRows([...lineItemRows, {
      _id: new ObjectID(),
      rowId: lineItemRows.length,
      itemId: '',
      qty: 1,
      sku: ''
    }])
  }

  function removeRow () {
    lineItemRows.splice(-1, 1)
    setLineItemRows([...lineItemRows])
  }

  function updateLineItemRows (value, i, key) {
    const lineItemRow = lineItemRows.find(a => a.rowId === i)
    const myLineItemRows = lineItemRows
    const index = myLineItemRows.indexOf(lineItemRow)
    if (key === 'item') {
      lineItemRow.itemId = value._id
      lineItemRow.sku = value.sku
    } else if (key === 'qty') {
      lineItemRow.qty = parseInt(value.trim())
    } else {
      lineItemRow[key] = value
    }
    myLineItemRows.splice(index, 1)
    myLineItemRows.push(lineItemRow)
    setLineItemRows(myLineItemRows)
  }

  function createOrder (redirect) {
    if (isBulk) {
      const myFormData = new FormData()
      myFormData.append('file', fileData)
      myFormData.append('clientId', client._id)
      myFormData.append('orderName', orderName)
      myFormData.append('lineItems', JSON.stringify(lineItemRows))
      axios.post('/api/order/createOrderBulk', myFormData,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          if (res.data.success) {
            setSnackbarMessage('Created Orders')
            setSnackbarOpen(true)
            if (redirect) {
              props.history.push('/orders/')
            }
          } else {
            setSnackbarMessage('Failed to create Orders')
            setSnackbarOpen(true)
          }
        })
        .catch(err => {
          setSnackbarMessage('Failed to create Orders')
          setSnackbarOpen(true)
        })
    } else {
      axios.post('/api/order/createOrder', {
        body: JSON.stringify({
          clientId: client._id,
          orderName,
          notes,
          shippingAddress: {
            firstName,
            lastName,
            company,
            address1,
            address2,
            suburb,
            state,
            postcode,
            country_code: country_code,
            country,
            phone
          },
          email,
          lineItems: lineItemRows
        })
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          if (res.data.success) {
            setSnackbarMessage('Created Order')
            setSnackbarOpen(true)
            if (redirect) {
              props.history.push('/order/' + res.data.id)
            }
          } else {
            setSnackbarMessage('Failed to create Order')
            setSnackbarOpen(true)
          }
        })
        .catch(err => {
          setSnackbarMessage('Failed to create Order')
          setSnackbarOpen(true)
        })
    }
  }

  return (
    <div>

      {props.authQueryReceived && props.isLoggedIn ? <MDBContainer>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
          <MDBBreadcrumbItem>Orders</MDBBreadcrumbItem>
          <MDBBreadcrumbItem active>Create Order</MDBBreadcrumbItem>
        </MDBBreadcrumb>
        <Paper style={{ padding: 25 }}>
          <Grid container spacing={5} alignContent='flex-start' alignItems='flex-end'>
            <Grid item>
              <InputLabel id='client-label' shrink>Client</InputLabel>
              <Select labelId='client-label' value={client} onChange={e => setClient(e.target.value)}>
                {clientList.sort((a, b) => a.name > b.name).map((row, i) => {
                  return (
                    <MenuItem key={i} value={row}>{row.name}</MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item>
              <TextField
                label='Order Name' value={orderName}
                // helperText="optional"
                onChange={e => setOrderName(e.target.value)}
              />
            </Grid>
            {!isBulk &&
              <Grid item>
                <TextField label='Notes' value={notes} onChange={e => setNotes(e.target.value)} />
              </Grid>}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item lg={6}>
                  <Typography variant='h5'>Items</Typography>
                  <Grid container direction='column' spacing={5}>
                    {lineItemRows.map((row, i) => {
                      return (
                        <CreateOrderLineItemRow
                          lineItems={lineItems}
                          updateLineItemRows={updateLineItemRows}
                          key={i} i={i} setSnackbarOpen={setSnackbarOpen} setSnackbarMessage={setSnackbarMessage}
                        />
                      )
                    })}
                    <Grid item>
                      <Button onClick={() => addRow()}><AddIcon
                        color='primary'
                                                       />
                      </Button>
                      {lineItemRows.length > 1 &&
                        <Button onClick={() => removeRow()}><RemoveIcon
                          color='primary'
                                                            />
                        </Button>}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={6} style={{ borderLeft: '1px solid #e3e3e3', paddingLeft: 10 }}>
                  <Button onClick={() => setIsBulk(!isBulk)} style={{ float: 'right' }}>Toggle Bulk vs
                                        single
                  </Button>
                  {isBulk
                    ? <CreateOrdersBulk
                      fileName={fileName} setFileName={setFileName}
                      setFileData={setFileData}
                      />
                    : <CreateOrdersSingle
                      countries={countries} setCountries={setCountries}
                      firstName={firstName} setFirstName={setFirstName}
                      lastName={lastName}
                      setLastName={setLastName} company={company}
                      setCompany={setCompany} address1={address1}
                      setAddress1={setAddress1} address2={address2}
                      setAddress2={setAddress2} suburb={suburb}
                      setSuburb={setSuburb}
                      state={state} setState={setState} postcode={postcode}
                      setPostcode={setPostcode}
                      country={country} setCountry={setCountry}
                      country_code={country_code} setCountryCode={setCountryCode}
                      phone={phone} setPhone={setPhone}
                      email={email} setEmail={setEmail}
                      />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} justify='flex-end' alignContent='flex-end' alignItems='flex-end'>
            <Grid item>
              <Button variant='contained' onClick={() => createOrder(false)}>Create</Button>
            </Grid>
            <Grid item>
              <Button variant='contained' color='primary' onClick={() => createOrder(true)}>Create &
                                Manage
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Snackbar message={snackbarMessage} onClose={() => setSnackbarOpen(false)} open={snackbarOpen} />
      </MDBContainer> : <div />}
    </div>

  )
}

export default CreateOrdersPage
