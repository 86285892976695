export function isEmpty (obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) { return false }
  }
  return true
}

export function isUserAuthorisedForMenuItem (reactComponentAuthorization, menuItem) {
  if (reactComponentAuthorization.reactComponentsAuthorization) {
    if (reactComponentAuthorization.reactComponentsAuthorization.denied) {
      if (reactComponentAuthorization.reactComponentsAuthorization.denied.menu) {
        for (let i = 0; i < reactComponentAuthorization.reactComponentsAuthorization.denied.menu.length; i++) {
          const menu = reactComponentAuthorization.reactComponentsAuthorization.denied.menu[i]
          if (menu.toLowerCase() === menuItem.toLowerCase()) {
            return false
          }
        }
        return true
      }
    } else {
      return true
    }
  } else {
    return false
  }
}
