import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer } from 'mdbreact'
import Paper from '@material-ui/core/Paper/Paper'
import React, { useEffect, useState } from 'react'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import Table from '@material-ui/core/Table/Table'
import CheckIcon from '@material-ui/icons/Check'
import Button from '@material-ui/core/Button/Button'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'

const NotificationsPage = (props) => {
  const reduxState = useSelector(state => state)
  const [notifications, setNotification] = useState([])
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const { setNotificationCount, notificationCount } = props
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [authQueryReceived, setQueryReceived] = useState(false)

  useEffect(() => {
    // Add Google Analytics
    if (reduxState.environmentVariables && reduxState.environmentVariables.public && reduxState.environmentVariables.public.analyticsTrackingId) {
      ReactGA.initialize(reduxState.environmentVariables.public.analyticsTrackingId, { debug: false })
      ReactGA.pageview(window.location.pathname + window.location.search)
    }

    window.fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        setLoggedIn(data.success)
        setQueryReceived(true)
      })

    window.fetch('/api/notification')
      .then(res => res.json())
      .then(data => {
        setNotification(data.notification)
      })
  }, [])

  // Must include this code block to redirect user to the login page if user is not logged in.
  // Otherwise if the session has timed out and the user access this page it will appear as loading indefinitely.
  // This code block must be placed immediately after the userEffect() blocks.
  if (!props.isLoggedIn && !props.isLoading) {
    return <Redirect to={{ pathname: '/login', state: { referrer: '/notification' } }} />
  }

  function acknowledgeNotification (id) {
    axios.post('/api/notification/acknowledge', {
      body: JSON.stringify({
        id
      })
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => { })
      .then(data => {
        setSnackbarMessage('Acknowledged')
        setSnackbarOpen(true)
        const row = notifications.find(a => a._id.toString() === id)
        row.acknowledged = true
        setNotification([...notifications, row])
        setNotificationCount(notificationCount - 1)
      }
      )
  }

  function acknowledgeAll () {
    axios.post('/api/notification/acknowledgeAll', {},
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => { })
      .then(data => {
        setSnackbarMessage('Acknowledged All')
        setSnackbarOpen(true)
        const myArray = []
        notifications.map(a => {
          a.acknowledged = true
          myArray.push(a)
        })
        setNotification(myArray)
        setNotificationCount(0)
      }
      )
  }

  return (<div>
    {props.isLoggedIn ? <MDBContainer>
      <MDBBreadcrumb>
        <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>Notification</MDBBreadcrumbItem>
      </MDBBreadcrumb>
      <Paper elevation={10} style={{ padding: 25 }}>
        <Grid container alignContent='flex-end' alignItems='flex-end' justify='flex-end'>
          <Grid item>
            <Button variant='contained' color='primary' onClick={() => acknowledgeAll()}>Acknowledge all</Button>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Message
              </TableCell>
              <TableCell>
                Created At
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {notifications !== undefined && notifications.map((notification, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>
                    {notification.message}
                  </TableCell>
                  <TableCell>
                    {new Date(notification.createdAt).toLocaleString('en-GB')}
                  </TableCell>
                  <TableCell>
                    <Button
                      color='secondary' disabled={notification.acknowledged}
                      onClick={() => acknowledgeNotification(notification._id)}
                    ><CheckIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Snackbar message={snackbarMessage} onClose={() => setSnackbarOpen(false)} open={snackbarOpen} />
      </Paper>
    </MDBContainer> : <div />}
  </div>
  )
}

export default NotificationsPage
