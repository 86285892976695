import React, { useState } from 'react'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import Button from '@material-ui/core/Button/Button'
import TextField from '@material-ui/core/TextField/TextField'
import axios from 'axios'

const AuspostRatesRow = (props) => {
  const { rate, setSnackbarOpen, setSnackbarMessage } = props
  const [base, setBase] = useState(null)
  const [perKg, setPerKg] = useState(null)
  const [under500, set500g] = useState(null)
  const [savedBase, setSavedBase] = useState(rate.basic)
  const [savedPerKg, setSavedPerKg] = useState(rate.perKg)
  const [saved500, setSaved500] = useState(rate['500g'])

  function updateRow () {
    axios.post('/api/auspostRates/updateRate', {
      body: JSON.stringify({
        _id: rate._id,
        basic: base === null ? rate.basic : base,
        perKg: perKg === null ? rate.perKg : perKg,
        '500g': under500 === null ? rate['500g'] : under500,
        code: rate.code,
        oldBasic: rate.basic,
        oldPerKg: rate.perKg,
        old500g: rate['500g']
      })
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.data.success) {
          setSnackbarMessage('Updated')
          if (base === null) {
            setSavedBase(rate.basic)
          } else {
            setSavedBase(base)
          }
          if (perKg === null) {
            setSavedPerKg(rate.perKg)
          } else {
            setSavedPerKg(perKg)
          }
          if (under500 === null) {
            setSaved500(rate['500g'])
          } else {
            setSaved500(under500)
          }
        } else {
          setSnackbarMessage('Failed')
        }
        setSnackbarOpen(true)
      })
  }

  return (
    <TableRow>
      <TableCell>{rate.name}</TableCell>
      <TableCell>{rate.code}</TableCell>
      <TableCell>
        <TextField
          type='number' value={base && base} onChange={e => setBase(e.target.value)}
          defaultValue={rate.basic} helperText={'Saved as: ' + savedBase}
        />
      </TableCell>
      <TableCell>
        <TextField
          type='number' value={perKg && perKg} onChange={e => setPerKg(e.target.value)}
          defaultValue={rate.perKg} helperText={'Saved as: ' + savedPerKg}
        />
      </TableCell>
      <TableCell>
        <TextField
          type='number' value={under500 && under500} onChange={e => set500g(e.target.value)}
          defaultValue={rate['500g']} helperText={'Saved as: ' + saved500}
        />
      </TableCell>
      <TableCell>
        <Button
          variant='contained' color='primary'
          onClick={() => updateRow()}
        >Update
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default AuspostRatesRow
