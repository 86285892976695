import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

const MasterCaseRow = (props) => {
  const { masterCase, tags, updateMasterCaseRows, i } = props

  return (
    <Grid container justify='space-between' alignContent='flex-end' alignItems='flex-end' style={{ marginTop: 25 }}>
      <Grid item>
        <TextField label='Quantity' type='number' defaultValue={masterCase.quantity} onChange={e => updateMasterCaseRows(e.target.value, i, 'quantity')} />
      </Grid>
      <Grid item>
        <Autocomplete
          options={tags}
          style={{ width: 200 }}
          // getOptionLabel={(option) => option.tag}
          renderInput={(params) => <TextField {...params} label='Tag' variant='outlined' fullWidth />}
          onChange={(e, value) => updateMasterCaseRows(value, i, 'tag')}
          defaultValue={masterCase.tag}
        />
      </Grid>
    </Grid>
  )
}

export default MasterCaseRow
