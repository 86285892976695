import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBCollapse,
  MDBCollapseHeader,
  MDBContainer,
  MDBIcon,
  MDBDataTable,
  MDBBtn
} from 'mdbreact'
import axios from 'axios'
import Paper from '@material-ui/core/Paper/Paper'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import TextField from '@material-ui/core/TextField/TextField'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid/Grid'
import Divider from '@material-ui/core/Divider/Divider'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import MUIDataTable from 'mui-datatables'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'

import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import FormControl from '@material-ui/core/FormControl/FormControl'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { CSVLink } from 'react-csv'
import { Redirect } from 'react-router-dom'
import DateFnsUtils from '@date-io/date-fns'
import 'date-fns'

var _ = require('lodash')

const BatchReportPage = props => {
  var start = new Date()
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  var end = new Date()
  end.setHours(23)
  end.setMinutes(59)
  const [startDate, setStartDate] = useState(start)
  const [endDate, setEndDate] = useState(end)
  const [isLoading, setIsLoading] = useState(false)
  const [client, setClient] = useState({})
  const [clientMap, setClientMap] = useState([])
  const reduxState = useSelector(state => state)
  const [authQueryReceived, setQueryReceived] = useState(false)
  const [isLoggedIn, setLoggedIn] = useState(false)
  const history = useHistory()
  const columns = [
    { name: 'sku', label: 'SKU' },
    { name: 'orderName', label: 'Order Name' },
    { name: 'batchNumber', label: 'Batch Id' },
    { name: 'quantity', label: 'Quantity Sent' },
    { name: 'quantityRemaining', label: 'Quantity Remaining' },
    { name: 'expirationDate', label: 'Expiration Date' },
    { name: 'fulfilledAt', label: 'Fulfilled At' }
  ]

  const [logs, setLogs] = useState([])

  useEffect(() => {
    setIsLoading(true)

    window
      .fetch('/api/client/getClients')
      .then(res => res.json())
      .then(data => {
        setClientMap(data.clients)
        setClient(data.clients[0])
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    getBatchLogs(client)
  }, [startDate, endDate])

  useEffect(() => {
    getBatchLogs()
  }, [client])

  function handleDateChange (fromOrTo, e) {
    if (fromOrTo === 'from') {
      setStartDate(e)
    } else {
      setEndDate(e)
    }
  }

  function getBatchLogs () {
    window
      .fetch(
        '/api/batches/logs/' + startDate + '/' + endDate + '/' + client._id
      )
      .then(res => res.json())
      .then(data => {
        if (data.logs) {
          setLogs(data.logs)
        }
      })
  }

  return (
    <>
      <MDBContainer>
        <Paper elevation={10} style={{ padding: 25 }}>
          <Grid
            container
            alignContent='space-between'
            justify='space-between'
            alignItems='stretch'
          >
            <Grid item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  format='yyyy-MM-dd'
                  margin='normal'
                  id='from-date'
                  label='From:'
                  onChange={e => handleDateChange('from', e)}
                  value={startDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  variant='inline'
                  style={{ margin: 10 }}
                />
                <KeyboardDatePicker
                  format='yyyy-MM-dd'
                  margin='normal'
                  id='to-date'
                  label='To:'
                  onChange={e => handleDateChange('to', e)}
                  value={endDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  variant='inline'
                  style={{ margin: 10 }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item>
              <InputLabel id='demo-simple-select-label'>Client</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={client}
                onChange={e => setClient(e.target.value)}
                style={{ marginTop: 15 }}
              >
                {clientMap.map((row, i) => {
                  return (
                    <MenuItem key={i} value={row}>
                      {row.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid>
            <Grid item>
              <Button variant='contained' color='primary'>
                <CSVLink
                  filename={
                    client.name +
                    '-' +
                    new Date().toLocaleDateString('en-GB') +
                    '-logs.csv'
                  }
                  data={logs}
                  style={{ color: 'white' }}
                >
                  Download Log
                </CSVLink>
              </Button>
            </Grid>
          </Grid>

          <MUIDataTable
            title='Logs'
            data={logs}
            columns={columns}
            options={{
              download: false,
              isSelectable: false,
              elevation: 0,
              selectableRows: 'none',
              selectableRowsHeader: false
            }}
          />
        </Paper>
      </MDBContainer>
    </>
  )
}

export default BatchReportPage
