import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer } from 'mdbreact'
import Paper from '@material-ui/core/Paper/Paper'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import FormGroup from '@material-ui/core/FormGroup/FormGroup'
import Button from '@material-ui/core/Button/Button'
import Grid from '@material-ui/core/Grid/Grid'
import Table from '@material-ui/core/Table/Table'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableBody from '@material-ui/core/TableBody/TableBody'
import EditReturnsRow from './EditReturnsRow'
import Typography from '@material-ui/core/Typography/Typography'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import ReactGA from 'react-ga'
import { ChevronLeft, Close } from '@material-ui/icons'
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import { useHistory } from 'react-router'

// NOTE Returns page only works with shopify orders for now
const ViewReturnPage = props => {
  const [dbOrder, setDbOrder] = React.useState()
  const [retDoc, setRetDoc] = React.useState()
  const [quantityReceiveList, setQuantityReceiveList] = React.useState({})
  const [rowChecked, setRowChecked] = React.useState({})
  const [reason, setReason] = React.useState('')
  const [solution, setSolution] = React.useState('None')
  const [, updateState] = React.useState()
  const [resolved, setResolved] = React.useState(false)
  const [notes, setNotes] = React.useState('')
  const [replacementRows, setReplacementRows] = React.useState([])
  const [customItemRows, setCustomItemRows] = React.useState([])
  const forceUpdate = React.useCallback(() => updateState({}), [])
  var today = new Date()
  var dd = String(today.getDate()).padStart(2, '0')
  var mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
  var yyyy = today.getFullYear()
  const [selectedDate, setSelectedDate] = React.useState(
    new Date(yyyy + '-' + mm + '-' + dd)
  )

  const [snackbarOpen, setSnackbarOpen] = React.useState(false)
  const [snackbarMessage, setSnackbarMessage] = React.useState('')
  const history = useHistory()
  const reduxState = useSelector(state => state)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [storeInv, setStoreInv] = React.useState()

  useEffect(() => {
    // if (!props.isLoggedIn && !props.isLoading) {
    //   history.push('/login')
    // }
    window
      .fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        props.setAuthQueryReceived(true)
        props.setIsLoggedIn(data.success)
        if (data.success) {
          if (
            reduxState.environmentVariables &&
            reduxState.environmentVariables.public &&
            reduxState.environmentVariables.public.analyticsTrackingId
          ) {
            ReactGA.initialize(
              reduxState.environmentVariables.public.analyticsTrackingId,
              { debug: false }
            )
            ReactGA.pageview(window.location.pathname + window.location.search)
          }

          window
            .fetch('/api/returns/getReturnById/' + props.match.params.returnId)
            .then(res => res.json())
            .then(data => {
              setDbOrder(data.orderData)
              setRetDoc(data)
              setReason(data.reason)
              setResolved(data.resolved)
              if (data.solution) {
                setSolution(data.solution)
              }
              if (data.resolvedAt) {
                setSelectedDate(data.receivedAt)
              }

              if (data.notes) {
                setNotes(data.notes)
              }

              if (data.receivedList) {
                const quantityList = {}
                const checkedList = {}
                data.receivedList.map(item => {
                  quantityList[item.sku] = item.quantityReceived
                  checkedList[item.sku] = item.returnToStock
                })
                setQuantityReceiveList(quantityList)
                setRowChecked(checkedList)
                forceUpdate()
              }

              if (data.replacementRows) {
                setReplacementRows(data.replacementRows)
              }

              if (data.customItemRows) {
                setCustomItemRows(data.customItemRows)
              }

              return data.orderData
            })
            .then(orderData => {
              window
                .fetch('/api/item/getStoreInv/' + orderData.storeId)
                .then(res => res.json())
                .then(data => {
                  setStoreInv(data.inventory)
                })
            })
        }
      })
  }, [])

  const handleDateChange = date => {
    setSelectedDate(date)
  }

  const handleReceiveQuantityChange = (key, e, maxValue) => {
    const quantityList = quantityReceiveList
    if (e.target.value > maxValue) {
      quantityList[key] = maxValue
    } else if (e.target.value < 0) {
      quantityList[key] = 0
    } else {
      quantityList[key] = e.target.value
    }
    setQuantityReceiveList(quantityList)
    forceUpdate()
  }

  const handleChecked = key => {
    const checkedList = rowChecked
    if (checkedList[key]) {
      checkedList[key] = !checkedList[key]
    } else {
      checkedList[key] = true
    }

    setRowChecked(checkedList)
    forceUpdate()
  }

  const handleSolution = event => {
    setSolution(event.target.value)
  }

  const handleReasonChange = event => {
    setReason(event.target.value)
  }

  const handleResolve = () => {
    if (solution !== 'None') {
      const receivedList = []
      Object.keys(quantityReceiveList).map(key => {
        receivedList.push({
          sku: key,
          quantityReceived: quantityReceiveList[key],
          returnToStock: rowChecked[key]
        })
      })
      let payload
      if (!retDoc.isCustom) {
        payload = {
          _id: retDoc._id,
          orderId: dbOrder.orderId,
          isCustom: dbOrder.isCustom,
          receivedList: receivedList,
          notes: notes,
          reason: reason,
          replacementRows: replacementRows,
          customItemRows: customItemRows,
          solution: solution
        }
      } else {
        payload = {
          _id: retDoc._id,
          orderId: dbOrder.orderId,
          isCustom: dbOrder.isCustom,
          receivedList: customItemRows,
          notes: notes,
          reason: reason,
          replacementRows: replacementRows,
          customItemRows: customItemRows,
          solution: solution
        }
      }
      window
        .fetch('/api/returns/resolveReturn', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ payload })
        })
        .then(res => res.json())
        .then(data => {
          setResolved(true)
          setSnackbarOpen(true)
          if (data.success) {
            setSnackbarMessage('Return Updated Successfully')
          } else {
            setSnackbarMessage('Return Not Updated Successfully')
          }
        })
    } else {
      setSnackbarOpen(true)
      setSnackbarMessage('Select a Solution')
    }

    setDialogOpen(false)
  }

  const handleUpdate = () => {
    const receivedList = []
    Object.keys(quantityReceiveList).map(key => {
      receivedList.push({
        sku: key,
        quantityReceived: quantityReceiveList[key],
        returnToStock: rowChecked[key]
      })
    })
    let payload
    if (!retDoc.isCustom) {
      payload = {
        _id: retDoc._id,
        orderId: dbOrder.orderId,
        isCustom: dbOrder.isCustom,
        receivedList: receivedList,
        notes: notes,
        reason: reason,
        replacementRows: replacementRows,
        customItemRows: customItemRows,
        solution: solution
      }
    } else {
      payload = {
        _id: retDoc._id,
        orderId: dbOrder.orderId,
        isCustom: dbOrder.isCustom,
        receivedList: customItemRows,
        notes: notes,
        reason: reason,
        replacementRows: replacementRows,
        customItemRows: customItemRows,
        solution: solution
      }
    }
    window
      .fetch('/api/returns/updateReturn', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ payload })
      })
      .then(res => res.json())
      .then(data => {
        setSnackbarOpen(true)
        if (data.success) {
          setSnackbarMessage('Details Saved Successfully')
        } else {
          setSnackbarMessage('Details Not Saved Successfully')
        }
      })
  }
  const handleReceiveAll = () => {
    const newReceivedList = {}
    dbOrder.lineItems.map(item => {
      newReceivedList[item.sku] = item.qty - item.fulfillableQty
    })

    setQuantityReceiveList(newReceivedList)
    forceUpdate()
  }

  const handleNotesChange = e => {
    setNotes(e.target.value)
  }

  const handleAddReplacementRow = () => {
    const newReplacementRows = replacementRows
    newReplacementRows.push({
      sku: '',
      title: '',
      quantityInStock: 0,
      quantityReceived: 0
    })
    setReplacementRows(newReplacementRows)
    forceUpdate()
  }

  const handleRemoveReplacementRow = () => {
    const newReplacementRows = replacementRows
    newReplacementRows.pop()
    setReplacementRows(newReplacementRows)
    forceUpdate()
  }

  const handleRemoveCustomRow = () => {
    const newCustomItemRows = customItemRows
    newCustomItemRows.pop()
    setCustomItemRows(newCustomItemRows)
    forceUpdate()
  }

  const handleReplacementSKUChange = (data, index) => {
    if (data) {
      const newReplacementRows = replacementRows
      newReplacementRows[index].sku = data.sku
      newReplacementRows[index].title = data.title
      newReplacementRows[index].quantityInStock = data.quantity
      setReplacementRows(newReplacementRows)
      forceUpdate()
    }
  }

  const handleCustomSKUChange = (data, index) => {
    if (data) {
      const newCustomItemRows = customItemRows
      newCustomItemRows[index].sku = data.sku
      newCustomItemRows[index].title = data.title
      setCustomItemRows(newCustomItemRows)
      forceUpdate()
    }
  }

  const handleReplacementQuantityChange = (value, index) => {
    if (value) {
      const newReplacementRows = replacementRows
      newReplacementRows[index].quantityReceived = value
      setReplacementRows(newReplacementRows)
      forceUpdate()
    }
  }

  const handleCustomQuantityChange = (value, index) => {
    if (value) {
      const newCustomItemRows = customItemRows
      newCustomItemRows[index].quantityReceived = value
      setCustomItemRows(newCustomItemRows)
      forceUpdate()
    }
  }

  const handleAddCustomOrderItem = () => {
    const newCustomItemRows = customItemRows
    newCustomItemRows.push({
      sku: '',
      title: '',
      quantityInStock: 0,
      quantityReceived: 0,
      returnToStock: false
    })
    setCustomItemRows(newCustomItemRows)
    forceUpdate()
  }

  const handleCustomReturnToStock = index => {
    const newCustomItemRows = customItemRows
    newCustomItemRows[index].returnToStock = !newCustomItemRows[index]
      .returnToStock
    setCustomItemRows(newCustomItemRows)
    forceUpdate()
  }
  return (
    <>
      {props.isLoggedIn && !props.isLoading ? (
        <>
          <MDBContainer>
            <MDBBreadcrumb style={{ paddingTop: 10 }}>
              <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
              <MDBBreadcrumbItem>Returns</MDBBreadcrumbItem>
              <MDBBreadcrumbItem active>
                Order -{' '}
                {dbOrder
                  ? dbOrder.orderName
                    ? dbOrder.orderName
                    : dbOrder.OrderID
                  : 'Loading'}
              </MDBBreadcrumbItem>
            </MDBBreadcrumb>
            <Paper elevation={10} style={{ padding: 25, marginBottom: 25 }}>
              <Grid
                container
                justify='space-between'
                style={{ marginBottom: 25 }}
              >
                <Grid item xs={2} container justify='flex-start'>
                  <Button onClick={() => props.history.push('/returns')}>
                    <ChevronLeft /> Back
                  </Button>
                </Grid>
                <Grid item xs={6} container justify='flex-start'>
                  <Typography variant='h5' align='center'>
                    Items for Order {dbOrder ? dbOrder.orderName : 'Loading...'}
                  </Typography>
                </Grid>
                <Grid item />
              </Grid>

              {dbOrder ? (
                <>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>SKU</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Quantity Ordered</TableCell>
                        <TableCell>Quantity Sent</TableCell>
                        <TableCell>Quantity Received</TableCell>
                        <TableCell>Return to Stock?</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {dbOrder && retDoc && !retDoc.isCustom
                        ? dbOrder.lineItems.map((item, i) => {
                            return (
                              <TableRow
                                key={item.sku}
                                style={{
                                  backgroundColor:
                                    quantityReceiveList[item.sku] > 0 &&
                                    'palegreen'
                                }}
                              >
                                <TableCell>{item.sku}</TableCell>
                                <TableCell>{item.title}</TableCell>
                                <TableCell>{item.qty}</TableCell>
                                <TableCell>
                                  {item.qty - item.fulfillableQty}
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    id='outlined-number'
                                    label='Quantity'
                                    type='number'
                                    disabled={resolved}
                                    value={
                                      quantityReceiveList[item.sku]
                                        ? quantityReceiveList[item.sku]
                                        : 0
                                    }
                                    onChange={e =>
                                      handleReceiveQuantityChange(
                                        item.sku,
                                        e,
                                        item.qty - item.fulfillableQty
                                      )
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    variant='outlined'
                                  />
                                </TableCell>
                                <TableCell>
                                  <Checkbox
                                    disabled={resolved}
                                    checked={rowChecked[item.sku] || false}
                                    onClick={() => handleChecked(item.sku)}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          })
                        : customItemRows.map((item, i) => {
                            return (
                              <TableRow key={i}>
                                <TableCell align='center'>
                                  {item.sku === '' ? (
                                    <Autocomplete
                                      fullWidth
                                      style={{ width: 300, paddingLeft: 10 }}
                                      options={storeInv}
                                      getOptionLabel={option =>
                                        option.sku + ' - ' + option.title
                                      }
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          label='Item'
                                          variant='outlined'
                                          fullWidth
                                        />
                                      )}
                                      onChange={(e, value) => {
                                        handleCustomSKUChange(value, i)
                                      }}
                                    />
                                  ) : (
                                    item.sku
                                  )}
                                </TableCell>
                                <TableCell>{item.title}</TableCell>
                                <TableCell>N/A</TableCell>
                                <TableCell>N/A</TableCell>
                                <TableCell align='center'>
                                  <TextField
                                    id='outlined-number'
                                    label='Quantity'
                                    type='number'
                                    disabled={resolved || !item.sku}
                                    value={item.quantityReceived}
                                    onChange={e =>
                                      handleCustomQuantityChange(
                                        e.target.value,
                                        i
                                      )
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    variant='outlined'
                                  />
                                </TableCell>
                                <TableCell>
                                  <Checkbox
                                    disabled={resolved || !item.sku}
                                    checked={
                                      (customItemRows[i] &&
                                        customItemRows[i].returnToStock) ||
                                      false
                                    }
                                    onClick={() => handleCustomReturnToStock(i)}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          })}
                    </TableBody>
                  </Table>
                  {retDoc && retDoc.isCustom && (
                    <>
                      <Button
                        disabled={resolved}
                        variant='contained'
                        color='secondary'
                        size='large'
                        style={{ marginTop: 25, marginRight: 25 }}
                        onClick={() => handleRemoveCustomRow()}
                      >
                        Remove order item
                      </Button>
                      <Button
                        disabled={resolved}
                        variant='contained'
                        color='primary'
                        size='large'
                        style={{ marginTop: 25 }}
                        onClick={() => handleAddCustomOrderItem()}
                      >
                        Add order item
                      </Button>
                    </>
                  )}
                  <Grid container>
                    <Grid item xs={6} style={{ marginTop: 25 }}>
                      <TextField
                        id='outlined-notes'
                        label={<b>Notes</b>}
                        type='text'
                        multiline
                        rowsMax={6}
                        rows={4}
                        value={notes}
                        onChange={e => {
                          handleNotesChange(e)
                        }}
                        fullWidth
                        style={{ marginLeft: 25 }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant='outlined'
                      />
                    </Grid>
                  </Grid>
                  {retDoc && !retDoc.isCustom && (
                    <Grid container justify='flex-end'>
                      <Button
                        disabled={resolved}
                        variant='contained'
                        color='primary'
                        size='large'
                        style={{ marginTop: 25, marginRight: 15 }}
                        onClick={() => handleReceiveAll()}
                      >
                        Received All
                      </Button>
                    </Grid>
                  )}
                </>
              ) : (
                <LinearProgress style={{ width: '100%', margin: '25px 5px' }} />
              )}
            </Paper>
            {retDoc && (
              <Paper elevation={10} style={{ padding: 25, marginBottom: 25 }}>
                <Typography
                  variant='h5'
                  align='center'
                  style={{ marginTop: 15, marginBottom: 25 }}
                >
                  Replacements
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align='center'>SKU</TableCell>
                      <TableCell align='center'>Title</TableCell>
                      <TableCell align='center'>Quantity in stock</TableCell>
                      <TableCell align='center'>Quantity to send</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {replacementRows.map((row, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell align='center'>
                            {row.sku === '' ? (
                              <Autocomplete
                                fullWidth
                                style={{ paddingLeft: 10 }}
                                options={storeInv}
                                getOptionLabel={option =>
                                  option.sku + ' - ' + option.title
                                }
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label='Item'
                                    variant='outlined'
                                    fullWidth
                                  />
                                )}
                                onChange={(e, value) => {
                                  handleReplacementSKUChange(value, i)
                                }}
                              />
                            ) : (
                              row.sku
                            )}
                          </TableCell>
                          <TableCell>{row.title}</TableCell>
                          <TableCell align='center'>
                            {row.quantityInStock}
                          </TableCell>
                          <TableCell align='center'>
                            <TextField
                              id='outlined-number'
                              label='Quantity'
                              type='number'
                              disabled={resolved}
                              value={row.quantityReceived}
                              onChange={e =>
                                handleReplacementQuantityChange(
                                  e.target.value,
                                  i
                                )
                              }
                              InputLabelProps={{
                                shrink: true
                              }}
                              variant='outlined'
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
                <Button
                  disabled={resolved}
                  variant='contained'
                  color='secondary'
                  size='large'
                  style={{ marginTop: 25, marginRight: 25 }}
                  onClick={() => handleRemoveReplacementRow()}
                >
                  Remove replacement item
                </Button>
                <Button
                  disabled={resolved}
                  variant='contained'
                  color='primary'
                  size='large'
                  style={{ marginTop: 25 }}
                  onClick={() => handleAddReplacementRow()}
                >
                  Add replacement item
                </Button>
              </Paper>
            )}
            {retDoc && !retDoc.isCustom && (
              <Paper elevation={10} style={{ padding: 25, marginBottom: 25 }}>
                <Typography
                  variant='h5'
                  align='center'
                  style={{ marginTop: 15, marginBottom: 25 }}
                >
                  Fulfillment Details
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Box Type</TableCell>
                      <TableCell>Polybag</TableCell>
                      <TableCell>Weight</TableCell>
                      <TableCell>Tracking Number</TableCell>
                      <TableCell>Courier</TableCell>
                      {dbOrder &&
                        dbOrder.fulfillment.courier === 'Australia Post' && (
                          <TableCell>eParcel?</TableCell>
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dbOrder &&
                      dbOrder.fulfillment.fulfillmentRows.map((item, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell>{item.boxType}</TableCell>
                            <TableCell>{item.polybag ? 'Yes' : 'No'}</TableCell>
                            <TableCell>{item.weight}</TableCell>
                            <TableCell>{item.trackingNumber}</TableCell>
                            <TableCell>
                              {dbOrder && dbOrder.fulfillment.courier}
                            </TableCell>
                            {dbOrder.fulfillment.courier ===
                              'Australia Post' && (
                              <TableCell>
                                {item.isEparcel ? 'Yes' : 'No'}
                              </TableCell>
                            )}
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </Paper>
            )}

            <Paper elevation={10} style={{ padding: 25, marginBottom: 25 }}>
              {dbOrder ? (
                <>
                  <Typography
                    variant='h5'
                    align='center'
                    style={{ marginTop: 5, marginBottom: 25 }}
                  >
                    Return Details
                  </Typography>
                  <Grid
                    container
                    spacing={1}
                    justify='flex-start'
                    alignContent='flex-start'
                    alignItems='flex-start'
                  >
                    <Grid item xs={3}>
                      <TextField
                        id='outlined-reason'
                        label={<b>Reason</b>}
                        type='text'
                        multiline
                        rowsMax={6}
                        rows={4}
                        value={reason}
                        onChange={e => {
                          handleReasonChange(e)
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={3}>
                      {retDoc && !retDoc.isCustom && (
                        <Typography variant='body1' align='left'>
                          <b>Name:</b>
                          <br />
                          {dbOrder && dbOrder.shippingAddress.first_name}{' '}
                          {dbOrder && dbOrder.shippingAddress.last_name}
                          <br />
                          <b>Address:</b>
                          <br />
                          {dbOrder &&
                            dbOrder.shippingAddress.address1 &&
                            dbOrder.shippingAddress.address1 + ', '}
                          {dbOrder &&
                            dbOrder.shippingAddress.city &&
                            dbOrder.shippingAddress.city + ', '}
                          {dbOrder &&
                            dbOrder.shippingAddress.province_code &&
                            dbOrder.shippingAddress.province_code + ', '}
                          {dbOrder &&
                            dbOrder.shippingAddress.zip &&
                            dbOrder.shippingAddress.zip + ', '}
                          {dbOrder &&
                            dbOrder.shippingAddress.country &&
                            dbOrder.shippingAddress.country}
                          <br />
                          <b>Email:</b>
                          <br />
                          {dbOrder && dbOrder.email}
                          <br />
                          <b>Phone:</b>
                          <br />
                          {dbOrder && dbOrder.shippingAddress.phone}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item container justify='flex-start' xs={3}>
                      <FormControl style={{ marginLeft: 25 }}>
                        <InputLabel id='demo-simple-select-label'>
                          <b>Solution</b>
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          value={solution}
                          disabled={resolved}
                          onChange={e => {
                            handleSolution(e)
                          }}
                        >
                          <MenuItem value='None' disabled>
                            <em>Select Solution</em>
                          </MenuItem>
                          <MenuItem value='Refund'>Refund</MenuItem>
                          <MenuItem value='Replacement'>Replacement</MenuItem>
                          <MenuItem value='Credit Note'>Credit Note</MenuItem>
                          <MenuItem value='Resend'>Resend</MenuItem>
                          <MenuItem value='No Response - Return to stock'>
                            No Response - Return to stock
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disabled={resolved}
                          disableToolbar
                          variant='inline'
                          format='yyyy/MM/dd'
                          // margin='normal'
                          id='date-picker-inline'
                          label={<b>Resolved Date</b>}
                          value={selectedDate}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} style={{ marginTop: 15 }}>
                    <Grid item xs={6} />
                    <Grid
                      item
                      container
                      xs={6}
                      justify='flex-end'
                      style={{ paddingRight: 50, maxHeight: 200 }}
                    >
                      <Button
                        disabled={resolved}
                        variant='contained'
                        color='primary'
                        size='large'
                        style={{ marginRight: 15 }}
                        onClick={() => handleUpdate()}
                      >
                        Update
                      </Button>
                      <Button
                        variant='contained'
                        color='primary'
                        disabled={resolved}
                        size='large'
                        onClick={() => setDialogOpen(true)}
                        style={{}}
                      >
                        Resolve
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} style={{ marginTop: 15 }}>
                    <Grid container item xs={6} />
                  </Grid>
                </>
              ) : (
                <LinearProgress style={{ width: '100%', margin: '25px 5px' }} />
              )}
            </Paper>
          </MDBContainer>
          <Snackbar
            onClose={() => setSnackbarOpen(false)}
            open={snackbarOpen}
            message={snackbarMessage}
          />
          <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              {'Are you sure you want to resolve?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                You won't be able to change any details after resolving
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDialogOpen(false)} color='primary'>
                No
              </Button>
              <Button onClick={() => handleResolve()} color='primary' autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { referrer: '/return/' + props.match.params.returnId }
          }}
        />
      )}
    </>
  )
}

export default ViewReturnPage
