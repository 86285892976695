import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import Grid from '@material-ui/core/Grid/Grid'
import FormControl from '@material-ui/core/FormControl/FormControl'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { isEmpty } from '../../Utils/Utils'
import TextField from '@material-ui/core/TextField/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete'

const InventorySnapshot = (props) => {
  const [clientMap, setClientMap] = useState([])
  const [client, setClient] = useState({})
  const [inventory, setInventory] = useState([])
  const [inventorySnapshotObj, setInventorySnapshotObj] = useState({})
  const [inventorySnapshotDates, setInventorySnapshotDates] = useState([])
  const [date, setDate] = useState('')

  useEffect(() => {
    window.fetch('/api/client/getClients')
      .then(res => res.json())
      .then(data => {
        setClientMap(data.clients)
        setClient(data.clients[0])
      })
  }, [])

  useEffect(() => {
    if (!isEmpty(client)) {
      window.fetch('/api/inventorySnapshot/getSnapshotByClient/' + client._id)
        .then(res => res.json())
        .then(data => {
          // setInventory(data.data)
          setInventorySnapshotDates(data)
        })
    }
    setInventorySnapshotObj({})
    setInventory([])
    setDate('')
  }, [client])

  useEffect(() => {
    if (!isEmpty(client)) {
      window.fetch('/api/inventorySnapshot/getSnapshotById/' + inventorySnapshotObj._id)
        .then(res => res.json())
        .then(data => {
          setInventory(data.data)
          setDate(new Date(data.createdAt).getDate() + '/' + (new Date(data.createdAt).getMonth() + 1) + '/' + new Date(data.createdAt).getFullYear())
        })
    }
  }, [inventorySnapshotObj])

  const columns = [
    { name: 'title', label: 'Title' },
    { name: 'sku', label: 'SKU' },
    { name: 'quantity', label: 'Quantity' },
    { name: 'displayQuantity', label: 'Quantity Displayed' }
  ]

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  }))
  const classes = useStyles()

  return (
    <>
      <Grid container spacing={5}>
        <Grid item>
          <FormControl className={classes.formControl}>
            <InputLabel id='demo-simple-select-label'>Client</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={client}
              onChange={e => setClient(e.target.value)}
              style={{ marginTop: 15 }}
            >
              {clientMap.map((row, i) => {
                return (
                  <MenuItem key={i} value={row}>{row.name}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          {/* <FormControl className={classes.formControl}> */}
          {/*    <InputLabel id="createdAt">Date:</InputLabel> */}
          {/*    <Select */}
          {/*        labelId="createdAt" */}
          {/*        id="created" */}
          {/*        value={inventorySnapshotObj} */}
          {/*        onChange={e => setInventorySnapshotObj(e.target.value)} */}
          {/*        style={{ marginTop: 15 }} */}
          {/*    > */}
          {/*        {inventorySnapshotDates.map((row, i) => { */}
          {/*            let date = new Date(row.createdAt) */}
          {/*            let dateString = date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear() */}
          {/*            return ( */}
          {/*                <MenuItem key={i} value={row}>{dateString}</MenuItem> */}
          {/*            ) */}
          {/*        })} */}
          {/*    </Select> */}
          {/* </FormControl> */}
          <Autocomplete
            options={inventorySnapshotDates}
            getOptionLabel={(row) => new Date(row.createdAt).getDate() + '/' + (new Date(row.createdAt).getMonth() + 1) + '/' + new Date(row.createdAt).getFullYear()}
            onChange={(e, value) => setInventorySnapshotObj(value)}
            renderInput={(params) => <TextField {...params} label='Date' variant='outlined' />}
            fullWidth
            style={{ minWidth: 200 }}
          />
        </Grid>
      </Grid>
      <MUIDataTable
        title={client.name + "'s Inventory " + date}
        data={inventory}
        columns={columns}
        style={{ width: '100vw' }}
        options={{ rowsPerPage: 50, rowsPerPageOptions: [50, 100, 500] }}
      />
    </>
  )
}

export default InventorySnapshot
