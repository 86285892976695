import { MDBCardBody, MDBRow } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import MUIDataTable from 'mui-datatables'
import Typography from '@material-ui/core/Typography/Typography'
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress'
import Paper from '@material-ui/core/Paper/Paper'

const BundleView = props => {
  const [bundles, setBundles] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    window
      .fetch('/api/inventoryBundle/getBundles')
      .then(res => res.json())
      .then(data => {
        const myBundles = data.bundles
        const formatBundles = myBundles.map((bundle, i) => {
          const clientStores = []
          const formatStores = bundle.stores.map(store => {
            clientStores.push(
              <Typography
                key={store._id}
                variant='caption'
                style={{
                  color: store && store.active ? 'limegreen' : 'tomato'
                }}
              >
                {store.platform + ' ' + store.storeName}
                <br />
              </Typography>
            )
          })

          const clientItems = []
          const formatItems = bundle.skuArray.map((row, i) => {
            clientItems.push(
              <div style={{ borderBottom: '1px solid #e3e3e3' }}>
                <Typography key={i + row.itemId} variant='caption'>
                  {row.qty + ' * ' + row.sku + ' - ' + row.title}
                </Typography>
              </div>
            )
          })

          Promise.all([formatStores, formatItems]).then(() => {
            bundle.clientItems = clientItems
            bundle.clientStores = clientStores
          })
        })

        Promise.all(formatBundles).then(() => {
          setBundles(myBundles)
          setLoading(false)
        })
      })
  }, [])

  const columns = [
    { name: 'aliasSku', label: 'Alias SKU' },
    { name: 'title', label: 'Title' },
    { name: 'clientName', label: 'Client' },
    { name: 'clientStores', label: 'Stores', sort: false },
    { name: 'clientItems', label: 'Contents', sort: false },
    { name: 'qty', label: 'Display Qty' },
    { name: 'createdAt', label: 'Created At' }
  ]

  return (
    <MDBCardBody style={{ padding: 25 }}>
      <MDBRow center key='tablerow'>
        {loading ? (
          <LinearProgress style={{ width: '100%', margin: '25px 5px' }} />
        ) : (
          <Paper style={{ padding: 25 }} elevation={5}>
            <MUIDataTable
              title='Bundles'
              data={bundles}
              columns={columns}
              style={{ width: '100vw' }}
              options={{
                download: false,
                isSelectable: false,
                elevation: 0,
                selectableRows: 'none',
                selectableRowsHeader: false
              }}
            />
          </Paper>
        )}
      </MDBRow>
    </MDBCardBody>
  )
}

export default BundleView
