import React, { useState } from 'react'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import Button from '@material-ui/core/Button/Button'
import TextField from '@material-ui/core/TextField/TextField'
import axios from 'axios'

const ClientMarginsRowInternational = (props) => {
  const { rate, id, setSnackbarOpen, setSnackbarMessage } = props
  const [multiplier, setMultiplier] = useState(rate.multiplier)
  const [multiplier500, setMultiplier500] = useState(rate.multiplier500)
  const [multiplier1kg, setMultiplier1kg] = useState(rate.multiplier1kg)
  const [multiplier2kg, setMultiplier2kg] = useState(rate.multiplier2kg)
  const [savedMultiplier, setSavedMultiplier] = useState(rate.multiplier)
  const [savedMultiplier500, setSavedMultiplier500] = useState(rate.multiplier500)
  const [savedMultiplier1kg, setSavedMultiplier1kg] = useState(rate.multiplier1kg)
  const [savedMultiplier2kg, setSavedMultiplier2kg] = useState(rate.multiplier1kg)

  function updateRate () {
    axios.post('/api/clientInvoiceMultiplier/updateRateInternational', {
      body: JSON.stringify({
        _id: id,
        code: rate.code,
        multiplier: multiplier,
        multiplier500: multiplier500,
        multiplier1kg: multiplier1kg,
        multiplier2kg: multiplier2kg,
        oldMultiplier: rate.multiplier,
        oldMultiplier500: rate.oldMultiplier500,
        oldMultiplier1kg: rate.oldMultiplier1kg,
        oldMultiplier2kg: rate.oldMultiplier2kg
      })
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.data.success) {
          setSnackbarMessage('Updated')
          setSavedMultiplier(multiplier)
          setSavedMultiplier500(multiplier500)
          setSavedMultiplier1kg(multiplier1kg)
          setSavedMultiplier2kg(multiplier2kg)
        } else {
          setSnackbarMessage('Failed')
        }
        setSnackbarOpen(true)
      })
  }

  return (
    <TableRow>
      <TableCell>{rate.code}</TableCell>
      <TableCell>
        <TextField
          type='number' value={multiplier} onChange={e => setMultiplier(e.target.value)}
          helperText={'Saved as: ' + savedMultiplier}
        />
      </TableCell>
      <TableCell>
        <TextField
          type='number' value={multiplier500} onChange={e => setMultiplier500(e.target.value)}
          helperText={'Saved as: ' + savedMultiplier500}
        />
      </TableCell>
      <TableCell>
        <TextField
          type='number' value={multiplier1kg} onChange={e => setMultiplier1kg(e.target.value)}
          helperText={'Saved as: ' + savedMultiplier1kg}
        />
      </TableCell>
      <TableCell>
        <TextField
          type='number' value={multiplier2kg} onChange={e => setMultiplier2kg(e.target.value)}
          helperText={'Saved as: ' + savedMultiplier2kg}
        />
      </TableCell>
      <TableCell>
        <Button
          variant='contained' color='primary'
          onClick={() => updateRate()}
        >Update
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default ClientMarginsRowInternational
