import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper/Paper'
import TextField from '@material-ui/core/TextField/TextField'
import { Redirect } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import InputLabel from '@material-ui/core/InputLabel/InputLabel'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import Grid from '@material-ui/core/Grid/Grid'
import Divider from '@material-ui/core/Divider/Divider'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import Typography from '@material-ui/core/Typography/Typography'
import ClientRatesPackagingRow from './ClientRatesPackagingRow'
import ClientRatesPickingRow from './ClientRatesPickingRow'
import Button from '@material-ui/core/Button/Button'
import axios from 'axios'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import AddIcon from '@material-ui/icons/Add'
import Modal from '@material-ui/core/Modal/Modal'
import ClientRatesPickModal from './ClientRatesPickModal'
import ClientRatesPackModal from './ClientRatesPackModal'

const ClientRatesPage = (props) => {
  const reduxState = useSelector(state => state)
  const [rates, setRates] = useState([])
  const [stores, setStores] = useState([])
  const [client, setClient] = useState({})
  const [clientList, setClientList] = useState([])
  const [dispatch, setDispatch] = useState(0)
  const [returnCost, setReturn] = useState(0)
  const [polybag, setPolybag] = useState(0)
  const [rts, setRts] = useState(0)
  const [origDispatch, setOrigDispatch] = useState(0)
  const [origReturnCost, setOrigReturn] = useState(0)
  const [origPolybag, setOrigPolybag] = useState(0)
  const [origRts, setOrigRts] = useState(0)
  const [packaging, setPackaging] = useState([])
  const [storePick, setStorePick] = useState([])
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [createPickModal, setCreatePickModal] = useState(false)
  const [createPackModal, setCreatePackModal] = useState(false)
  const [forceUpdate, setForceUpdate] = useState(false)

  useEffect(() => {
    if (reduxState.environmentVariables && reduxState.environmentVariables.public && reduxState.environmentVariables.public.analyticsTrackingId) {
      ReactGA.initialize(reduxState.environmentVariables.public.analyticsTrackingId, { debug: false })
      ReactGA.pageview(window.location.pathname + window.location.search)
    }

    window.fetch('/api/client/getClients')
      .then(res => res.json())
      .then(data => {
        setClientList(data.clients)
        setClient(data.clients[0])
        setStores(data.clients[0].stores)
      })
  }, [])

  useEffect(() => {
    window.fetch('/api/invoiceRates/getInvoiceRatesByClientId/' + client._id)
      .then(res => res.json())
      .then(data => {
        if (data !== null) {
          setRates(data)
          setDispatch(data.dispatch)
          setRts(data.rts)
          setReturn(data.return)
          setPolybag(data.polybag)
          setOrigDispatch(data.dispatch)
          setOrigRts(data.rts)
          setOrigReturn(data.return)
          setOrigPolybag(data.polybag)
          setPackaging(null)
          setStorePick(null)
          setTimeout(function () {
            setStorePick(data.storePick)
            setPackaging(data.packaging)
          }, 50)
        }
      })
    setStores(client.stores)
  }, [client, forceUpdate])

  if (!props.isLoggedIn && !props.isLoading) {
    return <Redirect to={{ pathname: '/login', state: { referrer: '/clientRates' } }} />
  }

  function updateRow () {
    axios.post('/api/invoiceRates/updateRateMisc', {
      body: JSON.stringify({
        _id: rates._id,
        dispatch,
        returnCost,
        polybag,
        rts
      })
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.data.success) {
          setSnackbarMessage('Updated')
          setOrigDispatch(dispatch)
          setOrigReturn(returnCost)
          setOrigRts(rts)
          setOrigPolybag(polybag)
        } else {
          setSnackbarMessage('Failed')
        }
        setSnackbarOpen(true)
      })
  }

  return (
    <div>
      {props.isLoggedIn
        ? <MDBContainer>
          <MDBBreadcrumb>
            <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
            <MDBBreadcrumbItem active>Client Rates</MDBBreadcrumbItem>
          </MDBBreadcrumb>
          <Paper style={{ padding: 25 }} elevation={5}>
            <InputLabel id='client-label' shrink>Client</InputLabel>
            <Select labelId='client-label' value={client} onChange={e => setClient(e.target.value)}>
              {clientList.sort((a, b) => a.name > b.name).map((row, i) => {
                return (
                  <MenuItem key={i} value={row}>{row.name}</MenuItem>
                )
              })}
            </Select>
            <Divider style={{ margin: '20px 0px' }} />
            <Grid container justify='space-evenly' alignItems='center' alignContent='center'>
              <Grid item>
                <TextField
                  helperText={'Saved as: ' + origDispatch} type='number' label='Dispatch'
                  value={dispatch} onChange={e => setDispatch(e.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField
                  helperText={'Saved as: ' + origReturnCost} type='number' label='Return'
                  value={returnCost} onChange={e => setReturn(e.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField
                  helperText={'Saved as: ' + origRts} type='number' label='RTS' value={rts}
                  onChange={e => setRts(e.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField
                  helperText={'Saved as: ' + origPolybag} type='number' label='Polybag' value={polybag}
                  onChange={e => setPolybag(e.target.value)}
                />
              </Grid>
              <Grid item>
                <Button variant='contained' onClick={() => updateRow()}>Update</Button>
              </Grid>
            </Grid>
            <Divider style={{ margin: '20px 0px' }} />
            <Grid container justify='space-evenly'>
              <Grid item lg={6}>
                <Grid
                  container direction='column' justify='center' alignItems='center' alignContent='center'
                  spacing={5}
                >
                  <Grid item>
                    <Typography variant='h6'>Packaging</Typography>
                  </Grid>
                  {packaging !== null && packaging.map((row, i) => {
                    return (
                      <ClientRatesPackagingRow
                        setSnackbarOpen={setSnackbarOpen}
                        setSnackbarMessage={setSnackbarMessage} row={row} key={i}
                      />
                    )
                  })}
                  <Grid item>
                    <Button onClick={() => setCreatePackModal(!createPackModal)}><AddIcon color='primary' /></Button>
                  </Grid>
                </Grid>
                {/* <Table> */}
                {/* <TableHead> */}
                {/* <TableRow> */}
                {/* <TableCell>Name</TableCell> */}
                {/* <TableCell>Cost</TableCell> */}
                {/* </TableRow> */}
                {/* </TableHead> */}
                {/* <TableBody> */}
                {/* {packaging !== null && packaging.map((row,i) => { */}
                {/* return ( */}
                {/* <TableRow key={i}> */}
                {/* <TableCell>{row.name}</TableCell> */}
                {/* <TableCell>{row.cost}</TableCell> */}
                {/* </TableRow> */}
                {/* ) */}
                {/* })} */}
                {/* </TableBody> */}
                {/* </Table> */}
              </Grid>
              <Grid item lg={6}>
                <Typography variant='h6' align='center'>Picking</Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Item Type</TableCell>
                      <TableCell>Threshold</TableCell>
                      <TableCell>Cost Under Threshold</TableCell>
                      <TableCell>Cost Over Threshold</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {storePick !== null && storePick.map((row, i) => {
                      return (
                        <ClientRatesPickingRow
                          client={client} setSnackbarOpen={setSnackbarOpen}
                          setSnackbarMessage={setSnackbarMessage} row={row}
                          key={i}
                        />
                      )
                    })}
                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell>
                        <Button onClick={() => setCreatePickModal(!createPickModal)}>
                          <AddIcon
                            color='primary'
                          />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            {createPickModal &&
              <Modal open={createPickModal} onClose={() => setCreatePickModal(false)}>
                <ClientRatesPickModal
                  stores={stores} client={client} setSnackbarOpen={setSnackbarOpen}
                  setSnackbarMessage={setSnackbarMessage} setForceUpdate={setForceUpdate} forceUpdate={forceUpdate}
                  setCreatePickModal={setCreatePickModal}
                />
              </Modal>}
            {createPackModal &&
              <Modal open={createPackModal} onClose={() => setCreatePackModal(false)}>
                <ClientRatesPackModal
                  stores={stores} client={client} setSnackbarOpen={setSnackbarOpen}
                  setSnackbarMessage={setSnackbarMessage} setForceUpdate={setForceUpdate} forceUpdate={forceUpdate}
                  setCreatePackModal={setCreatePackModal}
                />
              </Modal>}
            <Snackbar message={snackbarMessage} open={snackbarOpen} onClose={() => setSnackbarOpen(false)} />
          </Paper>
          </MDBContainer> : <div />}
    </div>
  )
}

export default ClientRatesPage
