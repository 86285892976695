import React, { Component } from 'react'
import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCollapse,
  MDBCollapseHeader,
  MDBContainer,
  MDBIcon,
  MDBInputGroup,
  MDBRow
} from 'mdbreact'

import axios from 'axios'
import '../../index.css'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import TextField from '@material-ui/core/TextField/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Typography from '@material-ui/core/Typography/Typography'
import Paper from '@material-ui/core/Paper/Paper'
import { Redirect } from 'react-router-dom'
import Grid from '@material-ui/core/Grid/Grid'
import { CSVLink } from 'react-csv'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import { setEnvironmentVariables } from '../../redux/actions'

class createItemPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileName: '',
      collapseID: 'collapse1',
      fileData: '',
      sku: '',
      title: '',
      quantity: 0,
      location: '',
      imageUrl: '',
      client: '',
      clientName: '',
      store: [],
      detailRows: {},
      detailInputs: {},
      clientList: [],
      snackbarOpen: false,
      snackbarMessage: '',
      shelfNameList: [],
      shelfList: [],
      authQueryReceived: false,
      isLoggedIn: false
    }

    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.handleUploadClick = this.handleUploadClick.bind(this)
    this.handleSKUChange = this.handleSKUChange.bind(this)
    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.handleTitleChange = this.handleTitleChange.bind(this)
    this.handleQuantityChange = this.handleQuantityChange.bind(this)
    this.handleShelfLocationChange = this.handleShelfLocationChange.bind(this)
    this.handleImageURLChange = this.handleImageURLChange.bind(this)
    this.handleSubmitClick = this.handleSubmitClick.bind(this)
    this.handleShelfChange = this.handleShelfChange.bind(this)
    this.handleClientChange = this.handleClientChange.bind(this)
  }

  componentDidMount() {
    window.fetch('/api/env/')
      .then(res => res.json())
      .then(data => {
        this.props.setEnvironmentVariables(data)

        // Add Google Analytics
        ReactGA.initialize(data.public.analyticsTrackingId, { debug: false })
        ReactGA.pageview(window.location.pathname + window.location.search)
      })

    window.fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        this.setState({ authQueryReceived: true, isLoggedIn: data.success })
        if (data.success) {
          axios.get('/api/client/getClients', this.state.fileData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
            .then(res => {
              this.setState({
                clientList: res.data.clients,
                client: res.data.clients[0]._id,
                clientName: res.data.clients[0].name
              })
            })

          axios.get('/api/item/get-all-shelves', this.state.fileData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
            .then(res => {
              const shelfNameList = []
              res.data.shelves.forEach(item => {
                shelfNameList.push(item.shelfName)
              })
              this.setState({
                shelfNameList: shelfNameList,
                shelfList: res.data.shelves
              })
            })
        }
      })
  }

  handleFileUpload(e) {
    const data = new FormData()
    const file = e.target.files[0]

    data.append('file', e.target.files[0])
    if (file) {
      this.setState({ fileName: e.target.files[0].name, fileData: data })
    }
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ''
    }))
  }

  handleUploadClick(e) {
    // TODO send file
    if (this.state.fileData !== '') {
      axios.post('/api/item/create/csv', this.state.fileData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          this.setState({
            snackbarOpen: true,
            snackbarMessage: 'CSV file accepted, please double check if there were any issues or mistakes'
          })
        })
    } else {
      this.setState({ snackbarOpen: true, snackbarMessage: 'Please select a csv file' })
    }
  }

  handleSKUChange(e) {
    this.setState({ sku: e.target.value })
  }

  handleTitleChange(e) {
    this.setState({ title: e.target.value })
  }

  handleQuantityChange(e) {
    this.setState({ quantity: e.target.value })
  }

  handleShelfLocationChange(e) {
    this.setState({ location: e.target.value })
  }

  handleImageURLChange(e) {
    this.setState({ imageUrl: e.target.value })
  }

  handleSubmitClick(e) {
    if (this.state.sku === '' || this.state.title === '') {
      this.setState({ snackbarOpen: true, snackbarMessage: 'Fill out the form before submitting' })
    } else if ((/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?\\s]/g.test(this.state.sku))) {
      this.setState({ snackbarOpen: true, snackbarMessage: 'Please refrain from using spaces and/or special characters in SKUs' })
    } else {
      axios.post('/api/item/create/manual', {
        body: JSON.stringify({
          sku: this.state.sku,
          title: this.state.title,
          client: this.state.client,
          location: this.state.location,
          imageUrl: this.state.imageUrl,
          store: this.state.store,
          quantity: this.state.quantity,
          productDetails: this.state.detailInputs
        })
      },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(res => res.data.success ? this.setState({
          sku: '',
          title: '',
          quantity: '',
          location: '',
          imageUrl: '',
          snackbarMessage: 'Successfully added item',
          snackbarOpen: true
        }) : this.setState({ snackbarMessage: 'SKU/Client combination already exists', snackbarOpen: true }))
    }
  }

  handleShelfChange(value) {
    this.setState({ location: value })
  }

  handleClientChange(e) {
    const json = JSON.parse(e.target.value)
    const id = json._id
    const name = json.name
    this.setState({ client: id, clientName: name })
  }

  render() {
    const { collapseID } = this.state
    const csvData = [
      ['title', 'sku', 'barcode', 'clientName', 'imgUrl', 'weight'],
      ['Item Title', 'sku123', '123456789', 'F45/White Glo/Vsculptor', 'imgUrl', 2000]
    ]

    // Must include this code block to redirect user to the login page if user is not logged in.
    // Otherwise if the session has timed out and the user access this page it will appear as loading indefinitely.
    if (this.state.authQueryReceived && !this.state.isLoggedIn) {
      this.props.setIsLoggedIn(false)
      this.props.setAuthQueryReceived(false)
      return <Redirect to={{ pathname: '/login', state: { referrer: '/create-items' } }} />
    }
    
    return (
      <div>
        <MDBContainer>
          {this.state.authQueryReceived ? <div>
            <MDBBreadcrumb>
              <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
              <MDBBreadcrumbItem>Items</MDBBreadcrumbItem>
              <MDBBreadcrumbItem active>Create Items</MDBBreadcrumbItem>
            </MDBBreadcrumb>
            <MDBContainer className='md-accordion mt-5'>
              <MDBCard className='mt-3' style={{ backgroundColor: 'rgba(0,0,0,.03)' }}>
                <MDBCollapseHeader
                  tagClassName='d-flex justify-content-between'
                  onClick={this.toggleCollapse('collapse1')}
                >
                  Upload a file
                  <MDBIcon icon={collapseID === 'collapse1' ? 'angle-up' : 'angle-down'} />
                </MDBCollapseHeader>
                <MDBCollapse id='collapse1' isOpen={collapseID}>
                  <MDBCardBody>
                    <MDBRow center>
                      <CSVLink data={csvData} filename='createItemTemplate.csv'>Download template</CSVLink>
                    </MDBRow>
                    <br />
                    <div className='input-group'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text' id='inputGroupFileAddon01'>
                          Upload
                        </span>
                      </div>
                      <div className='custom-file'>
                        <input
                          type='file'
                          className='custom-file-input'
                          id='inputGroupFile01'
                          aria-describedby='inputGroupFileAddon01'
                          onChange={this.handleFileUpload}
                        />
                        <label className='custom-file-label' htmlFor='inputGroupFile01'>
                          {this.state.fileName !== '' ? this.state.fileName : 'Choose file'}
                        </label>
                      </div>
                    </div>
                    <br />
                    <MDBRow center>
                      <MDBBtn
                        rounded onClick={this.handleUploadClick}
                        color='primary'
                      ><b>Submit</b>
                      </MDBBtn>
                    </MDBRow>
                  </MDBCardBody>
                </MDBCollapse>
              </MDBCard>
              <MDBCard className='mt-3' style={{ backgroundColor: 'rgba(0,0,0,.03)' }}>
                <MDBCollapseHeader
                  tagClassName='d-flex justify-content-between'
                  onClick={this.toggleCollapse('collapse2')}
                >
                  Add manually
                  <MDBIcon icon={collapseID === 'collapse2' ? 'angle-up' : 'angle-down'} />
                </MDBCollapseHeader>
                <MDBCollapse id='collapse2' isOpen={collapseID}>
                  <MDBCardBody>
                    <MDBContainer>
                      <div>
                        <label htmlFor='select-client'>Select Client</label>
                        <select id='select-client' className='browser-default custom-select' defaultValue='default' onChange={this.handleClientChange}>
                          <option value='default' disabled>Select Client</option>
                          {this.state.clientList.sort((a, b) => a.name > b.name).map((client, i) => {
                            return (
                              <option value={JSON.stringify(client)} key={i}>{client.name}</option>
                            )
                          })}
                        </select>
                      </div>
                      {/* <MDBInputGroup label={<b>Listing status:</b>} inputs={
                                        <MDBSwitch labelLeft="Disabled" labelRight="Active" checked={this.state.switch1} onChange={this.handleSwitchChange} />
                                    } /> */}
                      {/* <TextField label="SKU" placeholder="SKU123" fullWidth color="primary"
                                        onChange={this.handleSKUChange} value={this.state.sku} /> */}

                      <br />
                      <label htmlFor='sku'>SKU</label>
                      <MDBInputGroup id='sku' containerClassName='mb-3' hint='SKU123' onChange={this.handleSKUChange} />
                      {/* <MDBInputGroup containerClassName="mb-3" hint="Client" onChange={this.handleClientChange} /> */}
                      <label htmlFor='title'>Title</label>
                      <MDBInputGroup id='title' containerClassName='mb-3' hint='Title' onChange={this.handleTitleChange} />
                      {/* <TextField label="Item Title" fullWidth color="primary"
                                        onChange={this.handleTitleChange} value={this.state.title} /> */}
                      <label htmlFor='qty'>Quantity</label>
                      <MDBInputGroup id='qty' containerClassName='mb-3' hint='0' onChange={this.handleQuantityChange} />

                      <div>
                        <Autocomplete
                          options={this.state.shelfNameList}
                          // getOptionLabel={(option) => option.title}
                          renderInput={(params) => <TextField
                            {...params} label='Location'
                            variant='outlined'
                            fullWidth
                          />}
                          onChange={(e, value) => this.handleShelfChange(value)}

                        />
                      </div>

                      <TextField
                        label='Image URL' placeholder='https://www.example.com' fullWidth
                        color='primary' value={this.state.imageUrl}
                        onChange={this.handleImageURLChange}
                      />
                      <Paper square style={{ height: 250, marginTop: 25 }}>
                        <Grid
                          container direction='column' justify='center' alignItems='center'
                          alignContent='center'
                        >
                          <Grid item>
                            <Typography variant='caption'>Image preview</Typography>
                          </Grid>
                          <Grid item>
                            {this.state.imageUrl !== '' &&
                              <img
                                src={this.state.imageUrl} alt=''
                                style={{ maxHeight: 200, maxWidth: 200 }}
                              />}
                          </Grid>
                        </Grid>
                      </Paper>
                      <MDBRow center>
                        <MDBBtn
                          rounded onClick={this.handleSubmitClick}
                          color='primary'
                        ><b>Submit</b>
                        </MDBBtn>
                      </MDBRow>
                    </MDBContainer>
                  </MDBCardBody>
                </MDBCollapse>
              </MDBCard>
            </MDBContainer>
            <Snackbar
              message={this.state.snackbarMessage} open={this.state.snackbarOpen}
              onClose={() => this.setState({ snackbarOpen: false })}
            />
          </div> : <div />}
        </MDBContainer>
      </div>)
  }
}

// Access to Redux store props
const mapStateToProps = (state, ownProps) => {
  // redux state fields are mapped to the props fields here.
  // This class component can access the redux state field via this.props.[fieldname]
  return {
    environmentVariables: state.environmentVariables
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setEnvironmentVariables: (data) => {
      dispatch(setEnvironmentVariables(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(createItemPage)
