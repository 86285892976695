import {
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBCard,
  MDBCardBody,
  MDBCollapse,
  MDBCollapseHeader,
  MDBContainer,
  MDBIcon
} from 'mdbreact'
import Paper from '@material-ui/core/Paper/Paper'

import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'
import { useSelector } from 'react-redux'
import PickingList from './PickingList'
import OrdersTable from './OrdersTable'

const OrdersPage = props => {
  const reduxState = useSelector(state => state)
  const [orders, setOrders] = useState([])
  const [showingAll, setShowingAll] = useState(false)
  const [ordersEmpty, setOrdersEmpty] = useState(false)
  const [loader, setLoader] = useState(true)
  const [collapseId, setCollapseId] = useState('collapse0')
  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])
  const [mounted, setMounted] = React.useState(false)

  useEffect(() => {
    let mounted = true
    setMounted(true)
    window
      .fetch('/api/auth/verify-token')
      .then(res => res.json())
      .then(data => {
        props.setAuthQueryReceived(true)
        props.setIsLoggedIn(data.success)
        if (data.success) {
          if (
            reduxState.environmentVariables &&
            reduxState.environmentVariables.public &&
            reduxState.environmentVariables.public.analyticsTrackingId
          ) {
            ReactGA.initialize(
              reduxState.environmentVariables.public.analyticsTrackingId,
              { debug: false }
            )
            ReactGA.pageview(window.location.pathname + window.location.search)
          }
          if (mounted) {
            if (orders.length === 0) {
              if (ordersEmpty === false) {
                setOrdersEmpty(true)
              }
            } else {
              if (ordersEmpty === true) {
                setOrdersEmpty(false)
              }
            }
          }
        }
      })
    return function cleanup () {
      mounted = false
      setMounted(false)
    }
  }, [])

  // Wait for mounted to be set to true before calling fetch orders
  useEffect(() => {
    if (mounted) {
      fetchOrders()
    }
  }, [mounted])

  // Must include this code block to redirect user to the login page if user is not logged in.
  // Otherwise if the session has timed out and the user access this page it will appear as loading indefinitely.
  // This code block must be placed immediately after the userEffect() blocks.
  if (!props.isLoggedIn && !props.isLoading) {
    return (
      <Redirect to={{ pathname: '/login', state: { referrer: '/orders' } }} />
    )
  }

  function fetchOrders () {
    if (mounted) {
      setLoader(true)
    }
    window
      .fetch('/api/order')
      .then(res => res.json())
      .then(async data => {
        const myOrders = data.orders
        if (mounted) {
          setOrders(myOrders)
          setShowingAll(false)
          setLoader(false)
          forceUpdate()
        }
      })
  }

  function fetchAllOrders () {
    setLoader(true)

    window
      .fetch('/api/order/all')
      .then(res => res.json())
      .then(async data => {
        const myOrders = data.orders
        Promise.all(
          myOrders.map(async (order, i) => {
            await window
              .fetch('/api/client/getClientByStoreId/' + order.storeId)
              .then(res => res.json())
              .then(data => {
                const store = data.stores.find(
                  a => a._id.toString() === order.storeId.toString()
                )
                myOrders[i].store = store
                myOrders[i].clientName = data.name
                myOrders[i].storeName =
                  data.name + ' ' + store.platform + ' ' + store.name
                // myOrders[i].createdAt = new Date(myOrders[i].createdAt).toLocaleString('en-GB')
                myOrders[i].createdAt = myOrders[i]
              })
          })
        ).then(() => {
          setOrders(myOrders)
          setShowingAll(true)
          setLoader(false)
          forceUpdate()
        })
      })
  }

  function toggleCollapse (id) {
    if (collapseId !== id) {
      setCollapseId(id)
    } else {
      setCollapseId('')
    }
  }

  return (
    <MDBContainer>
      <MDBBreadcrumb>
        <MDBBreadcrumbItem>Home</MDBBreadcrumbItem>
        <MDBBreadcrumbItem active>Orders</MDBBreadcrumbItem>
      </MDBBreadcrumb>
      <MDBCard className='mt-3' style={{ backgroundColor: 'rgba(0,0,0,.03)' }}>
        <MDBCollapseHeader
          tagClassName='d-flex justify-content-between'
          onClick={() => toggleCollapse('collapse1')}
        >
          Picking List
          <MDBIcon
            icon={collapseId === 'collapse1' ? 'angle-up' : 'angle-down'}
          />
        </MDBCollapseHeader>
        <MDBCollapse id='collapse1' isOpen={collapseId}>
          <MDBCardBody>
            <PickingList />
          </MDBCardBody>
        </MDBCollapse>
      </MDBCard>
      <Paper elevation={10} style={{ padding: 25 }}>
        {reduxState.userPreferences && (
          <OrdersTable
            orders={orders}
            history={props.history}
            showAll={showingAll}
            fetchOrders={fetchOrders}
            fetchAllOrders={fetchAllOrders}
            loader={loader}
            setLoader={setLoader}
          />
        )}
      </Paper>
    </MDBContainer>
  )
}

export default OrdersPage
